import axios from 'axios';
import Config from './Config';
import ICustomerZoneBalance from '../Interfaces/ICustomerZoneBalance';

export default class CustomerZoneBalance {

  // Load list
  async list(data: any = {}): Promise<{
    data: ICustomerZoneBalance[],
    count: number
  }|false>  {
    const response = (await axios.get(Config.api('/customer-zone/balance/list'), {params: data})).data;

    if (!response.success) {
      console.error(response.message);

      return false;
    }

    const responseData = response.data;

    return {
      data: responseData.data.map(item => ICustomerZoneBalance.parse(item)),
      count: responseData.count
    };
  }

  // Remove by ID
  async delete(id: number|number[]): Promise<boolean> {
    const response = (await axios.post(Config.api('/customer-zone/balance/delete'), {id})).data;

    if (!response.success) {
      console.error(response.message);

      return false;
    }

    return true;
  }

}
