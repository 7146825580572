import React, {FC, Suspense, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import Login from './Login/Login';
import Admin from './Admin/Admin';
import Auth from './Providers/Auth';

const Navigator: FC = () => {
  const history = useHistory();
  const auth = new Auth();
  const isLogin = auth.check();

  useEffect(() => {
    if (!isLogin && (
      window.location.pathname.indexOf('/admin') !== -1 ||
      window.location.pathname === '/'
    )) {
      history.push('/');
      auth.clear();
    }

    if (isLogin && window.location.pathname.indexOf('/admin') !== 0) {
      history.push('/admin');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div id="navigator">
      {isLogin ?
        <Suspense fallback="loading">
          <Admin />
        </Suspense>
        :
        <Suspense fallback="loading">
          <Login />
        </Suspense>
      }
    </div>
  );
}

export default Navigator;
