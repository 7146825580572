import moment from 'moment';

export default class ITracking {

  id: number;
  customerZoneAutoId: number;
  data: {
    bodyType: string;
    vin: string;
    year: string;
    brand: string;
    model: string;
    sparePartsOrder: string;
    noteSparePartsOrder: string;
    autoForCutting: string;
    noteAutoForCutting: string;
    photos: string[];

    saleType: string;
    auction: string;
    lotLink: string;
    trucking: string;
    billOfSale: string[];

    port: string;
    country: string;
    city: string;
    consolidation: string;
    finalUse: string;
    title: any[];
  };
  code: string;
  createdAt: string;
  updatedAt: string;

  public static parse(data: any): ITracking {
    if (!data) {
      return null;
    }

    const tracking = new ITracking();

    tracking.id = data.id ? parseInt(data.id, 10) : null;
    tracking.customerZoneAutoId = data.customer_zone_auto_id ? data.customer_zone_auto_id : null;
    tracking.data = {
      bodyType: data.data['body-type'],
      vin: data.data['vin'],
      year: data.data['year'],
      brand: data.data['brand'],
      model: data.data['model'],
      sparePartsOrder: data.data['spare-parts-order'],
      noteSparePartsOrder: data.data['note-spare-parts-order'],
      autoForCutting: data.data['auto-for-cutting'],
      noteAutoForCutting: data.data['note-auto-for-cutting'],
      photos: Array.isArray(data.data['photos']) ? data.data['photos'] : [],

      saleType: data.data['sale-type'],
      auction: data.data['auction'],
      lotLink: data.data['lot-link'],
      trucking: data.data['trucking'],
      billOfSale: Array.isArray(data.data['bill-of-sale']) ? data.data['bill-of-sale'] : [],

      port: data.data['port'],
      country: data.data['country'],
      city: data.data['city'],
      consolidation: data.data['consolidation'],
      finalUse: data.data['final-use'],
      title: Array.isArray(data.data['title']) ? data.data['title'] : []
    };
    tracking.code = data.code;
    tracking.createdAt = data.created_at ? moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') : null;
    tracking.updatedAt = data.updated_at ? moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') : null;

    return tracking;
  }

}
