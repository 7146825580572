import React, {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import './InvoicesForm.scss';
import Input from '../../Core/Form/Input/Input';
import axios from 'axios';
import Config from '../../Providers/Config';
import $ from 'jquery';
import {IHttpResponse} from '../../Interfaces/IHttpResponse';
import * as EgoUtil from '@egofoxlab/util';
import Notify from '../../Core/Ui/Notify/Notify';
import IInvoice from '../../Interfaces/IInvoice';
import Radio from '../../Core/Form/Radio/Radio';
import moment from 'moment';
import Share from '../Share/Share';
import Head from '../Head/Head';
import BottomToolbar from '../BottomToolbar/BottomToolbar';
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary} from '@material-ui/core';
import RadioGroup from '../../Core/Form/RadioGroup/RadioGroup';

interface IState {

  invoice?: IInvoice;
  serviceType?: string;
  status?: string;
  stuffType?: string;
  saveProcess?: boolean;
  saveNotify?: boolean;

}

// Load form
async function load(id) {
  const response = (await axios.get(Config.api(`/invoices/get/${id}`))).data;

  if (!response.success) {
    console.error(response.message);

    return false;
  }

  // Skip empty
  if (!response.data)
    return false;

  return response.data;
}

const InvoicesForm: FC = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const id = parseInt(useParams().id) || null;
  const [invoice, setInvoice] = useState<IInvoice>();
  const [serviceType, setServiceType] = useState('');
  const [status, setStatus] = useState('');
  const [stuffType, setStuffType] = useState('');
  const [saveProcess, setSaveProcess] = useState(false);
  const [saveNotify, setSaveNotify] = useState(false);
  const _eForm = useRef<HTMLFormElement>(null);

  useEffect(() => {
    (async () => {
      let invoice: IInvoice|any = IInvoice.parse(id > 0 ? await load(id) : {});

      if (!invoice) {
        history.push('/admin/invoices');

        return;
      }

      setServiceType(invoice.data.serviceType ?? null);
      setStatus(invoice.status ?? null);
      setStuffType(invoice.data.stuffType ?? null);
      setInvoice(invoice);
    })();
    // eslint-disable-next-line
  }, []);

  function reset(): void {
    // Clear input field
    EgoUtil.clearFields(_eForm.current);
    $(_eForm.current).find('[name]').trigger('change');
  }

  function onSubmit(e): void {
    if (!_eForm.current.checkValidity())
      return;

    e.preventDefault();

    // Set `Save Process` flag
    setSaveProcess(true);

    const eForm = $(_eForm.current);
    const formData = new FormData();
    // Status
    formData.append('status', status);
    // Service Type
    formData.append('service-type', serviceType);
    // First Name
    formData.append('first-name', eForm.find('[name="first-name"]').val());
    // Last Name
    formData.append('last-name', eForm.find('[name="last-name"]').val());
    // Country
    formData.append('country', eForm.find('[name="country"]').val());
    // City
    formData.append('city', eForm.find('[name="city"]').val());
    // Street
    formData.append('street', eForm.find('[name="street"]').val());
    // House
    formData.append('house', eForm.find('[name="house"]').val());
    // Flat
    formData.append('flat', eForm.find('[name="flat"]').val());
    // Stuff Type
    formData.append('stuff-type', stuffType);
    // Invoice Number
    formData.append('invoice-number', eForm.find('[name="invoice-number"]').val());
    // Invoice Date
    formData.append('invoice-date', eForm.find('[name="invoice-date"]').val());
    // Invoice Date
    formData.append('invoice-date', eForm.find('[name="invoice-date"]').val());
    // Client Number
    formData.append('client-number', eForm.find('[name="client-number"]').val());
    // Product
    formData.append('product', eForm.find('[name="product"]').val());
    // Product Number
    formData.append('product-number', eForm.find('[name="product-number"]').val());
    // Serial Number
    formData.append('serial-number', eForm.find('[name="serial-number"]').val());
    // Amount
    formData.append('amount', eForm.find('[name="amount"]').val());

    // Save form
    let url;

    if (invoice && invoice.id > 0) {
      url = Config.api(`/invoices/update/${invoice.id}`);
    } else {
      url = Config.api('/invoices/create');
    }

    axios.post(url, formData, {
      headers: {'Content-Type': 'multipart/form-data'}
    })
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);
          // Unset `Save Process` flag
          setSaveProcess(false);

          return;
        }
        // Mark as saved
        setSaveNotify(true);

        // Add invoice to state after saving
        if (!invoice || !(invoice.id > 0)) {
          history.replace(`/admin/invoices/form/${response.data.id}`);

          // Update invoices
          setInvoice(IInvoice.parse(response.data));
        }

        // Unset `Save Process` flag
        setSaveProcess(false);
      });
  }

  return (
    <>
      {invoice ?
        <div
          id="invoices-form-component"
          className={'invoices-component'}
        >
          <Head
            head={t('admin:invoices.form.head')}
            subhead={'VIN - Auto'}
            right={<div className="d-flex justify-content-lg-end justify-content-start train-buttons">
              <button
                form="invoices-form"
                className="btn btn-save"
                disabled={saveProcess}
              >
                {t('buttons.save')}
              </button>
              <button
                type="button"
                className="btn btn-reload"
                onClick={() => reset()}
              >
                {t('buttons.reset')}
              </button>
              <button
                type="button"
                className="btn btn-remove"
              >
                {t('buttons.remove')}
              </button>
            </div>}
            hr={{style: {marginTop: 20}}}
          />

          <form
            ref={_eForm}
            id="invoices-form"
            onSubmit={onSubmit}
          >
            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelDetails className="pt-0">
                <div className="d-flex flex-column flex-grow-1">
                  {/*Service Type*/}
                  <RadioGroup
                    className="row"
                    value={serviceType}
                    onChange={e => setServiceType(e.target.value)}
                  >
                    <div className="col-12">
                      <div className="row d-flex justify-content-center mb-3">
                        {/*<div className="item col-12 col-md-2">
                              <Radio
                                  type="radio"
                                  name="service-type"
                                  id="service-type-g&g"
                                  defaultValue="g&g"
                                  labelAfter="G&G"
                                  required={true}
                                  bottomOffset={0}
                              />
                          </div>*/}

                        {/*IAAI*/}
                        <div className="item col-12 col-md-2">
                          <Radio
                            label={{
                              label: 'IAAI'
                            }}
                            radio={{
                              name: 'service-type',
                              value: 'iaai',
                              required: true
                            }}
                            bottomOffset={0}
                          />
                        </div>

                        {/*Copart*/}
                        <div className="item col-12 col-md-2">
                          <Radio
                            label={{
                              label: 'Copart'
                            }}
                            radio={{
                              name: 'service-type',
                              value: 'copart',
                              required: true,
                            }}
                            bottomOffset={0}
                          />
                        </div>

                        {/*GR8 Logistics*/}
                        <div className="item col-12 col-md-3">
                          <Radio
                            label={{
                              label: 'GR8 Logistics'
                            }}
                            radio={{
                              name: 'service-type',
                              value: 'gr8-logistics',
                              required: true,
                            }}
                            bottomOffset={0}
                          />
                        </div>

                        {/*GR8 Canada*/}
                        <div className="item col-12 col-md-3">
                          <Radio
                            label={{
                              label: 'GR8 Canada'
                            }}
                            radio={{
                              name: 'service-type',
                              value: 'gr8-auto-canada',
                              required: true,
                            }}
                            bottomOffset={0}
                          />
                        </div>
                      </div>
                    </div>
                  </RadioGroup>
                  {/*Status*/}
                  <RadioGroup
                    className="row"
                    value={status}
                    onChange={e => setStatus(e.target.value)}
                  >
                    <div className="col-12">
                      <div className="row d-flex justify-content-center">
                        <div className="col-12 col-md-2 d-flex align-items-center justify-content-start justify-content-md-end">
                          <span style={{fontWeight: 700}}>
                            {t('admin:invoices.form.status')}:
                          </span>
                        </div>

                        {/*Open*/}
                        <div className="col-12 col-md-2">
                          <Radio
                            label={{
                              label: t('admin:invoices.status_open')
                            }}
                            radio={{
                              name: 'status',
                              value: 'open',
                              required: true
                            }}
                            bottomOffset={0}
                          />
                        </div>

                        {/*Close*/}
                        <div className="col-12 col-md-2">
                          <Radio
                            label={{
                              label: t('admin:invoices.status_close')
                            }}
                            radio={{
                              name: 'status',
                              value: 'close',
                              required: true
                            }}
                            bottomOffset={0}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    </div>
                  </RadioGroup>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            {/*User Form*/}
            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary id="section-password">
                {t('admin:invoices.form.personal_data')}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className="row d-flex flex-grow-1">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Name*/}
                    <Input
                      label={t('admin:invoices.create_invoice.name')}
                      input={{
                        name: 'first-name',
                        id: 'first-name',
                        defaultValue: invoice ? invoice.data.firstName : null,
                        placeholder: t('admin:invoices.create_invoice.letters'),
                        required: true,
                        inputProps: {
                          minLength: 2,
                          pattern: '[A-Za-z\\D]*',
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Surname*/}
                    <Input
                      label={t('admin:invoices.create_invoice.surname')}
                      input={{
                        name: 'last-name',
                        id: 'last-name',
                        defaultValue: invoice ? invoice.data.lastName : null,
                        placeholder: t('admin:invoices.create_invoice.letters'),
                        required: true,
                        inputProps: {
                          minLength: 2,
                          pattern: '^[a-zA-Z0-9\\D]+$',
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Country*/}
                    <Input
                      label={t('admin:invoices.create_invoice.country')}
                      input={{
                        name: 'country',
                        id: 'country',
                        defaultValue: invoice ? invoice.data.country : null,
                        placeholder: t('admin:invoices.create_invoice.latin'),
                        required: true,
                        inputProps: {
                          minLength: 1,
                          pattern: '^[a-zA-Z0-9\\D]+$',
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*City*/}
                    <Input
                      label={t('admin:invoices.create_invoice.city')}
                      input={{
                        name: 'city',
                        id: 'city',
                        defaultValue: invoice ? invoice.data.city : null,
                        placeholder: t('admin:invoices.create_invoice.latin'),
                        required: true,
                        inputProps: {
                          minLength: 1,
                          pattern: '^[a-zA-Z0-9\\D]+$',
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Street*/}
                    <Input
                      label={t('admin:invoices.create_invoice.street')}
                      input={{
                        name: 'street',
                        id: 'street',
                        defaultValue: invoice ? invoice.data.street : null,
                        placeholder: t('admin:invoices.create_invoice.latin_and_numbers'),
                        required: true,
                        inputProps: {
                          minLength: 2,
                          pattern: '[A-Za-z0-9 \\D]*',
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*House*/}
                    <Input
                      label={t('admin:invoices.create_invoice.house')}
                      input={{
                        name: 'house',
                        id: 'house',
                        defaultValue: invoice ? invoice.data.house : null,
                        placeholder: t('admin:invoices.create_invoice.numbers'),
                        required: true,
                        inputProps: {
                          minLength: 1
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Flat*/}
                    <Input
                      label={t('admin:invoices.create_invoice.flat')}
                      input={{
                        name: 'flat',
                        id: 'flat',
                        defaultValue: invoice ? invoice.data.flat : null,
                        placeholder: t('admin:invoices.create_invoice.optional')
                      }}
                    />
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <div>
              <div>
                <RadioGroup
                  className="row flex-row justify-content-center"
                  value={stuffType}
                  onChange={e => setStuffType(e.target.value)}
                >
                  {/*Car*/}
                  <div className="item col-12 col-md-3">
                    <Radio
                      label={{
                        label: t('admin:invoices.create_invoice.car')
                      }}
                      radio={{
                        name: 'stuff-type',
                        value: 'car'
                      }}
                    />
                  </div>

                  {/*Delivery*/}
                  <div className="item col-12 col-md-3">
                    <Radio
                      label={{
                        label: t('admin:invoices.create_invoice.delivery')
                      }}
                      radio={{
                        name: 'stuff-type',
                        value: 'delivery'
                      }}
                    />
                  </div>
                </RadioGroup>
              </div>
            </div>

            {/*Invoice Form*/}
            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary id="section-password">
                {t('admin:invoices.form.invoice_info')}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className="row d-flex flex-grow-1">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Invoice Number*/}
                    <Input
                      label={t('admin:invoices.create_invoice.invoice_number')}
                      input={{
                        name: 'invoice-number',
                        id: 'invoice-number',
                        defaultValue: invoice ? invoice.data.invoiceNumber : null,
                        placeholder: t('admin:invoices.create_invoice.letters_and_numbers'),
                        required: true
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Invoice Date*/}
                    <Input
                      label={t('admin:invoices.create_invoice.invoice_date')}
                      input={{
                        name: 'invoice-date',
                        id: 'invoice-date',
                        defaultValue: invoice ? invoice.data.invoiceDate : moment().format('DD/MM/YYYY'),
                        placeholder: t('admin:invoices.create_invoice.date_format'),
                        required: true
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Member/Buyer*/}
                    <Input
                      label={t('admin:invoices.create_invoice.member_buyer')}
                      input={{
                        name: 'client-number',
                        id: 'client-number',
                        defaultValue: invoice ? invoice.data.clientNumber : null,
                        placeholder: t('admin:invoices.create_invoice.numbers'),
                        inputProps: {
                          pattern: '[0-9\\-]{1,}'
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Year, brand, model*/}
                    <Input
                      label={t('admin:invoices.create_invoice.year_brand_model')}
                      input={{
                        name: 'product',
                        id: 'product',
                        defaultValue: invoice ? invoice.data.product : null,
                        placeholder: t('admin:invoices.create_invoice.letters_and_numbers'),
                        required: true,
                        inputProps: {
                          pattern: '^[a-zA-Z0-9\\- ]+$',
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Lot number*/}
                    <Input
                      label={t('admin:invoices.create_invoice.lot_number')}
                      input={{
                        name: 'product-number',
                        id: 'product-number',
                        defaultValue: invoice ? invoice.data.productNumber : null,
                        placeholder: t('admin:invoices.create_invoice.8_numbers'),
                        inputProps: {
                          minLength: 8,
                          maxLength: 8,
                          pattern: '[0-9]{8,8}',
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*VIN code*/}
                    <Input
                      label={t('admin:invoices.create_invoice.vin_code')}
                      input={{
                        name: 'serial-number',
                        id: 'serial-number',
                        defaultValue: invoice ? invoice.data.serialNumber : null,
                        placeholder: t('admin:invoices.create_invoice.17_letters_and_numbers'),
                        required: true,
                        inputProps: {
                          minLength: 17,
                          maxLength: 17,
                          pattern: '^[a-zA-Z0-9]+$',
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Amount*/}
                    <Input
                      label={t('admin:invoices.create_invoice.amount')}
                      input={{
                        name: 'amount',
                        id: 'amount',
                        defaultValue: invoice ? invoice.data.amount : null,
                        placeholder: '0.00',
                        required: true,
                        inputProps: {
                          pattern: '\\d+(\\.\\d{2})?'
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Promo code*/}
                    <Input
                      label={t('admin:invoices.create_invoice.promo_code')}
                      input={{
                        name: 'promo-code',
                        id: 'promo-code',
                        defaultValue: invoice ? invoice.promoCode : null,
                        placeholder: t('admin:invoices.create_invoice.letters_and_numbers')
                      }}
                    />
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </form>

          {/*Share*/}
          {invoice && invoice.id > 0 ?
            <Share
              className="mb-3"
              share={invoice.id}
              module={'invoice'}
            />
            :
            ''
          }

          <BottomToolbar
            right={<>
              <div className="d-flex justify-content-lg-end justify-content-start train-buttons">
                <button
                  form="invoices-form"
                  className="btn btn-save"
                  disabled={saveProcess}
                >
                  {t('buttons.save')}
                </button>
                <button
                  type="button"
                  className="btn btn-reload"
                  onClick={() => reset()}
                >
                  {t('buttons.reset')}
                </button>
                <button
                  type="button"
                  className="btn btn-remove"
                >
                  {t('buttons.remove')}
                </button>
              </div>
              <div className="d-flex justify-content-end">
                <Notify
                  type="save-form"
                  hide={!saveNotify}
                />
              </div>
            </>}
          />
        </div>
        :
        ''
      }
    </>
  );
};

export default InvoicesForm;
