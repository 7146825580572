import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './ActivatePromoCode.scss';
import Input from '../../../Core/Form/Input/Input';
import Select, {SelectStylesHideIndicatorSeparator} from '../../../Core/Form/Select/Select';
import IInvoice from '../../../Interfaces/IInvoice';
import {IHttpResponse} from '../../../Interfaces/IHttpResponse';
import axios from 'axios';
import Config from '../../../Providers/Config';
import IUser from '../../../Interfaces/IUser';
import Notify from '../../../Core/Ui/Notify/Notify';
import DownArrowIndicator from '../../../Core/Form/Select/Indicators/DownArrowIndicator';
import qs from 'qs';

interface IActivatePromoCodeState {

  promoCode: string;
  invoice?: any;
  _selectedInvoice: number;
  selectedInvoice: IInvoice;
  invoiceUser: IUser;
  hideNotify?: boolean;
}

// Search invoices based on input
async function invoiceLoadOptions(input) {
  input = input ? input : '';

  // Min input length
  if (input && input.length < 2)
    return false;

  const q = qs.stringify({
    invoice_number: input,
  });
  const response = (await axios.get(Config.api(`/invoices/search?${q}`))).data;

  if (!response.success)
    return [];

  return response.data.map((item) => ({
    value: item.id,
    label: item.data['invoice-number']
  }));
}

const ActivatePromoCode: FC = () => {
  const {t} = useTranslation();
  const [promoCode, setPromoCode] = useState('');
  const [invoice, setInvoice] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [invoiceUser, setInvoiceUser] = useState(null);
  const [hideNotify, setHideNotify] = useState(true);

  /**
   * Enter promo code and select invoice
   */
  async function onSubmit(e) {
    if (!e.target.checkValidity()) {
      return;
    }

    e.preventDefault();

    setInvoiceUser(null);
    setSelectedInvoice(null);
    setHideNotify(true);

    // User
    const userResponse = (await axios.get(Config.api(`/user/get/by-promo-code/${promoCode}`))).data;

    if (!userResponse.success)
      return;

    const user = IUser.parse(userResponse.data);

    // Invoice
    const invoiceResponse = (await axios.get(Config.api(`/invoices/search?id=${invoice.value}`))).data;

    if (!invoiceResponse.success)
      return;

    const _invoice = invoiceResponse.data[0];

    setInvoiceUser(user);
    setSelectedInvoice(_invoice);
  }

  /**
   * Bind promo code and bind it to invoice
   */
  function onActivate(): void {
    axios.post(Config.api('/invoices/referral-program/activate'), {
      invoice: selectedInvoice.id,
      promoCode: invoiceUser.referralCode
    })
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);

          return;
        }

        setHideNotify(false);
      });
  }

  return (
    <div id="activate-promo-component">
      <div className="header title">
        {t('admin:referral_program.activate_promo_code.header')}
      </div>

      <div className="description">
        {t('admin:referral_program.activate_promo_code.description')}
      </div>

      <br className="none" />
      <br />

      <form
        className="content"
        onSubmit={onSubmit}
      >
        {/*Search form*/}
        <div
          className="row"
          style={{alignItems: 'flex-end'}}
        >
          {/*Promo Code*/}
          <div className="col-12 col-md-4">
            <Input
              label={t('admin:referral_program.activate_promo_code.promo_code')}
              input={{
                name: 'promo-code',
                id: 'promo-code',
                placeholder: t('admin:referral_program.activate_promo_code.enter_promo_code'),
                required: true,
                onChange: (e) => setPromoCode(e.target.value)
              }}
              bottomOffset={-3}
            />
          </div>
          {/*Promo Code*/}
          <div className="col-12 col-md-4">
            <Select
              label={t('admin:referral_program.activate_promo_code.select_invoice')}
              async={true}
              select={{
                cacheOptions: true,
                loadOptions: invoiceLoadOptions,
                defaultOptions: true,
                value: invoice,
                components: {
                  DropdownIndicator: DownArrowIndicator,
                },
                styles: SelectStylesHideIndicatorSeparator,
                onChange: option => setInvoice(option)
              }}
              required={true}
              bottomOffset={0}
            />
          </div>
          {/*Search*/}
          <div className="col-12 col-md-3">
            <button className="w-100 th-submit">
              {t('admin:referral_program.activate_promo_code.search')}
            </button>
          </div>
        </div>

        <br />
        <br />

        {/*Invoice*/}
        {
          invoiceUser && selectedInvoice ?
            <div className="invoice">
              <div className="table-wrap-scroll">
                <div className="table-wrap mobile-flex d-flex">
                  <div className="user">
                    <div className="wrap">
                      <div
                        className="avatar"
                        style={{backgroundImage: `url(${invoiceUser.avatar})`}}
                      />
                      <div className="name">
                        {
                          `${invoiceUser.firstName} ` +
                          (invoiceUser.lastName ? invoiceUser.lastName : '')
                        }
                      </div>
                    </div>
                  </div>

                  <table>
                    <thead>
                      <tr>
                        {/*Phone*/}
                        <th style={{width: '25%'}}>
                          <div>
                            <img
                              src={'/assets/images/icon-calendar-empty.svg'}
                              alt="icon-calendar-empty"
                              height={14}
                            />

                            <span>
                              {t('admin:referral_program.activate_promo_code.phone')}
                            </span>
                          </div>
                        </th>
                        {/*Email*/}
                        <th style={{width: '25%'}}>
                          <div>
                            <img
                              src={'/assets/images/icon-user-silhouette.svg'}
                              alt="icon-user-silhouette"
                              height={14}
                            />

                            <span>
                              Email
                            </span>
                          </div>
                        </th>
                        {/*Card number*/}
                        <th style={{width: '25%'}}>
                          <div>
                            <img
                              src={'/assets/images/icon-dollar-symbol.svg'}
                              alt="icon-dollar-symbol"
                              height={14}
                            />

                            <span>
                              {t('admin:referral_program.activate_promo_code.card_number')}
                            </span>
                          </div>
                        </th>
                        {/*Card name*/}
                        <th style={{width: '25%'}}>
                          <div>
                            <img
                              src={'/assets/images/icon-car.svg'}
                              alt="icon-car"
                              height={14}
                            />

                            <span>
                              {t('admin:referral_program.activate_promo_code.card_name')}
                            </span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {/*Phone*/}
                        <td>
                          {invoiceUser.phone}
                        </td>
                        {/*Email*/}
                        <td>
                          {invoiceUser.email}
                        </td>
                        {/*Card number*/}
                        <td>
                          {invoiceUser.cardNumber}
                        </td>
                        {/*Card name*/}
                        <td>
                          {invoiceUser.cardName}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
            :
            ''
        }

        <br />

        {/*Activate promo code*/}
        {
          invoiceUser && selectedInvoice ?
            <div className="btn-act">
              <div className="text-right none">
                <Notify
                  type="save-form"
                  hide={hideNotify}
                  message={t('admin:referral_program.activate_promo_code.success_activated')}
                />
                <button
                  type="button"
                  className="btn th-activate"
                  onClick={onActivate}
                >
                  {t('admin:referral_program.activate_promo_code.activate_promo_code')}
                </button>
              </div>
              <div className="text-center mobile">
                <button
                  type="button"
                  className="btn th-activate"
                  onClick={onActivate}
                >
                  {t('admin:referral_program.activate_promo_code.activate_promo_code')}
                </button>
                <Notify
                  type="save-form"
                  hide={hideNotify}
                  message={t('admin:referral_program.activate_promo_code.success_activated')}
                />
              </div>

            </div>
            :
            ''
        }

        <br />
        <br />

      </form>
    </div>
  );
}

export default ActivatePromoCode;
