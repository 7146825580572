import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import './CustomerZoneBalanceView.scss';
import axios from 'axios';
import Config from '../../Providers/Config';
import ICustomerZoneAuto from '../../Interfaces/ICustomerZoneAuto';
import ViewValue from '../../Core/Form/ViewValue/ViewValue';
import Head from '../Head/Head';

/**
 * Load form
 */
async function load(id) {
  const formResponse = (await axios.get(Config.api(`/customer-zone/balance/get/${id}`))).data;

  if (!formResponse.success) {
    console.error(formResponse.message);

    return false;
  }

  return formResponse.data;
}

// Load Customer zone auto
async function loadCustomerZoneAuto(customerZoneBalance) {
  //  Skip if empty tracking
  if (!customerZoneBalance?.data?.['customer-zone-auto'])
    return null;

  const customerZoneAutoResponse = (await axios.get(
    Config.api(`/customer-zone/auto/search?id=${customerZoneBalance.data['customer-zone-auto']}`)
  )).data;

  if (!customerZoneAutoResponse.success)
    return null;

  const customerZoneAuto = customerZoneAutoResponse.data.length > 0
    ? ICustomerZoneAuto.parse(customerZoneAutoResponse.data[0]) : null;

  if (!customerZoneAuto)
    return;

  return `#${customerZoneAuto.id} ${customerZoneAuto.data.model}`;
}

const CustomerZoneBalanceForm: FC = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const id = parseInt(useParams().id);
  const [customerZoneBalance, setCustomerZoneBalance] = useState(null);
  const [customerZoneAuto, setCustomerZoneAuto] = useState('');

  useEffect(() => {
    (async () => {
      if (!(id > 0)) {
        history.push('/admin/customer-zone/balance');

        return;
      }

      const customerZoneBalance = await load(id);

      if (!customerZoneBalance) {
        history.push('/admin/customer-zone/balance');

        return;
      }

      setCustomerZoneBalance(customerZoneBalance);
      setCustomerZoneAuto(await loadCustomerZoneAuto(customerZoneBalance));
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {customerZoneBalance ?
        <div
          id="customer-zone-balance-component"
        >
          <Head
            head={t('admin:customer_zone_balance.form.head')}
            subhead={'VIN - Auto'}
          />

          <form id="customer-zone-view-component">
            <div className="row">
              <div className="col-12 col-md-6">
                {/*Customer Zone Auto*/}
                <ViewValue
                  label={t('admin:customer_zone_balance.customer_zone_auto')}
                  value={customerZoneAuto}
                />

                {/*City*/}
                <ViewValue
                  label={t('admin:customer_zone_balance.city')}
                  value={customerZoneBalance.data ? customerZoneBalance.data['city'] : null}
                />

                {/*Price*/}
                <ViewValue
                  label={t('admin:customer_zone_balance.price')}
                  value={customerZoneBalance.data ? customerZoneBalance.data['price'] : null}
                />

                {/*Delivery*/}
                <ViewValue
                  label={t('admin:customer_zone_balance.delivery')}
                  value={customerZoneBalance.data ? customerZoneBalance.data['delivery'] : null}
                />

                {/*Shipping*/}
                <ViewValue
                  label={t('admin:customer_zone_balance.shipping')}
                  value={customerZoneBalance.data ? customerZoneBalance.data['shipping'] : null}
                />

                {/*Dealer fee*/}
                <ViewValue
                  label={t('admin:customer_zone_balance.dealer_fee')}
                  value={customerZoneBalance.data ? customerZoneBalance.data['dealer-fee'] : null}
                />

                {/*Storage Auction fee*/}
                <ViewValue
                  label={t('admin:customer_zone_balance.storage_auction_fee')}
                  value={customerZoneBalance.data ? customerZoneBalance.data['storage-auction-fee'] : null}
                />

                {/*Storage fee*/}
                <ViewValue
                  label={t('admin:customer_zone_balance.storage_fee')}
                  value={customerZoneBalance.data ? customerZoneBalance.data['storage-fee'] : null}
                />

                {/*Loading fee*/}
                <ViewValue
                  label={t('admin:customer_zone_balance.loading_fee')}
                  value={customerZoneBalance.data ? customerZoneBalance.data['loading-fee'] : null}
                />
              </div>

              <div className="col-12 col-md-6">
                {/*Late fee*/}
                <ViewValue
                  label={t('admin:customer_zone_balance.late_fee')}
                  value={customerZoneBalance.data ? customerZoneBalance.data['late-fee'] : null}
                />

                {/*Insurance*/}
                <ViewValue
                  label={t('admin:customer_zone_balance.insurance')}
                  value={customerZoneBalance.data ? customerZoneBalance.data['insurance'] : null}
                />

                {/*Current balance*/}
                <ViewValue
                  label={t('admin:customer_zone_balance.current_balance')}
                  value={customerZoneBalance.data ? customerZoneBalance.data['current-balance'] : null}
                />

                {/*Payed amount*/}
                <ViewValue
                  label={t('admin:customer_zone_balance.payed_amount')}
                  value={customerZoneBalance.data ? customerZoneBalance.data['payed-amount'] : null}
                />

                {/*Balance*/}
                <ViewValue
                  label={t('admin:customer_zone_balance.balance')}
                  value={customerZoneBalance.data ? customerZoneBalance.data['balance'] : null}
                />

                {/*Custom Notes*/}
                <ViewValue
                  label={t('admin:customer_zone_balance.custom_notes')}
                  value={customerZoneBalance.data ? customerZoneBalance.data['custom-notes'] : null}
                />
              </div>
            </div>

            <hr style={{marginTop: '29px'}} />
          </form>
        </div>
        :
        ''
      }
    </>
  );
}

export default CustomerZoneBalanceForm;
