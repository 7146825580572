import axios from 'axios';
import Config from './Config';
import ITracking from '../Interfaces/ITracking';

export default class Tracking {

  // Load list
  async list(data: any = {}): Promise<{
    data: ITracking[],
    count: number
  } | false> {
    const response = (await axios.get(Config.api('/tracking/list'), {params: data})).data;

    if (!response.success) {
      console.error(response.message);

      return false;
    }

    //  Skip empty
    if (!response.data)
      return false;

    const responseData = response.data;

    return {
      data: responseData.data.map(item => ITracking.parse(item)),
      count: responseData.count
    };
  }

  // Remove by ID
  async delete(id: number | number[]): Promise<boolean> {
    const response = (await axios.post(Config.api('/tracking/delete'), {id})).data;

    if (!response.success) {
      console.error(response.message);

      return false;
    }

    return true;
  }

}
