import React, {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import './TrackingForm.scss';
import Input from '../../Core/Form/Input/Input';
import axios from 'axios';
import Config from '../../Providers/Config';
import $ from 'jquery';
import {IHttpResponse} from '../../Interfaces/IHttpResponse';
import * as EgoUtil from '@egofoxlab/util';
import Select, {SelectStylesHideIndicatorSeparator} from '../../Core/Form/Select/Select';
import Checkbox from '../../Core/Form/Checkbox/Checkbox';
import Textarea from '../../Core/Form/Textarea/Textarea';
import Upload, {IUploadedFile} from '../../Core/Form/Upload/Upload';
import InputAutosuggest from '../../Core/Form/InputAutosuggest/InputAutosuggest';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Notify from '../../Core/Ui/Notify/Notify';
import Share from '../Share/Share';
import Head from '../Head/Head';
import BottomToolbar from '../BottomToolbar/BottomToolbar';
import {ExpansionPanel, ExpansionPanelDetails} from '@material-ui/core';
import DownArrowIndicator from '../../Core/Form/Select/Indicators/DownArrowIndicator';
import qs from 'qs';
import moment, {Moment} from 'moment';
import KeyboardDatePicker from '../../Core/Form/Pickers/KeyboardDatePicker/KeyboardDatePicker';

const stepsSuggestions = [
  {
    text: 'Инвойс выставлен'
  },
  {
    text: 'GR8 USA получил оплату'
  },
  {
    text: 'Доставлен на площадку в США'
  },
  {
    text: 'Подготовка к загрузке в контейнер'
  },
  {
    text: 'Погружен в контейнер'
  },
  {
    text: 'Контейнер на 50% пути'
  },
  {
    text: 'Контейнер прибывает в порт разгрузки'
  },
  {
    text: 'Прибыл в порт разгрузки'
  },
  {
    text: 'Выгружен на склад временного хранения'
  },
  {
    text: 'Подготовка к растаможке'
  },
  {
    text: 'Растаможен'
  },
  {
    text: 'Передан клиенту'
  }
];

// Load form
async function load(id) {
  const response = (await axios.get(Config.api(`/tracking/get/${id}`))).data;

  if (!response.success) {
    console.error(response.message);

    return false;
  }

  // Skip empty
  if (!response.data)
    return false;

  return response.data;
}

const TrackingForm: FC = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const id = parseInt(useParams().id) || null;
  const [trackingOrig, setTrackingOrig] = useState<any>();
  const [tracking, setTracking] = useState<any>();
  const [bodyType, setBodyType] = useState<any>();
  const [saleType, setSaleType] = useState<any>();
  const [trucking, setTrucking] = useState<any>();
  const [port, setPort] = useState<any>();
  const [consolidation, setConsolidation] = useState<any>();
  const [dateOfLoading, setDateOfLoading] = useState<Moment>();
  const [dateOfStayAtDestination, setDateOfStayAtDestination] = useState<Moment>();
  const [invoice, setInvoice] = useState<any>();
  const [finalUse, setFinalUse] = useState<any>();
  const [steps, setSteps] = useState<{
    dndId: string;
    id?: string;
    name: string;
    date: Moment;
    status: boolean;
  }[]>([]);
  const [addStepId, setAddStepId] = useState('');
  const [addStepName, setAddStepName] = useState('');
  const [addStepDate, setAddStepDate] = useState<Moment>(null);
  const [addStepStatus, setAddStepStatus] = useState(false);
  const [showNoteSparePartsOrder, setShowNoteSparePartsOrder] = useState(false);
  const [showNoteAutoForCutting, setShowNoteAutoForCutting] = useState(false);
  const [showAuction, setShowAuction] = useState(false);
  const [saveProcess, setSaveProcess] = useState(false);
  const [saveNotify, setSaveNotify] = useState(false);
  const _eForm = useRef<HTMLFormElement>();
  const [photosAuction, setPhotosAuction] = useState<IUploadedFile[]>([]);
  const [photosBeforeLoading, setPhotosBeforeLoading] = useState<IUploadedFile[]>([]);
  const [photosAfterUploading, setPhotosAfterUploading] = useState<IUploadedFile[]>([]);
  const [billOfSale, setBillOfSale] = useState<IUploadedFile[]>([]);
  const [carfaxAutocheck, setCarfaxAutocheck] = useState<IUploadedFile[]>([]);
  const [conosament, setConosament] = useState<IUploadedFile[]>([]);
  const [title, setTitle] = useState<IUploadedFile[]>([]);

  const bodyTypes = [
    {
      value: 'vehicle',
      label: t('admin:tracking.vehicle')
    },
    {
      value: 'package',
      label: t('admin:tracking.package')
    },
    {
      value: 'heavy',
      label: t('admin:tracking.heavy')
    }
  ];
  const saleTypes = [
    {
      value: 'auction',
      label: t('admin:tracking.auction')
    },
    {
      value: 'dealer',
      label: t('admin:tracking.dealer')
    },
    {
      value: 'personal',
      label: t('admin:tracking.personal')
    }
  ];
  const truckingOptions = [
    {
      value: 'require_trucking',
      label: t('admin:tracking.require_trucking')
    },
    {
      value: 'trucking_myself',
      label: t('admin:tracking.trucking_myself')
    }
  ];
  const ports = [
    {
      value: 'odessa',
      label: t('admin:tracking.odessa')
    },
    {
      value: 'haifa',
      label: t('admin:tracking.haifa')
    },
    {
      value: 'riga',
      label: t('admin:tracking.riga')
    }
  ];
  const consolidations = [
    {
      value: 'place_in_container',
      label: t('admin:tracking.place_in_container')
    },
    {
      value: 'own_container',
      label: t('admin:tracking.own_container')
    }
  ];
  const finalUseOptions = [
    {
      value: 'personal',
      label: t('admin:tracking.personal_use')
    },
    {
      value: 'outbid',
      label: t('admin:tracking.outbid_use')
    }
  ];

  useEffect(() => {
    (async () => {
      const tracking = id > 0 ? await load(id) : {};

      if (!tracking) {
        history.push('/admin/tracking');

        return;
      }

      // Dates
      const dateOfLoading = tracking.data?.['date-of-loading'] ?? null;
      const dateOfStayAtDestination = tracking.data?.['date-of-stay-at-destination'] ?? null;

      setBodyType(bodyTypes.find(item => item.value === (tracking.data && tracking.data['body-type'])));
      setSaleType(saleTypes.find(item => item.value === (tracking.data && tracking.data['sale-type'])));
      setTrucking(truckingOptions.find(item => item.value === (tracking.data && tracking.data['trucking'])));
      setPort(ports.find(item => item.value === (tracking.data && tracking.data['port'])));
      setConsolidation(
        consolidations.find(item => item.value === (tracking.data && tracking.data['consolidation'])));
      setDateOfLoading(dateOfLoading ? moment(dateOfLoading, Config.DATE_FORMAT) : null);
      setDateOfStayAtDestination(dateOfStayAtDestination ? moment(dateOfStayAtDestination, Config.DATE_FORMAT) : null);
      setFinalUse(finalUseOptions.find(item => item.value === (tracking.data && tracking.data['final-use'])));

      // Steps
      if (Array.isArray(tracking.data?.steps)) {
        setSteps(dndSteps(tracking.data.steps).map(item => {
          item.date = moment(item.date, Config.DATE_FORMAT);

          return item;
        }));
      }

      // Update other statuses
      setShowNoteSparePartsOrder(tracking.data?.['spare-parts-order'] ?? false);
      setShowNoteAutoForCutting(tracking.data?.['auto-for-cutting'] ?? false);
      setShowAuction(tracking.data?.['sale-type'] === 'auction' ?? false);

      // Files
      setPhotosAuction((tracking?.data?.['photos-auction'] ?? []).map(item => ({
        url: item.url,
        originalName: item['original-name']
      })));
      setPhotosBeforeLoading((tracking?.data?.['photos-before-loading'] ?? []).map(item => ({
        url: item.url,
        originalName: item['original-name']
      })));
      setPhotosAfterUploading((tracking?.data?.['photos-after-uploading'] ?? []).map(item => ({
        url: item.url,
        originalName: item['original-name']
      })));
      setBillOfSale((tracking?.data?.['bill-of-sale'] ?? []).map(item => ({
        url: item.url,
        originalName: item['original-name']
      })));
      setCarfaxAutocheck((tracking?.data?.['carfax-autocheck'] ?? []).map(item => ({
        url: item.url,
        originalName: item['original-name']
      })));
      setConosament((tracking?.data?.['conosament'] ?? []).map(item => ({
        url: item.url,
        originalName: item['original-name']
      })));
      setTitle((tracking?.data?.['title'] ?? []).map(item => ({
        url: item.url,
        originalName: item['original-name']
      })));

      setTrackingOrig(tracking);
      setTracking(tracking);
    })();
    // eslint-disable-next-line
  }, []);

  function reset(): void {
    // Clear input field
    EgoUtil.clearFields(_eForm.current);
    // Roll back default state
    setShowNoteSparePartsOrder(false);
    setShowNoteAutoForCutting(false);
    setShowAuction(false);
    // Clear uploads
    setPhotosAuction([]);
    setPhotosBeforeLoading([]);
    setPhotosAfterUploading([]);
    setBillOfSale([]);
    setCarfaxAutocheck([]);
    setConosament([]);
    setTitle([]);
    // Clear dates
    $(_eForm.current).find('[name]').trigger('change');
  }

  /**
   * Modify all steps for using dnd
   *
   * @param {any[]} steps
   * @returns {any[]}
   */
  function dndSteps(steps: any[]): any[] {
    for (const key in steps) {
      steps[key].dndId = `step-${key}`
    }

    return steps;
  }

  function onSubmit(e): void {
    if (!_eForm.current.checkValidity()) {
      return;
    }

    e.preventDefault();

    // Set `Save Process` flag
    setSaveProcess(true);

    const eForm = $(_eForm.current);
    const formData = new FormData();
    // Body type
    formData.append('body-type', bodyType ? bodyType.value : '');
    // Vin
    formData.append('vin', eForm.find('[name="vin"]').val());
    // Year
    formData.append('year', eForm.find('[name="year"]').val());
    // Brand
    formData.append('brand', eForm.find('[name="brand"]').val());
    // Model
    formData.append('model', eForm.find('[name="model"]').val());
    // Spare Parts Order
    formData.append('spare-parts-order', eForm.find('[name="spare-parts-order"]').is(':checked') === true ? '1' : '0');
    // Note Spare Parts Order
    formData.append('note-spare-parts-order', eForm.find('[name="note-spare-parts-order"]').val());
    // Auto For Cutting
    formData.append('auto-for-cutting', eForm.find('[name="auto-for-cutting"]').is(':checked') === true ? '1' : '0');
    // Note Spare Parts Order
    formData.append('note-auto-for-cutting', eForm.find('[name="note-auto-for-cutting"]').val());
    // Photos Auction
    photosAuction.forEach((file, i) => {
      if (file.format === 'file') {
        formData.append(`files-photos-auction[${i}][file]`, file.file);
      } else {
        formData.append(`photos-auction[${i}][url]`, (new URL(file.url)).pathname);
        formData.append(`photos-auction[${i}][original-name]`, file.originalName);
      }
    });
    /*_ePhotosAuction.urlFiles().forEach((file, i) => {
      formData.append(`photos-auction[${i}][url]`, (new URL(file.url)).pathname);
      formData.append(`photos-auction[${i}][original-name]`, file.originalName);
    });
    // Uploaded Photos Auction
    _ePhotosAuction.uploadedFiles().forEach((file, i) => {
      formData.append(`files-photos-auction[${i}][file]`, file.file);
    });*/
    // Photos Before Loading
    photosBeforeLoading.forEach((file, i) => {
      if (file.format === 'file') {
        formData.append(`files-photos-before-loading[${i}][file]`, file.file);
      } else {
        formData.append(`photos-before-loading[${i}][url]`, (new URL(file.url)).pathname);
        formData.append(`photos-before-loading[${i}][original-name]`, file.originalName);
      }
    });
    /*_ePhotosBeforeLoading.urlFiles().forEach((file, i) => {
      formData.append(`photos-before-loading[${i}][url]`, (new URL(file.url)).pathname);
      formData.append(`photos-before-loading[${i}][original-name]`, file.originalName);
    });
    // Uploaded Photos Auction
    _ePhotosBeforeLoading.uploadedFiles().forEach((file, i) => {
      formData.append(`files-photos-before-loading[${i}][file]`, file.file);
    });*/
    // Photos After Uploading
    photosAfterUploading.forEach((file, i) => {
      if (file.format === 'file') {
        formData.append(`files-photos-after-uploading[${i}][file]`, file.file);
      } else {
        formData.append(`photos-after-uploading[${i}][url]`, (new URL(file.url)).pathname);
        formData.append(`photos-after-uploading[${i}][original-name]`, file.originalName);
      }
    });
    /*_ePhotosAfterUploading.urlFiles().forEach((file, i) => {
      formData.append(`photos-after-uploading[${i}][url]`, (new URL(file.url)).pathname);
      formData.append(`photos-after-uploading[${i}][original-name]`, file.originalName);
    });
    // Uploaded After Uploading
    _ePhotosAfterUploading.uploadedFiles().forEach((file, i) => {
      formData.append(`files-photos-after-uploading[${i}][file]`, file.file);
    });*/

    // Sale Type
    formData.append('sale-type', saleType ? saleType.value : '');
    // Auction
    formData.append('auction', eForm.find('[name="auction"]').val());
    // Lot Link
    formData.append('lot-link', eForm.find('[name="lot-link"]').val());
    // Trucking
    formData.append('trucking', trucking ? trucking.value : '');
    // Bill Of Sale
    billOfSale.forEach((file, i) => {
      if (file.format === 'file') {
        formData.append(`files-bill-of-sale[${i}][file]`, file.file);
      } else {
        formData.append(`bill-of-sale[${i}][url]`, (new URL(file.url)).pathname);
        formData.append(`bill-of-sale[${i}][original-name]`, file.originalName);
      }
    });
    /*_eBillOfSale.urlFiles().forEach((file, i) => {
      formData.append(`bill-of-sale[${i}][url]`, (new URL(file.url)).pathname);
      formData.append(`bill-of-sale[${i}][original-name]`, file.originalName);
    });
    // Uploaded Bill Of Sale
    _eBillOfSale.uploadedFiles().forEach((file, i) => {
      formData.append(`files-bill-of-sale[${i}][file]`, file.file);
    });*/
    // Carfax/Autocheck
    carfaxAutocheck.forEach((file, i) => {
      if (file.format === 'file') {
        formData.append(`files-carfax-autocheck[${i}][file]`, file.file);
      } else {
        formData.append(`carfax-autocheck[${i}][url]`, (new URL(file.url)).pathname);
        formData.append(`carfax-autocheck[${i}][original-name]`, file.originalName);
      }
    });
    /*_eCarfaxAutocheck.urlFiles().forEach((file, i) => {
      formData.append(`carfax-autocheck[${i}][url]`, (new URL(file.url)).pathname);
      formData.append(`carfax-autocheck[${i}][original-name]`, file.originalName);
    });
    // Uploaded Carfax/Autocheck
    _eCarfaxAutocheck.uploadedFiles().forEach((file, i) => {
      formData.append(`files-carfax-autocheck[${i}][file]`, file.file);
    });*/
    // Conosament
    conosament.forEach((file, i) => {
      if (file.format === 'file') {
        formData.append(`files-conosament[${i}][file]`, file.file);
      } else {
        formData.append(`conosament[${i}][url]`, (new URL(file.url)).pathname);
        formData.append(`conosament[${i}][original-name]`, file.originalName);
      }
    });
    /*_eConosament.urlFiles().forEach((file, i) => {
      formData.append(`conosament[${i}][url]`, (new URL(file.url)).pathname);
      formData.append(`conosament[${i}][original-name]`, file.originalName);
    });
    // Uploaded Conosament
    _eConosament.uploadedFiles().forEach((file, i) => {
      formData.append(`files-conosament[${i}][file]`, file.file);
    });*/

    // Port
    formData.append('port', port ? port.value : '');
    // Country
    formData.append('country', eForm.find('[name="country"]').val());
    // City
    formData.append('city', eForm.find('[name="city"]').val());
    // Consolidation
    formData.append('consolidation', consolidation ? consolidation.value : '');
    // Date and time of loading
    formData.append('date-of-loading', moment(dateOfLoading).format(Config.DATE_FORMAT));
    // Date of stay at destination
    formData.append('date-of-stay-at-destination', moment(dateOfStayAtDestination).format(Config.DATE_FORMAT));
    // Link Autoastat
    formData.append('link-autoastat', eForm.find('[name="link-autoastat"]').val());
    // Invoice
    formData.append('invoice', invoice ? invoice.value : '');
    // Loading Port USA
    formData.append('loading-port-usa', eForm.find('[name="loading-port-usa"]').val());
    // Container Number
    formData.append('container-number', eForm.find('[name="container-number"]').val());
    // Booking number
    formData.append('booking-number', eForm.find('[name="booking-number"]').val());
    // Final Use
    formData.append('final-use', finalUse ? finalUse.value : '');
    // Title
    title.forEach((file, i) => {
      if (file.format === 'file') {
        formData.append(`files-title[${i}][file]`, file.file);
      } else {
        formData.append(`title[${i}][url]`, (new URL(file.url)).pathname);
        formData.append(`title[${i}][original-name]`, file.originalName);
      }
    });
    /*_eTitle.urlFiles().forEach((file, i) => {
      formData.append(`title[${i}][url]`, (new URL(file.url)).pathname);
      formData.append(`title[${i}][original-name]`, file.originalName);
    });
    // Uploaded Title
    _eTitle.uploadedFiles().forEach((file, i) => {
      formData.append(`files-title[${i}][file]`, file.file);
    });*/

    // Steps
    steps.forEach((step, i) => {
      formData.set(`steps[${i}][id]`, step.id);
      formData.set(`steps[${i}][name]`, step.name);
      formData.set(`steps[${i}][date]`, step.date.format(Config.DATE_FORMAT));
      formData.set(`steps[${i}][status]`, step.status ? '1' : '0');
    });

    // Save form
    let url;

    if (tracking.id > 0) {
      url = Config.api(`/tracking/update/${tracking.id}`);
    } else {
      url = Config.api('/tracking/create');
    }

    axios.post(url, formData, {
      headers: {'Content-Type': 'multipart/form-data'}
    })
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);
          // Unset `Save Process` flag
          setSaveProcess(false);

          return;
        }

        const updatedTracking = response.data;

        // Mark as saved
        setSaveNotify(true);

        // Add tracking to state after saving
        if (!(tracking.id > 0)) {
          history.replace(`/admin/tracking/form/${response.data.id}`);

          // Update tracking
          setTracking(response.data);

          // Steps
          if (tracking.data && Array.isArray(tracking.data.steps)) {
            setSteps(dndSteps(tracking.data.steps));
          }
        }

        // Unset `Save Process` flag
        setSaveProcess(false);

        // Set uploads
        setPhotosAuction((updatedTracking?.data?.['photos-auction'] ?? []).map(item => ({
          url: item.url,
          originalName: item['original-name']
        })));
        setPhotosBeforeLoading((updatedTracking?.data?.['photos-before-loading'] ?? []).map(item => ({
          url: item.url,
          originalName: item['original-name']
        })));
        setPhotosAfterUploading((updatedTracking?.data?.['photos-after-uploading'] ?? []).map(item => ({
          url: item.url,
          originalName: item['original-name']
        })));
        setBillOfSale((updatedTracking?.data?.['bill-of-sale'] ?? []).map(item => ({
          url: item.url,
          originalName: item['original-name']
        })));
        setCarfaxAutocheck((updatedTracking?.data?.['carfax-autocheck'] ?? []).map(item => ({
          url: item.url,
          originalName: item['original-name']
        })));
        setConosament((updatedTracking?.data?.['conosament'] ?? []).map(item => ({
          url: item.url,
          originalName: item['original-name']
        })));
        setTitle((updatedTracking?.data?.['title'] ?? []).map(item => ({
          url: item.url,
          originalName: item['original-name']
        })));
      });
  }

  // Search invoices based on input
  const invoiceLoadOptions = async input => {
    input = input ? input : '';

    // Min input length
    if (input && input.length < 2)
      return false;

    const q = qs.stringify({
      invoice_number: input,
    });
    const response = (await axios.get(Config.api(`/invoices/search?${q}`))).data;

    if (!response.success)
      return [];

    const options = response.data.map((item) => ({
      value: item.id,
      label: item.data['invoice-number']
    }));

    // Initial value
    if (invoice === undefined && tracking.data
      && parseInt(tracking.data.invoice) > 0) {
      const valueResponse = (await axios.get(Config.api(
        `/invoices/search?id=${tracking.data.invoice}`)
      )).data;
      const item = valueResponse.success ? valueResponse.data[0] : null;
      setInvoice(item ? {
        value: item.id,
        label: item.data['invoice-number']
      } : null);
    }

    return options;
  };

  function onDragEnd(result) {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }

    const _steps = steps.slice();
    const [step] = _steps.splice(result.source.index, 1);
    _steps.splice(result.destination.index, 0, step);
    setSteps(_steps);
  }

  return (
    <>
      {tracking ?
        <div
          id="tracking-component"
        >
          <Head
            head={t('admin:tracking.form.head')}
            subhead={'VIN - Auto'}
            right={<div className="d-flex justify-content-lg-end justify-content-start train-buttons">
              <button
                form="tracking-form-component"
                className="btn btn-save"
                disabled={saveProcess}
              >
                {t('buttons.save')}
              </button>
              <button
                type="button"
                className="btn btn-reload"
                onClick={() => reset()}
              >
                {t('buttons.reset')}
              </button>
              <button
                type="button"
                className="btn btn-remove"
              >
                {t('buttons.remove')}
              </button>
            </div>}
            hr={{style: {marginTop: 20}}}
          />

          <form
            ref={_eForm}
            id="tracking-form-component"
            onSubmit={onSubmit}
          >
            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelDetails className="pt-0">
                <div className="row d-flex flex-grow-1">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Body type*/}
                    <Select
                      label={t('admin:tracking.body_type')}
                      select={{
                        isSearchable: false,
                        options: bodyTypes,
                        value: bodyType,
                        components: {
                          DropdownIndicator: DownArrowIndicator,
                        },
                        styles: SelectStylesHideIndicatorSeparator,
                        onChange: option => setBodyType(option)
                      }}
                      required={true}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Vin*/}
                    <Input
                      label={t('admin:tracking.vin')}
                      input={{
                        name: 'vin',
                        id: 'vin',
                        defaultValue: trackingOrig.data ? trackingOrig.data['vin'] : null,
                        required: true,
                        inputProps: {
                          pattern: '^[a-zA-Z0-9]+$',
                          minLength: 17,
                          maxLength: 17
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Year*/}
                    <Input
                      label={t('admin:tracking.year')}
                      input={{
                        type: 'number',
                        name: 'year',
                        id: 'year',
                        defaultValue: trackingOrig.data ? trackingOrig.data['year'] : null,
                        required: true
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Brand*/}
                    <Input
                      label={t('admin:tracking.brand')}
                      input={{
                        name: 'brand',
                        id: 'brand',
                        defaultValue: tracking.data ? tracking.data['brand'] : null,
                        required: true
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Model*/}
                    <Input
                      label={t('admin:tracking.model')}
                      input={{
                        name: 'model',
                        id: 'model',
                        defaultValue: trackingOrig.data ? trackingOrig.data['model'] : null,
                        required: true
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <div>
                      {/*Spare parts order*/}
                      <Checkbox
                        label={{
                          label: t('admin:tracking.spare_parts_order')
                        }}
                        checkbox={{
                          name: 'spare-parts-order',
                          defaultChecked: trackingOrig.data ? trackingOrig.data['spare-parts-order'] : null,
                          onChange: (e, checked) => setShowNoteSparePartsOrder(checked)
                        }}
                        bottomOffset={0}
                      />
                    </div>
                    <div>
                      {/*Auto for cutting*/}
                      <Checkbox
                        label={{
                          label: t('admin:tracking.auto_for_cutting')
                        }}
                        checkbox={{
                          name: 'auto-for-cutting',
                          defaultChecked: trackingOrig.data ? trackingOrig.data['auto-for-cutting'] : null,
                          onChange: (e, checked) => setShowNoteAutoForCutting(checked)
                        }}
                        bottomOffset={0}
                      />
                    </div>
                  </div>
                  <div className={`col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 ${showNoteSparePartsOrder ? '' : 'hide'}`}>
                    {/*Note spare parts order*/}
                    <Textarea
                      label={t('admin:tracking.note_spare_parts_order')}
                      textarea={{
                        name: 'note-spare-parts-order',
                        defaultValue: trackingOrig.data ? tracking.data['note-spare-parts-order'] : null,
                        rowsMin: 3,
                        rowsMax: 3
                      }}
                    />
                  </div>
                  <div className={`col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 ${showNoteAutoForCutting ? '' : 'hide'}`}>
                    {/*Note auto for cutting*/}
                    <Textarea
                      label={t('admin:tracking.note_auto_for_cutting')}
                      textarea={{
                        name: 'note-auto-for-cutting',
                        defaultValue: trackingOrig.data ? trackingOrig.data['note-auto-for-cutting'] : null,
                        rowsMin: 3,
                        rowsMax: 3
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Photos Auction*/}
                    <Upload
                      name="photos-auction"
                      id="photos-auction"
                      label={t('admin:tracking.photos_auction')}
                      files={photosAuction}
                      placeholder={t('admin:tracking.photos_placeholder')}
                      accept={['image/png', 'image/jpeg']}
                      maxFiles={-1}
                      multiple={true}
                      onLoad={(files) => setPhotosAuction(files)}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Photos Before Loading*/}
                    <Upload
                      name="photos-before-loading"
                      id="photos-before-loading"
                      label={t('admin:tracking.photos_before_loading')}
                      files={photosBeforeLoading}
                      placeholder={t('admin:tracking.photos_placeholder')}
                      accept={['image/png', 'image/jpeg']}
                      maxFiles={-1}
                      multiple={true}
                      onLoad={(files) => setPhotosBeforeLoading(files)}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Photos After Uploading*/}
                    <Upload
                      name="photos-after-uploading"
                      id="photos-after-uploading"
                      label={t('admin:tracking.photos_after_uploading')}
                      files={photosAfterUploading}
                      placeholder={t('admin:tracking.photos_placeholder')}
                      accept={['image/png', 'image/jpeg']}
                      maxFiles={-1}
                      multiple={true}
                      onLoad={(files) => setPhotosAfterUploading(files)}
                    />
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <hr style={{marginTop: '29px'}} />

            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelDetails className="pt-0">
                <div className="row d-flex flex-grow-1">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Sale type*/}
                    <Select
                      label={t('admin:tracking.sale_type')}
                      select={{
                        isSearchable: false,
                        options: saleTypes,
                        value: saleType,
                        components: {
                          DropdownIndicator: DownArrowIndicator,
                        },
                        styles: SelectStylesHideIndicatorSeparator,
                        onChange: option => {
                          setSaleType(option);
                          setShowAuction(option.value === 'auction');
                        }
                      }}
                      required={true}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Auction*/}
                    <div className={(showAuction ? '' : 'hide')}>
                      <Input
                        label={t('admin:tracking.auction')}
                        input={{
                          name: 'auction',
                          id: 'auction',
                          defaultValue: trackingOrig.data ? trackingOrig.data['auction'] : null
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Link to the lot*/}
                    <Input
                      label={t('admin:tracking.lot_link')}
                      input={{
                        type: 'url',
                        name: 'lot-link',
                        id: 'lot-link',
                        defaultValue: trackingOrig.data ? trackingOrig.data['lot-link'] : null
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Trucking*/}
                    <Select
                      label={t('admin:tracking.trucking')}
                      select={{
                        isSearchable: false,
                        options: truckingOptions,
                        value: trucking,
                        components: {
                          DropdownIndicator: DownArrowIndicator,
                        },
                        styles: SelectStylesHideIndicatorSeparator,
                        onChange: option => setTrucking(option)
                      }}
                      required={true}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Bill Of Sale*/}
                    <Upload
                      name="bill-of-sale"
                      id="bill-of-sale"
                      label={t('admin:tracking.bill_of_sale')}
                      files={billOfSale}
                      placeholder={t('admin:tracking.bill_of_sale_placeholder')}
                      accept={['application/pdf']}
                      multiple={false}
                      maxFiles={1}
                      onLoad={(files) => setBillOfSale(files)}
                    />
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <hr style={{marginTop: '29px'}} />

            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelDetails className="pt-0">
                <div className="row d-flex flex-grow-1">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Port*/}
                    <Select
                      label={t('admin:tracking.port')}
                      select={{
                        isSearchable: false,
                        options: ports,
                        value: port,
                        components: {
                          DropdownIndicator: DownArrowIndicator,
                        },
                        styles: SelectStylesHideIndicatorSeparator,
                        onChange: option => setPort(option)
                      }}
                      required={true}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Country*/}
                    <Input
                      label={t('admin:tracking.country')}
                      input={{
                        name: 'country',
                        id: 'country',
                        defaultValue: trackingOrig.data ? trackingOrig.data['country'] : null
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*City*/}
                    <Input
                      label={t('admin:tracking.city')}
                      input={{
                        name: 'city',
                        id: 'city',
                        defaultValue: trackingOrig.data ? trackingOrig.data['city'] : null
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Consolidation*/}
                    <Select
                      label={t('admin:tracking.consolidation')}
                      select={{
                        isSearchable: false,
                        options: consolidations,
                        value: consolidation,
                        components: {
                          DropdownIndicator: DownArrowIndicator,
                        },
                        styles: SelectStylesHideIndicatorSeparator,
                        onChange: option => setConsolidation(option)
                      }}
                      required={true}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Date and time of loading*/}
                    <KeyboardDatePicker
                      label={t('admin:tracking.date_of_loading')}
                      KeyboardDatePicker={{
                        value: dateOfLoading,
                        onChange: date => setDateOfLoading(date)
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Date of stay at destination*/}
                    <KeyboardDatePicker
                      label={t('admin:tracking.date_of_stay_at_destination')}
                      KeyboardDatePicker={{
                        value: dateOfStayAtDestination,
                        onChange: date => setDateOfStayAtDestination(date)
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Link Autoastat*/}
                    <Input
                      label={t('admin:tracking.link_autoastat')}
                      input={{
                        type: 'url',
                        name: 'link-autoastat',
                        id: 'link-autoastat',
                        defaultValue: trackingOrig.data ? trackingOrig.data['link-autoastat'] : null
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Invoice*/}
                    <Select
                      label={t('admin:tracking.select_invoice')}
                      async={true}
                      select={{
                        cacheOptions: true,
                        loadOptions: invoiceLoadOptions,
                        defaultOptions: true,
                        value: invoice,
                        components: {
                          DropdownIndicator: DownArrowIndicator,
                        },
                        styles: SelectStylesHideIndicatorSeparator,
                        onChange: option => setInvoice(option)
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Port of loading USA*/}
                    <Input
                      label={t('admin:tracking.loading_port_usa')}
                      input={{
                        name: 'loading-port-usa',
                        id: 'loading-port-usa',
                        defaultValue: trackingOrig.data ? trackingOrig.data['loading-port-usa'] : null
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Port of loading USA*/}
                    <Input
                      label={t('admin:tracking.container_number')}
                      input={{
                        name: 'container-number',
                        id: 'container-number',
                        defaultValue: trackingOrig.data ? trackingOrig.data['container-number'] : null
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Booking Number*/}
                    <Input
                      label={t('admin:tracking.booking_number')}
                      input={{
                        name: 'booking-number',
                        id: 'booking-number',
                        defaultValue: trackingOrig.data ? trackingOrig.data['booking-number'] : null
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Final use*/}
                    <Select
                      label={t('admin:tracking.final_use')}
                      select={{
                        isSearchable: false,
                        options: finalUseOptions,
                        value: finalUse,
                        components: {
                          DropdownIndicator: DownArrowIndicator,
                        },
                        styles: SelectStylesHideIndicatorSeparator,
                        onChange: option => setFinalUse(option)
                      }}
                      required={true}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Title*/}
                    <Upload
                      name="title"
                      id="title"
                      label={t('admin:tracking.title')}
                      defaultFiles={title}
                      placeholder={t('admin:tracking.title_placeholder')}
                      accept={['application/pdf']}
                      multiple={false}
                      maxFiles={1}
                      onLoad={(files) => setTitle(files)}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Carfax/Autocheck*/}
                    <Upload
                      name="carfax-autocheck"
                      id="carfax-autocheck"
                      label={t('admin:tracking.carfax_autocheck')}
                      defaultFiles={carfaxAutocheck}
                      placeholder={t('admin:tracking.title_placeholder')}
                      accept={['application/pdf']}
                      multiple={false}
                      maxFiles={1}
                      onLoad={(files) => setCarfaxAutocheck(files)}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Conosament*/}
                    <Upload
                      name="conosament"
                      id="conosament"
                      label={t('admin:tracking.conosament')}
                      defaultFiles={tracking.data ? tracking.data['conosament'] : []}
                      placeholder={t('admin:tracking.title_placeholder')}
                      accept={['application/pdf']}
                      multiple={false}
                      maxFiles={1}
                    />
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </form>

          <hr style={{marginTop: '29px'}} />

          <div className="row">
            <div className="col-12 col-md-12">
              <div className="steps-wrap">
                <div className="steps">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {steps.map((step, i) =>
                            <Draggable
                              key={step.dndId}
                              draggableId={step.dndId}
                              index={i}
                            >
                              {(provided, snapshot) => (
                                <div>
                                  <form
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                    className="row d-flex align-items-start step-row step"
                                  >
                                    {/*Action*/}
                                    <div
                                      className="col-12 col-md-1 action d-flex align-items-center action"
                                      style={{height: 37, marginTop: 31}}
                                    >
                                      <button
                                        type={'button'}
                                        onClick={() => {
                                          const _steps = [...steps];
                                          _steps.splice(i, 1);
                                          setSteps(_steps);
                                        }}
                                      >
                                        <img
                                          src={'/assets/images/icon-delete.svg'}
                                          alt="icon-delete"
                                        />
                                      </button>
                                    </div>
                                    {/*Name*/}
                                    <div className="col-12 col-md-3">
                                      <div className="step-label">
                                        {t('admin:tracking.form.step')}
                                      </div>
                                      <InputAutosuggest
                                        hideLabel={true}
                                        Autosuggest={{
                                          suggestions: stepsSuggestions,
                                          inputProps: {
                                            name: 'step-name',
                                            value: step.name ? step.name : '',
                                            placeholder: t('admin:tracking.step_name'),
                                            required: true,
                                            onChange: (e, suggestion, {newValue}) => {
                                              const _steps = [...steps];
                                              _steps[i].name = suggestion ? suggestion.text : newValue;
                                              setSteps(_steps);
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                    {/*Date*/}
                                    <div className="col-12 col-md-3">
                                      <div className="step-label">
                                        {t('admin:tracking.form.date')}
                                      </div>
                                      <KeyboardDatePicker
                                        hideLabel={true}
                                        KeyboardDatePicker={{
                                          name: 'step-date',
                                          placeholder: t('admin:tracking.step_date'),
                                          value: step.date ? step.date : null,
                                          onChange: (date) => {
                                            if (!date)
                                              return;

                                            const _steps = [...steps];
                                            _steps[i].date = date;
                                            setSteps(_steps);
                                          }
                                        }}
                                      />
                                    </div>
                                    {/*Status*/}
                                    <div
                                      className="col-12 col-md-3 d-flex align-items-center action"
                                      style={{height: 37, marginTop: 31}}
                                    >
                                      <Checkbox
                                        label={{
                                          label: t('admin:tracking.step_status'),
                                          labelPlacement: 'start'
                                        }}
                                        checkbox={{
                                          name: 'step-status',
                                          checked: step.status,
                                          onChange: (e, checked) => {
                                            const _steps = [...steps];
                                            _steps[i].status = checked;
                                            setSteps(_steps);
                                          }
                                        }}
                                        bottomOffset={0}
                                      />
                                    </div>
                                  </form>
                                  {provided.placeholder}
                                </div>
                              )}
                            </Draggable>
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                {/*Add new*/}
                <form
                  className="row d-flex align-items-start step-row step"
                  onSubmit={(e) => {
                    e.preventDefault();

                    let _steps = [...steps];
                    _steps.push({
                      dndId: null,
                      id: addStepId,
                      name: addStepName,
                      date: addStepDate,
                      status: addStepStatus === true
                    });
                    _steps = dndSteps(_steps);
                    setSteps(_steps);

                    // Clear input field
                    EgoUtil.clearFields(e.target);
                    setAddStepId(null);
                    setAddStepName(null);
                    setAddStepDate(null);
                    setAddStepStatus(false);
                  }}
                >
                  {/*Action*/}
                  <div
                    className="col-12 col-md-1 d-flex align-items-center action"
                    style={{height: 37, marginTop: 31}}
                  >
                    <button>
                      <img
                        src={'/assets/images/icon-plus.svg'}
                        alt="icon-plus"
                      />
                    </button>
                  </div>
                  {/*Name*/}
                  <div className="col-12 col-md-3">
                    <div className="step-label">
                      {t('admin:tracking.form.step')}
                    </div>
                    <InputAutosuggest
                      hideLabel={true}
                      Autosuggest={{
                        suggestions: stepsSuggestions,
                        inputProps: {
                          name: 'step-name',
                          value: addStepName ?? '',
                          placeholder: t('admin:tracking.step_name'),
                          required: true,
                          onChange: (e, suggestion, {newValue}) => {
                            setAddStepId(suggestion ? suggestion.id ? suggestion.id : '' : '');
                            setAddStepName(suggestion ? suggestion.text : newValue);
                          }
                        }
                      }}
                    />
                  </div>
                  {/*Date*/}
                  <div className="col-12 col-md-3">
                    <div className="step-label">
                      {t('admin:tracking.form.date')}
                    </div>
                    <KeyboardDatePicker
                      hideLabel={true}
                      KeyboardDatePicker={{
                        value: addStepDate,
                        onChange: (date) => setAddStepDate(date)
                      }}
                    />
                  </div>
                  {/*Status*/}
                  <div
                    className="col-12 col-md-3 d-flex align-items-center action"
                    style={{height: 37, marginTop: 31}}
                  >
                    <Checkbox
                      label={{
                        label: t('admin:tracking.step_status'),
                        labelPlacement: 'start'
                      }}
                      checkbox={{
                        name: 'add-step-status',
                        checked: addStepStatus === true,
                        onChange: (e, checked) => setAddStepStatus(checked)
                      }}
                      bottomOffset={0}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>

          <hr style={{marginTop: '29px'}} />

          <div className="row">
            <div className="col-12 col-md-6">
              <div className="tracking-id">
                {/*Tracking ID*/}
                <Input
                  label={t('admin:tracking.tracking_id') + ':'}
                  input={{
                    value: tracking.code ?? '',
                    readOnly: true
                  }}
                />
              </div>
            </div>
          </div>

          {/*Share*/}
          {tracking && tracking.id > 0 ?
            <Share
              className="mb-3"
              share={tracking.id}
              module={'tracking'}
            />
            :
            ''
          }

          <BottomToolbar
            right={<>
              <div className="d-flex justify-content-lg-end justify-content-start train-buttons">
                <button
                  form="tracking-form-component"
                  className="btn btn-save"
                  disabled={saveProcess}
                >
                  {t('buttons.save')}
                </button>
                <button
                  type="button"
                  className="btn btn-reload"
                  onClick={() => reset()}
                >
                  {t('buttons.reset')}
                </button>
                <button
                  type="button"
                  className="btn btn-remove"
                >
                  {t('buttons.remove')}
                </button>
              </div>
              <div className="d-flex justify-content-end">
                <Notify
                  type="save-form"
                  hide={!saveNotify}
                />
              </div>
            </>}
          />
        </div>
        :
        ''
      }
    </>
  );
};

export default TrackingForm;
