export default class Cars {

  public static readonly startYear = 1998;

  // Return years of available cars
  years(): number[] {
    return [...Array((new Date()).getFullYear() - 1998 + 1)].map((item, i) => Cars.startYear + i).reverse();
  }

}
