import React, {FC, useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import './Tracking.scss';
import TrackingProvider from '../../Providers/Tracking';
import AdminContext from '../AdminContext';
import IRole from '../../Interfaces/IRole';
import {UserPermission} from '../../Providers/UserPermission';
import Head from '../Head/Head';
import {RowActionMenuItemEdit, RowActionMenuItemView} from '../../Core/Form/Table/useRowActions';
import Table, {useRowActions} from '../../Core/Form/Table/Table';

const trackingProvider = new TrackingProvider();

const Tracking: FC = () => {
  const {t} = useTranslation();
  const context = useContext(AdminContext);
  const columns = useMemo(() => [
    {
      Header: t('admin:tracking.date'),
      accessor: 'createdAt',
    },
    {
      Header: t('admin:tracking.tracking_id'),
      accessor: 'code'
    },
    {
      Header: t('admin:tracking.body_type'),
      accessor: 'data.bodyType'
    },
    {
      Header: t('admin:tracking.vin'),
      accessor: 'data.vin'
    },
    {
      Header: t('admin:tracking.year'),
      accessor: 'data.year'
    },
    {
      Header: t('admin:tracking.brand'),
      accessor: 'data.brand'
    },
    {
      Header: t('admin:tracking.model'),
      accessor: 'data.model'
    },
    // eslint-disable-next-line
  ], []);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const fetchIdRef = useRef(0);
  const _tableRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const menuItems = useMemo(() => {
    const items = [];

    // Edit
    if (IRole.checkPermission(context.auth.user.role, [
      UserPermission.TRACKINGS_FULL
    ])) {
      items.push(<RowActionMenuItemEdit
        link={rowIndex => {
          const table = _tableRef.current;
          const row = table.page[rowIndex].original;

          return {
            to: `/admin/tracking/form/${row.id}`
          };
        }}
      />);
    }

    // View
    items.push(<RowActionMenuItemView
      href={rowIndex => {
        const table = _tableRef.current;
        const row = table.page[rowIndex].original;

        return {
          href: `https://gr8logistics.com/tracking/?tracking_code=${row.code}`,
          target: '_blank'
        };
      }}
    />)

    return items;
    // eslint-disable-next-line
  }, []);

  // Get table data
  const fetchData = useCallback(({pageIndex, pageSize}) => {
    const fetchId = ++fetchIdRef.current;
    setLoading(true);

    if (fetchId !== fetchIdRef.current)
      return;

    (async () => {
      const list = (await trackingProvider.list({
        page_index: pageIndex,
        page_size: pageSize
      }));

      if (!list)
        return;

      setData(list.data);
      setCount(list.count);
      setPageCount(Math.ceil(list.count / pageSize));
      setLoading(false);
    })();
  }, []);

  return (
    <div id="tracking-table-component">
      <Head head={t('admin:tracking.head')} />

      <Table
        tableRef={_tableRef}
        table={{
          columns,
          data,
          initialState: {pageIndex: 0},
          manualPagination: true,
          pageCount
        }}
        count={count}
        tableHooks={[
          useRowActions({
            menuItems
          }),
        ].reverse()}
        fetchData={fetchData}
        loading={loading}
        toolbar={{
          top: {
            right: <>
              {IRole.checkPermission(context.auth.user.role, [
                UserPermission.TRACKINGS_FULL
              ]) && <div className="row">
                <div className="col-12 d-flex justify-content-end train-buttons">
                  <Link
                      to={'/admin/tracking/form'}
                      className="btn btn-create"
                  >
                    {t('buttons.create')}
                  </Link>
                </div>
              </div>}
            </>
          }
        }}
      />
    </div>
  );
}

export default Tracking;
