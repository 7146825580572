import React, {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import './CustomerZoneBalanceForm.scss';
import Input from '../../Core/Form/Input/Input';
import axios from 'axios';
import Config from '../../Providers/Config';
import $ from 'jquery';
import {IHttpResponse} from '../../Interfaces/IHttpResponse';
import * as EgoUtil from '@egofoxlab/util';
import Textarea from '../../Core/Form/Textarea/Textarea';
import Notify from '../../Core/Ui/Notify/Notify';
import Select, {SelectStylesHideIndicatorSeparator} from '../../Core/Form/Select/Select';
import Share from '../Share/Share';
import Head from '../Head/Head';
import BottomToolbar from '../BottomToolbar/BottomToolbar';
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary} from '@material-ui/core';
import DownArrowIndicator from '../../Core/Form/Select/Indicators/DownArrowIndicator';

// Load form
async function load(id) {
  const response = (await axios.get(Config.api(`/customer-zone/balance/get/${id}`))).data;

  if (!response.success) {
    console.error(response.message);

    return false;
  }

  return response.data;
}

const CustomerZoneBalanceForm: FC = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const id = parseInt(useParams().id) || null;
  const [customerZoneBalance, setCustomerZoneBalance] = useState(null);
  const [customerZoneAuto, setCustomerZoneAuto] = useState<any>();
  const [saveProcess, setSaveProcess] = useState(false);
  const [saveNotify, setSaveNotify] = useState(false);
  const _eForm = useRef<HTMLFormElement>(null);

  useEffect(() => {
    (async () => {
      const customerZoneBalance = id > 0 ? await load(id) : {};

      if (!customerZoneBalance) {
        history.push('/admin/customer-zone/balance');

        return;
      }

      setCustomerZoneBalance(customerZoneBalance);
    })();
    // eslint-disable-next-line
  }, []);

  function reset(): void {
    //  Clear input field
    EgoUtil.clearFields(_eForm.current);
    $(_eForm.current).find('[name]').trigger('change');
    setCustomerZoneAuto(null);
  }

  function onSubmit(e): void {
    if (!_eForm.current.checkValidity()) {
      return;
    }

    e.preventDefault();

    //  Set `Save Process` flag
    setSaveProcess(true);

    const eForm = $(_eForm.current);
    const formData = new FormData();
    //  Customer Zone Auto
    formData.append('customer-zone-auto', customerZoneAuto?.value ?? '');
    //  City
    formData.append('city', eForm.find('[name="city"]').val());
    //  Price
    formData.append('price', eForm.find('[name="price"]').val());
    //  Delivery
    formData.append('delivery', eForm.find('[name="delivery"]').val());
    //  Shipping
    formData.append('shipping', eForm.find('[name="shipping"]').val());
    //  Dealer fee
    formData.append('dealer-fee', eForm.find('[name="dealer-fee"]').val());
    //  Storage Auction Fee
    formData.append('storage-auction-fee', eForm.find('[name="storage-auction-fee"]').val());
    //  Storage fee
    formData.append('storage-fee', eForm.find('[name="storage-fee"]').val());
    //  Loading fee
    formData.append('loading-fee', eForm.find('[name="loading-fee"]').val());
    //  Late fee
    formData.append('late-fee', eForm.find('[name="late-fee"]').val());
    //  Insurance
    formData.append('insurance', eForm.find('[name="insurance"]').val());
    //  Current balance
    formData.append('current-balance', eForm.find('[name="current-balance"]').val());
    //  Payed amount
    formData.append('payed-amount', eForm.find('[name="payed-amount"]').val());
    //  Balance
    formData.append('balance', eForm.find('[name="balance"]').val());
    //  Custom Notes
    formData.append('custom-notes', eForm.find('[name="custom-notes"]').val());

    //  Save form
    let url;

    if (customerZoneBalance.id > 0) {
      url = Config.api(`/customer-zone/balance/update/${customerZoneBalance.id}`);
    } else {
      url = Config.api('/customer-zone/balance/create');
    }

    axios.post(url, formData, {
      headers: {'Content-Type': 'multipart/form-data'}
    })
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);
          //  Unset `Save Process` flag
          setSaveProcess(false);

          return;
        }

        const state: any = {};

        //  Mark as saved
        setSaveNotify(true);

        //  Add customer zone to state after saving
        if (!(customerZoneBalance.id > 0)) {
          history.replace(`/admin/customer-zone/balance/form/${response.data.id}`);

          //  Update customer zone state
          setCustomerZoneBalance(response.data);
        }

        //  Unset `Save Process` flag
        setSaveProcess(false);
        state.saveProcess = false;
      });
  }

  // Search customer zone auto based on input
  const customerZoneAutoLoadOptions = async input => {
    input = input ? input : '';

    // Min input length
    if (input && input.length < 2)
      return false;

    const response = (await axios.get(Config.api(`/customer-zone/auto/search?term=${input}`))).data;

    if (!response.success)
      return [];

    const options = response.data.map((item) => ({
      value: item.id,
      label: `#${item.id} ${item.data.model}`
    }));

    // Initial value
    if (customerZoneAuto === undefined && customerZoneBalance.data
      && parseInt(customerZoneBalance.data['customer-zone-auto']) > 0) {
      const valueResponse = (await axios.get(Config.api(
        `/customer-zone/auto/search?id=${customerZoneBalance.data['customer-zone-auto']}`)
      )).data;
      const item = valueResponse.success ? valueResponse.data[0] : null;
      setCustomerZoneAuto(item ? {
        value: item.id,
        label: `#${item.id} ${item.data.model}`
      } : null);
    }

    return options;
  };

  return (
    <>
      {customerZoneBalance ?
        <div
          id="customer-zone-balance-component"
        >
          <Head
            head={t('admin:customer_zone_balance.form.head')}
            subhead={'VIN - Auto'}
            right={<div className="d-flex justify-content-lg-end justify-content-start train-buttons">
              <button
                form="customer-zone-form-component"
                className="btn btn-save"
                disabled={saveProcess}
              >
                {t('buttons.save')}
              </button>
              <button
                type="button"
                className="btn btn-reload"
                onClick={() => reset()}
              >
                {t('buttons.reset')}
              </button>
              <button
                type="button"
                className="btn btn-remove"
              >
                {t('buttons.remove')}
              </button>
            </div>}
            hr={{style: {marginTop: 20}}}
          />

          <form
            ref={_eForm}
            id="customer-zone-form-component"
            onSubmit={onSubmit}
          >
            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelDetails className="pt-0">
                <div className="row d-flex flex-grow-1">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Customer Zone Auto*/}
                    <Select
                      label={t('admin:customer_zone_balance.customer_zone_auto')}
                      async={true}
                      select={{
                        cacheOptions: true,
                        loadOptions: customerZoneAutoLoadOptions,
                        defaultOptions: true,
                        value: customerZoneAuto,
                        components: {
                          DropdownIndicator: DownArrowIndicator,
                        },
                        styles: SelectStylesHideIndicatorSeparator,
                        onChange: option => setCustomerZoneAuto(option)
                      }}
                      required={true}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*City*/}
                    <Input
                      label={t('admin:customer_zone_balance.city')}
                      input={{
                        name: 'city',
                        id: 'city',
                        defaultValue: customerZoneBalance.data ? customerZoneBalance.data['city'] : null,
                        required: true
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Price*/}
                    <Input
                      label={t('admin:customer_zone_balance.price')}
                      input={{
                        type: 'number',
                        name: 'price',
                        id: 'price',
                        defaultValue: customerZoneBalance.data ? customerZoneBalance.data['price'] : null,
                        required: true
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Delivery*/}
                    <Input
                      label={t('admin:customer_zone_balance.delivery')}
                      input={{
                        type: 'number',
                        name: 'delivery',
                        id: 'delivery',
                        defaultValue: customerZoneBalance.data ? customerZoneBalance.data['delivery'] : null,
                        required: true
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Shipping*/}
                    <Input
                      label={t('admin:customer_zone_balance.shipping')}
                      input={{
                        type: 'number',
                        name: 'shipping',
                        id: 'shipping',
                        defaultValue: customerZoneBalance.data ? customerZoneBalance.data['shipping'] : null,
                        required: true
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Dealer fee*/}
                    <Input
                      label={t('admin:customer_zone_balance.dealer_fee')}
                      input={{
                        type: 'number',
                        name: 'dealer-fee',
                        id: 'dealer-fee',
                        defaultValue: customerZoneBalance.data ? customerZoneBalance.data['dealer-fee'] : null,
                        required: true
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Storage Auction fee*/}
                    <Input
                      label={t('admin:customer_zone_balance.storage_auction_fee')}
                      input={{
                        type: 'number',
                        name: 'storage-auction-fee',
                        id: 'storage-auction-fee',
                        defaultValue: customerZoneBalance.data ? customerZoneBalance.data['storage-auction-fee'] : null,
                        required: true
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Storage fee*/}
                    <Input
                      label={t('admin:customer_zone_balance.storage_fee')}
                      input={{
                        type: 'number',
                        name: 'storage-fee',
                        id: 'storage-fee',
                        defaultValue: customerZoneBalance.data ? customerZoneBalance.data['storage-fee'] : null,
                        required: true
                      }}
                    />
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary id="section-password">
                {t('admin:customer_zone_balance.form.calculation')}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className="row d-flex flex-grow-1">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Loading fee*/}
                    <Input
                      label={t('admin:customer_zone_balance.loading_fee')}
                      input={{
                        type: 'number',
                        name: 'loading-fee',
                        id: 'loading-fee',
                        defaultValue: customerZoneBalance.data ? customerZoneBalance.data['loading-fee'] : null,
                        required: true
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Late fee*/}
                    <Input
                      label={t('admin:customer_zone_balance.late_fee')}
                      input={{
                        type: 'number',
                        name: 'late-fee',
                        id: 'late-fee',
                        defaultValue: customerZoneBalance.data ? customerZoneBalance.data['late-fee'] : null,
                        required: true
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Insurance*/}
                    <Input
                      label={t('admin:customer_zone_balance.insurance')}
                      input={{
                        type: 'number',
                        name: 'insurance',
                        id: 'insurance',
                        defaultValue: customerZoneBalance.data ? customerZoneBalance.data['insurance'] : null,
                        required: true
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Current balance*/}
                    <Input
                      label={t('admin:customer_zone_balance.current_balance')}
                      input={{
                        type: 'number',
                        name: 'current-balance',
                        id: 'current-balance',
                        defaultValue: customerZoneBalance.data ? customerZoneBalance.data['current-balance'] : null,
                        required: true
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Payed amount*/}
                    <Input
                      label={t('admin:customer_zone_balance.payed_amount')}
                      input={{
                        type: 'number',
                        name: 'payed-amount',
                        id: 'payed-amount',
                        defaultValue: customerZoneBalance.data ? customerZoneBalance.data['payed-amount'] : null,
                        required: true
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Balance*/}
                    <Input
                      label={t('admin:customer_zone_balance.balance')}
                      input={{
                        type: 'number',
                        name: 'balance',
                        id: 'balance',
                        defaultValue: customerZoneBalance.data ? customerZoneBalance.data['balance'] : null,
                        required: true
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Custom Notes*/}
                    <Textarea
                      label={t('admin:customer_zone_balance.custom_notes')}
                      textarea={{
                        name: 'custom-notes',
                        defaultValue: customerZoneBalance.data ? customerZoneBalance.data['custom-notes'] : null,
                        rowsMin: 3,
                        rowsMax: 3
                      }}
                    />
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </form>

          {/*Share*/}
          {customerZoneBalance && customerZoneBalance.id > 0 ?
            <Share
              className="mb-3"
              share={customerZoneBalance.id}
              module={'customer-zone-balance'}
            />
            :
            ''
          }

          <BottomToolbar
            right={<>
              <div className="d-flex justify-content-lg-end justify-content-start train-buttons">
                <button
                  form="customer-zone-form-component"
                  className="btn btn-save"
                  disabled={saveProcess}
                >
                  {t('buttons.save')}
                </button>
                <button
                  type="button"
                  className="btn btn-reload"
                  onClick={() => reset()}
                >
                  {t('buttons.reset')}
                </button>
                <button
                  type="button"
                  className="btn btn-remove"
                >
                  {t('buttons.remove')}
                </button>
              </div>
              <div className="d-flex justify-content-end">
                <Notify
                  type="save-form"
                  hide={!saveNotify}
                />
              </div>
            </>}
          />
        </div>
        :
        ''
      }
    </>
  );
};

export default CustomerZoneBalanceForm;
