import React, {FC, useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import './CustomerZoneBalance.scss';
import CustomerZoneBalanceProvider from '../../Providers/CustomerZoneBalance';
import AdminContext from '../AdminContext';
import IRole from '../../Interfaces/IRole';
import {UserPermission} from '../../Providers/UserPermission';
import Select, {SelectStylesHideIndicatorSeparator} from '../../Core/Form/Select/Select';
import Input from '../../Core/Form/Input/Input';
import Head from '../Head/Head';
import DownArrowIndicator from '../../Core/Form/Select/Indicators/DownArrowIndicator';
import {RowActionMenuItemEdit, RowActionMenuItemView} from '../../Core/Form/Table/useRowActions';
import Table, {useRowActions} from '../../Core/Form/Table/Table';

const customerZoneBalanceProvider = new CustomerZoneBalanceProvider();

const CustomerZoneBalance: FC = () => {
  const {t} = useTranslation();
  const context = useContext(AdminContext);
  const columns = useMemo(() => [
    {
      Header: t('admin:customer_zone_balance.id'),
      accessor: 'id',
    },
    {
      Header: t('admin:customer_zone_balance.date'),
      accessor: 'customerZoneAuto.data.date'
    },
    {
      Header: t('admin:customer_zone_balance.buyer_number'),
      accessor: 'customerZoneAuto.data.buyerNumber'
    },
    {
      Header: t('admin:customer_zone_balance.year'),
      accessor: 'customerZoneAuto.data.year'
    },
    {
      Header: t('admin:customer_zone_balance.type'),
      accessor: 'customerZoneAuto.data.type'
    },
    {
      Header: t('admin:customer_zone_balance.model'),
      accessor: 'customerZoneAuto.data.model'
    },
    {
      Header: t('admin:customer_zone_balance.auction'),
      accessor: 'customerZoneAuto.data.auction'
    },
    {
      Header: t('admin:customer_zone_balance.city'),
      accessor: 'data.city'
    },
    {
      Header: t('admin:customer_zone_balance.lot'),
      accessor: 'customerZoneAuto.data.lot'
    },
    {
      Header: t('admin:customer_zone_balance.vin'),
      accessor: 'customerZoneAuto.data.vin'
    },
    {
      Header: t('admin:customer_zone_balance.point_of_loading'),
      accessor: 'customerZoneAuto.data.pointOfLoading'
    },

    {
      Header: t('admin:customer_zone_balance.price'),
      accessor: 'data.price'
    },
    {
      Header: t('admin:customer_zone_balance.delivery'),
      accessor: 'data.delivery'
    },
    {
      Header: t('admin:customer_zone_balance.shipping'),
      accessor: 'data.shipping'
    },
    {
      Header: t('admin:customer_zone_balance.dealer_fee'),
      accessor: 'data.dealerFee'
    },
    {
      Header: t('admin:customer_zone_balance.storage_fee'),
      accessor: 'data.storageFee'
    },
    {
      Header: t('admin:customer_zone_balance.loading_fee'),
      accessor: 'data.loadingFee'
    },
    {
      Header: t('admin:customer_zone_balance.late_fee'),
      accessor: 'data.lateFee'
    },
    {
      Header: t('admin:customer_zone_balance.insurance'),
      accessor: 'data.insurance'
    },
    {
      Header: t('admin:customer_zone_balance.current_balance'),
      accessor: 'data.currentBalance'
    },
    {
      Header: t('admin:customer_zone_balance.payed_amount'),
      accessor: 'data.payedAmount'
    },
    {
      Header: t('admin:customer_zone_balance.balance'),
      accessor: 'data.balance'
    },
    {
      Header: t('admin:customer_zone_balance.custom_notes'),
      accessor: 'data.customNotes'
    },
    // eslint-disable-next-line
  ], []);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const fetchIdRef = useRef(0);
  const _tableRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const menuItems = useMemo(() => {
    const items = [];

    // Edit
    if (IRole.checkPermission(context.auth.user.role, [
      UserPermission.CUSTOMER_ZONE_BALANCES_FULL
    ])) {
      items.push(<RowActionMenuItemEdit
        link={rowIndex => {
          const table = _tableRef.current;
          const row = table.page[rowIndex].original;

          return {
            to: `/admin/customer-zone/balance/form/${row.id}`
          };
        }}
      />);
    }

    // View
    if (IRole.checkPermission(context.auth.user.role, [
      UserPermission.CUSTOMER_ZONE_BALANCES_READONLY
    ])) {
      items.push(<RowActionMenuItemView
        link={rowIndex => {
          const table = _tableRef.current;
          const row = table.page[rowIndex].original;

          return {
            to: `/admin/customer-zone/balance/view/${row.id}`
          };
        }}
      />)
    }

    return items;
    // eslint-disable-next-line
  }, []);

  // Get table data
  const fetchData = useCallback(({pageIndex, pageSize}) => {
    const fetchId = ++fetchIdRef.current;
    setLoading(true);

    if (fetchId !== fetchIdRef.current)
      return;

    (async () => {
      const list = (await customerZoneBalanceProvider.list({
        page_index: pageIndex,
        page_size: pageSize
      }));

      if (!list)
        return;

      setData(list.data);
      setCount(list.count);
      setPageCount(Math.ceil(list.count / pageSize));
      setLoading(false);
    })();
  }, []);

  return (
    <div id="customer-zone-balance-component">
      <Head
        head={t('admin:customer_zone_balance.head')}
        hr={{style: {marginTop: 0}}}
      >
        <div className="row table-filter">
          <div className="col-12 col-md-6 col-lg-4 col-xl-4">
            <Select
              hideLabel={true}
              select={{
                isSearchable: false,
                options: [
                  {
                    value: '2019',
                    label: '2019'
                  },
                  {
                    value: '2020',
                    label: '2020'
                  },
                ],
                placeholder: 'Год выпуска авто',
                components: {
                  DropdownIndicator: DownArrowIndicator,
                },
                styles: SelectStylesHideIndicatorSeparator
              }}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-2 col-xl-2">
            <Select
              hideLabel={true}
              select={{
                isSearchable: false,
                options: [
                  {
                    value: '=',
                    label: '='
                  },
                  {
                    value: '>',
                    label: '>'
                  },
                  {
                    value: '<',
                    label: '<'
                  },
                ],
                defaultValue: {
                  value: '=',
                  label: '='
                },
                components: {
                  DropdownIndicator: DownArrowIndicator,
                },
                styles: SelectStylesHideIndicatorSeparator
              }}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-4">
            <Input
              hideLabel={true}
              input={{
                placeholder: 'Значение'
              }}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-2 col-xl-2">
            <Select
              hideLabel={true}
              select={{
                isSearchable: false,
                options: [
                  {
                    value: 'and',
                    label: 'И'
                  },
                  {
                    value: 'or',
                    label: 'ИЛИ'
                  },
                ],
                defaultValue: {
                  value: 'and',
                  label: 'И'
                },
                components: {
                  DropdownIndicator: DownArrowIndicator,
                },
                styles: SelectStylesHideIndicatorSeparator
              }}
            />
          </div>
          <div className="col-6">
            <button className="btn btn-create mb-control">
              ДОБАВИТЬ
            </button>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <button className="btn btn-submit mb-control">
              {t('buttons.search')}
            </button>
          </div>
        </div>
      </Head>

      <Table
        tableRef={_tableRef}
        table={{
          columns,
          data,
          initialState: {pageIndex: 0},
          manualPagination: true,
          pageCount
        }}
        count={count}
        tableHooks={[
          useRowActions({
            menuItems
          }),
        ].reverse()}
        fetchData={fetchData}
        loading={loading}
        toolbar={{
          top: {
            right: <>
              {IRole.checkPermission(context.auth.user.role, [
                UserPermission.CUSTOMER_ZONE_BALANCES_FULL
              ]) && <div className="row">
                <div className="col-12 d-flex justify-content-end train-buttons">
                  <Link
                      to={'/admin/customer-zone/balance/form'}
                      className="btn btn-create"
                  >
                    {t('buttons.create')}
                  </Link>
                </div>
              </div>}
            </>
          }
        }}
      />
    </div>
  );
}

export default CustomerZoneBalance;
