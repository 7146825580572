import React, {FC, useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import './CustomerZoneAuto.scss';
import CustomerZoneAutoProvider from '../../Providers/CustomerZoneAuto';
import Config from '../../Providers/Config';
import AdminContext from '../AdminContext';
import IRole from '../../Interfaces/IRole';
import {UserPermission} from '../../Providers/UserPermission';
import Head from '../Head/Head';
import Table, {useRowActions, useRowSelection} from '../../Core/Form/Table/Table';
import {
  RowActionMenuItemEdit,
  RowActionMenuItemRemove,
  RowActionMenuItemView
} from '../../Core/Form/Table/useRowActions';
import CustomerZoneAutoFilter from './CustomerZoneAutoFilter';

const customerZoneAutoProvider = new CustomerZoneAutoProvider();

const CustomerZoneAuto: FC = () => {
  const {t} = useTranslation();
  const context = useContext(AdminContext);
  const filterRef = useRef({});
  const columns = useMemo(() => [
    {
      Header: t('admin:customer_zone_auto.id'),
      accessor: 'id',
    },
    {
      Header: t('admin:customer_zone_auto.date'),
      accessor: 'data.date'
    },
    {
      Header: t('admin:customer_zone_auto.model'),
      accessor: 'data.model',
    },
    {
      Header: t('admin:customer_zone_auto.lot'),
      accessor: 'data.lot',
    },
    {
      Header: t('admin:customer_zone_auto.vin'),
      accessor: 'data.vin',
    },
    {
      Header: t('admin:customer_zone_auto.point_of_loading'),
      accessor: 'data.pointOfLoading',
    },
    {
      Header: t('admin:customer_zone_auto.auction'),
      accessor: 'data.auction',
    },
    {
      Header: t('admin:customer_zone_auto.destination_port'),
      accessor: 'data.destinationPort',
    },
    {
      Header: t('admin:customer_zone_auto.container_number'),
      accessor: 'data.containerNumber',
    },
    {
      Header: t('admin:customer_zone_auto.delivered_to_warehouse'),
      accessor: 'data.deliveredToWarehouse',
    },
    {
      Header: t('admin:customer_zone_auto.arrival_date'),
      accessor: 'data.arrivalDate',
    },
    {
      Header: t('admin:customer_zone_auto.dock_receipt'),
      //customerZone.data.dockReceipt[0].url
      accessor: 'data.dockReceipt',
      Cell: ({value}) => value?.length > 0 ? <a
          href={value[0].url}
          target={'blank'}
        >
          {t('admin:customer_zone_auto.file')}
        </a>
        :
        ''
    },
    {
      Header: t('admin:customer_zone_auto.title_status'),
      accessor: 'data.titleStatus',
    },
    {
      Header: t('admin:customer_zone_auto.title_received'),
      accessor: 'data.titleReceived',
    },
    {
      Header: t('admin:customer_zone_auto.keys'),
      accessor: 'data.keys',
    },
    {
      Header: t('admin:customer_zone_auto.notes'),
      accessor: 'data.notes',
    },
    {
      Header: t('admin:customer_zone_auto.invoice'),
      accessor: 'data.invoice',
      Cell: ({value}) => value ?
        <a
          href={Config.api(`/invoices/get-pdf/${value}?api_token=${context.auth.user.token}`)}
          target={'blank'}
          className="btn th-action th-action-open"
        >
          {t('admin:customer_zone_auto.open')}
        </a>
        :
        ''

    },
    {
      Header: t('admin:customer_zone_auto.auto_price'),
      accessor: 'data.autoPrice',
    },
    {
      Header: t('admin:customer_zone_auto.paid'),
      accessor: 'data.paid',
    },
    {
      Header: t('admin:customer_zone_auto.bill_of_lading'),
      accessor: 'data.billOfLading',
      Cell: ({value}) => value?.length > 0 ? <a
          href={value[0].url}
          target={'blank'}
        >
          {t('admin:customer_zone_auto.file')}
        </a>
        :
        ''
    },
    {
      Header: t('admin:customer_zone_auto.waggon_number'),
      accessor: 'data.waggonLoadingPlace',
    },
    {
      Header: t('admin:customer_zone_auto.destination'),
      accessor: 'data.destination',
    },
    {
      Header: t('admin:customer_zone_auto.planned_wagon_lading'),
      accessor: 'data.plannedWagonLading',
    },
    {
      Header: t('admin:customer_zone_auto.waggon_loading_date'),
      accessor: 'data.waggonLoadingDate',
    },
    {
      Header: t('admin:customer_zone_auto.waggon_estimate_arrival_date'),
      accessor: 'data.waggonEstimateArrivalDate',
    },
    {
      Header: t('admin:customer_zone_auto.waggon_arrival_date'),
      accessor: 'data.waggonArrivalDate',
    },
    {
      Header: t('admin:customer_zone_auto.waggon_notes'),
      accessor: 'data.waggonNotes',
    },
    {
      Header: t('admin:customer_zone_auto.notes_for_yourself'),
      accessor: 'data.notesForYourself',
    },
    // eslint-disable-next-line
  ], []);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const fetchIdRef = useRef(0);
  const _tableRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const menuItems = useMemo(() => {
    const items = [];

    // Edit
    if (IRole.checkPermission(context.auth.user.role, [
      UserPermission.CUSTOMER_ZONE_AUTOS_FULL
    ])) {
      items.push(<RowActionMenuItemEdit
        link={rowIndex => {
          const table = _tableRef.current;
          const row = table.page[rowIndex].original;

          return {
            to: `/admin/customer-zone/auto/form/${row.id}`
          };
        }}
      />);
    }

    // View
    if (IRole.checkPermission(context.auth.user.role, [
      UserPermission.CUSTOMER_ZONE_AUTOS_READONLY
    ])) {
      items.push(<RowActionMenuItemView
        link={rowIndex => {
          const table = _tableRef.current;
          const row = table.page[rowIndex].original;

          return {
            to: `/admin/customer-zone/auto/view/${row.id}`
          };
        }}
      />)
    }

    // Remove
    if (IRole.checkPermission(context.auth.user.role, [
      UserPermission.CUSTOMER_ZONE_AUTOS_FULL
    ])) {
      items.push(<RowActionMenuItemRemove
        menuItem={{
          onClick: (e, rowIndex) => {
            const table = _tableRef.current;
            let pageIndex = table.state.pageIndex;
            const row = table.page[rowIndex].original;

            if (table.page.length === 1)
              pageIndex = --pageIndex < 0 ? 0 : pageIndex;

            customerZoneAutoProvider.delete(row.id).then(() => {
              fetchData({pageIndex, pageSize: table.state.pageSize});
            });
          }
        }}
      />);
    }

    return items;
    // eslint-disable-next-line
  }, []);

  // Get table data
  const fetchData = useCallback(({pageIndex, pageSize}) => {
    const fetchId = ++fetchIdRef.current;
    setLoading(true);

    if (fetchId !== fetchIdRef.current)
      return;

    (async () => {
      const list = (await customerZoneAutoProvider.list({
        page_index: pageIndex,
        page_size: pageSize,
        ...filterRef.current,
      }));

      if (!list)
        return;

      setData(list.data);
      setCount(list.count);
      setPageCount(Math.ceil(list.count / pageSize));
      setLoading(false);
    })();
  }, []);

  return (
    <div id="customer-zone-auto-component">
      <Head
        head={t('admin:customer_zone_auto.head')}
        hr={{style: {marginTop: 0}}}
      >
        <CustomerZoneAutoFilter
          onFilter={filter => {
            filterRef.current = filter;
            const table = _tableRef.current;
            fetchData({pageIndex: table.state.pageIndex, pageSize: table.state.pageSize});
          }}
        />
      </Head>

      <Table
        tableRef={_tableRef}
        table={{
          columns,
          data,
          initialState: {pageIndex: 0},
          manualPagination: true,
          pageCount
        }}
        count={count}
        tableHooks={[
          useRowSelection(),
          useRowActions({
            menuItems
          }),
        ].reverse()}
        fetchData={fetchData}
        loading={loading}
        toolbar={{
          top: {
            right: <>
              {IRole.checkPermission(context.auth.user.role, [
                UserPermission.CUSTOMER_ZONE_AUTOS_FULL
              ]) && <div className="row">
                <div className="col-12 d-flex justify-content-end train-buttons">
                  <Link
                      to={'/admin/customer-zone/auto/form'}
                      className="btn btn-create"
                  >
                    {t('buttons.create')}
                  </Link>
                  <button
                      className="btn btn-remove"
                      onClick={() => {
                        const table = _tableRef.current;
                        const {page, pageCount, gotoPage, state: {selectedRowIds}} = table;
                        const ids = Object.keys(selectedRowIds).map(key => table.rowsById[key].original.id);
                        let pageIndex = table.state.pageIndex;

                        if (ids.length === 0) {
                          table.page.forEach(row => row.toggleRowSelected(false));

                          return;
                        }

                        if (pageIndex === pageCount - 1 && page.length === Object.keys(selectedRowIds).length)
                          pageIndex = --pageIndex < 0 ? 0 : pageIndex;

                        customerZoneAutoProvider.delete(ids).then(() => {
                          if (pageIndex !== table.state.pageIndex)
                            gotoPage(pageIndex);
                          else
                            fetchData({pageIndex, pageSize: table.state.pageSize});
                        });
                      }}
                  >
                    {t('buttons.remove')}
                  </button>
                </div>
              </div>}
            </>
          }
        }}
      />
    </div>
  );
}

export default CustomerZoneAuto;
