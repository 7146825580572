import React, {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import './CustomerZoneAutoForm.scss';
import Input from '../../Core/Form/Input/Input';
import axios from 'axios';
import Config from '../../Providers/Config';
import $ from 'jquery';
import {IHttpResponse} from '../../Interfaces/IHttpResponse';
import * as EgoUtil from '@egofoxlab/util';
import Select, {SelectStylesHideIndicatorSeparator} from '../../Core/Form/Select/Select';
import Textarea from '../../Core/Form/Textarea/Textarea';
import Upload, {IUploadedFile} from '../../Core/Form/Upload/Upload';
import Notify from '../../Core/Ui/Notify/Notify';
import Share from '../Share/Share';
import Head from '../Head/Head';
import BottomToolbar from '../BottomToolbar/BottomToolbar';
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary} from '@material-ui/core';
import DownArrowIndicator from '../../Core/Form/Select/Indicators/DownArrowIndicator';
import qs from 'qs';
import KeyboardDatePicker from '../../Core/Form/Pickers/KeyboardDatePicker/KeyboardDatePicker';
import moment, {Moment} from 'moment';
import KeyboardDateTimePicker from '../../Core/Form/Pickers/KeyboardDateTimePicker/KeyboardDateTimePicker';

// Load form by ID
async function load(id) {
  const response = (await (axios.get(Config.api(`/customer-zone/auto/get/${id}`)))).data;

  if (!response.success) {
    console.error(response.message);

    return false;
  }

  // Skip empty
  if (!response.data)
    return false;

  return response.data;
}

const CustomerZoneAutoForm: FC = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const id = parseInt(useParams().id) || null;
  const [buyerId, setBuyerId] = useState<any>();
  const [customerZone, setCustomerZone] = useState(null);
  const [date, setDate] = useState<Moment>(null);
  const [dateOfLoading, setDateOfLoading] = useState<Moment>(null);
  const [deliveredToWarehouse, setDeliveredToWarehouse] = useState<Moment>(null);
  const [titleReceived, setTitleReceived] = useState<Moment>(null);
  const [fencePoint, setFencePoint] = useState<any>();
  const [plannedWagonLading, setPlannedWagonLading] = useState<Moment>(null);
  const [waggonLoadingDate, setWaggonLoadingDate] = useState<Moment>(null);
  const [waggonEstimateArrivalDate, setWaggonEstimateArrivalDate] = useState<Moment>(null);
  const [waggonArrivalDate, setWaggonArrivalDate] = useState<Moment>(null);
  const [invoice, setInvoice] = useState<any>();
  const [saveProcess, setSaveProcess] = useState(false);
  const [saveNotify, setSaveNotify] = useState(false);
  const _eForm = useRef<HTMLFormElement>(null);
  const [photos, setPhotos] = useState<IUploadedFile[]>([]);
  const [dockReceipt, setDockReceipt] = useState<IUploadedFile[]>([]);
  const [billOfLading, setBillOfLading] = useState<IUploadedFile[]>([]);

  useEffect(() => {
    (async () => {
      const customerZone = id > 0 ? await load(id) : {};

      if (!customerZone) {
        history.push('/admin/customer-zone/auto');

        return;
      }

      // Dates
      const date = customerZone.data?.date;
      const dateOfLoading = customerZone.data?.['date-of-loading'];
      const deliveredToWarehouse = customerZone.data?.['delivered-to-warehouse'];
      const titleReceived = customerZone.data?.['title-received'];
      const plannedWagonLading = customerZone.data?.['planned-wagon-lading'];
      const waggonLoadingDate = customerZone.data?.['waggon-loading-date'];
      const waggonEstimateArrivalDate = customerZone.data?.['waggon-estimate-arrival-date'];
      const waggonArrivalDate = customerZone.data?.['waggon-arrival-date'];

      setDate(date ? moment(date, Config.DATE_FORMAT) : null);
      setDateOfLoading(dateOfLoading ? moment(dateOfLoading, Config.DATE_TIME_FORMAT) : null);
      setDeliveredToWarehouse(deliveredToWarehouse ? moment(deliveredToWarehouse, Config.DATE_FORMAT) : null);
      setTitleReceived(titleReceived ? moment(titleReceived, Config.DATE_FORMAT) : null);
      setPlannedWagonLading(plannedWagonLading ? moment(plannedWagonLading, Config.DATE_FORMAT) : null);
      setWaggonLoadingDate(waggonLoadingDate ? moment(waggonLoadingDate, Config.DATE_FORMAT) : null);
      setWaggonEstimateArrivalDate(
        waggonEstimateArrivalDate ? moment(waggonEstimateArrivalDate, Config.DATE_FORMAT) : null);
      setWaggonArrivalDate(waggonArrivalDate ? moment(waggonArrivalDate, Config.DATE_FORMAT) : null);

      // Files
      setPhotos((customerZone?.data?.['photos'] ?? []).map(item => ({
        url: item.url,
        originalName: item['original-name']
      })));
      setDockReceipt((customerZone?.data?.['dock-receipt'] ?? []).map(item => ({
        url: item.url,
        originalName: item['original-name']
      })));
      setBillOfLading((customerZone.data?.['bill-of-lading'] ?? []).map(item => ({
        url: item.url,
        originalName: item['original-name']
      })));

      setCustomerZone(customerZone);
    })();
    // eslint-disable-next-line
  }, []);

  function reset(): void {
    // Clear input field
    EgoUtil.clearFields(_eForm.current);
    // Clear uploads
    setPhotos([]);
    setDockReceipt([]);
    setBillOfLading([]);

    $(_eForm.current).find('[name]').trigger('change');

    setDate(null);
    setDateOfLoading(null);
    setDeliveredToWarehouse(null);
    setTitleReceived(null);
    setFencePoint(null);
    setPlannedWagonLading(null);
    setWaggonLoadingDate(null);
    setWaggonEstimateArrivalDate(null);
    setWaggonArrivalDate(null);
    setInvoice(null);
  }

  // Search Buyer ID based on input
  const buyerIdLoadOptions = async input => {
    input = input ? input : '';

    // Min input length
    if (input && input.length < 2)
      return false;

    const response = (await axios.get(Config.api(`/buyer-id/search?term=${input}`))).data;

    if (!response.success)
      return [];

    const options = response.data.map((item) => ({
      value: item.id,
      label: `${item.login} - ${t(`admin:auction.${item.auction}`)}`
    }));

    // Initial value
    if (buyerId === undefined && customerZone?.buyer_id > 0) {
      const valueResponse = (await axios.get(Config.api(`/buyer-id/search?id=${customerZone.buyer_id}`))).data;
      const item = valueResponse.success ? valueResponse.data[0] : null;
      setBuyerId(item ? {
        value: item.id,
        label: `${item.login} - ${t(`admin:auction.${item.auction}`)}`
      } : null);
    }

    return options;
  };

  // Search auction cities based on input
  const fencePointLoadOptions = async input => {
    input = input ? input : '';

    // Min input length
    if (input && input.length < 2)
      return false;

    const response = (await axios.get(Config.api(`/auction/city/search?term=${input}`))).data;

    if (!response.success)
      return [];

    const options = response.data.map((item) => ({
      value: item.id,
      label: `${item.city} – ${item.port}`
    }));

    // Initial value
    if (fencePoint === undefined && customerZone.data
      && parseInt(customerZone.data['fence-point']) > 0) {
      const valueResponse = (await axios.get(Config.api(
        `/auction/city/search?id=${customerZone.data['fence-point']}`)
      )).data;
      const item = valueResponse.success ? valueResponse.data[0] : null;
      setFencePoint(item ? {
        value: item.id,
        label: `${item.city} – ${item.port}`
      } : null);
    }

    return options;
  };

  // Search invoices based on input
  const invoiceLoadOptions = async input => {
    input = input ? input : '';

    // Min input length
    if (input && input.length < 2)
      return false;

    const q = qs.stringify({
      invoice_number: input,
    });
    const response = (await axios.get(Config.api(`/invoices/search?${q}`))).data;

    if (!response.success)
      return [];

    const options = response.data.map((item) => ({
      value: item.id,
      label: item.data['invoice-number']
    }));

    // Initial value
    if (invoice === undefined && customerZone.data
      && parseInt(customerZone.data.invoice) > 0) {
      const valueResponse = (await axios.get(Config.api(
        `/invoices/search?id=${customerZone.data.invoice}`)
      )).data;
      const item = valueResponse.success ? valueResponse.data[0] : null;
      setInvoice(item ? {
        value: item.id,
        label: item.data['invoice-number']
      } : null);
    }

    return options;
  };

  function onSubmit(e): void {
    if (!_eForm.current.checkValidity()) {
      return;
    }

    e.preventDefault();

    // Set `Save Process` flag
    setSaveProcess(true);

    const eForm = $(_eForm.current);
    const formData = new FormData();
    // Buyer ID
    formData.append('buyer_id', buyerId?.value ?? '');
    // Date
    formData.append('date', date.isValid() ? date.format(Config.DATE_FORMAT) : null);
    // Buyer Number
    formData.append('buyer-number', eForm.find('[name="buyer-number"]').val());
    // Year
    formData.append('year', eForm.find('[name="year"]').val());
    // Type
    formData.append('type', eForm.find('[name="type"]').val());
    // Model
    formData.append('model', eForm.find('[name="model"]').val());
    // Lot
    formData.append('lot', eForm.find('[name="lot"]').val());
    // Vin
    formData.append('vin', eForm.find('[name="vin"]').val());
    // Point of loading
    formData.append('point-of-loading', eForm.find('[name="point-of-loading"]').val());
    // Date Of Loading
    formData.append('date-of-loading', dateOfLoading?.isValid()
      ? dateOfLoading.format(Config.DATE_TIME_FORMAT) : null);
    // Auction
    formData.append('auction', eForm.find('[name="auction"]').val());
    // Destination port
    formData.append('destination-port', eForm.find('[name="destination-port"]').val());
    // Container number
    formData.append('container-number', eForm.find('[name="container-number"]').val());
    // Delivered to warehouse
    formData.append('delivered-to-warehouse', deliveredToWarehouse?.isValid()
      ? deliveredToWarehouse.format(Config.DATE_FORMAT) : null);
    // Arrival Date
    formData.append('arrival-date', eForm.find('[name="arrival-date"]').val());
    // Dock receipt
    dockReceipt.forEach((file, i) => {
      // File
      if (file.format === 'file') {
        formData.append(`files-dock-receipt[${i}][file]`, file.file);
      }
      // URL
      else {
        formData.append(`dock-receipt[${i}][url]`, (new URL(file.url)).pathname);
        formData.append(`dock-receipt[${i}][original-name]`, file.originalName);
      }
    });
    // Title status
    formData.append('title-status', eForm.find('[name="title-status"]').val());
    // Title received
    formData.append('title-received', titleReceived?.isValid()
      ? titleReceived.format(Config.DATE_FORMAT) : null);
    // Fence Point
    formData.append('fence-point', fencePoint ? fencePoint.value : '');
    // Keys
    formData.append('keys', eForm.find('[name="keys"]').val());
    // Notes
    formData.append('notes', eForm.find('[name="notes"]').val());
    // Invoice
    formData.append('invoice', invoice ? invoice.value : '');
    // Auto price
    formData.append('auto-price', eForm.find('[name="auto-price"]').val());
    // Paid
    formData.append('paid', eForm.find('[name="paid"]').val());
    // Waggon number
    formData.append('waggon-number', eForm.find('[name="waggon-number"]').val());
    // Bill of loading
    billOfLading.forEach((file, i) => {
      // File
      if (file.format === 'file') {
        formData.append(`files-bill-of-lading[${i}][file]`, file.file);
      }
      // URL
      else {
        formData.append(`bill-of-lading[${i}][url]`, (new URL(file.url)).pathname);
        formData.append(`bill-of-lading[${i}][original-name]`, file.originalName);
      }
    });
    // Waggon loading place
    formData.append('waggon-loading-place', eForm.find('[name="waggon-loading-place"]').val());
    // Destination
    formData.append('destination', eForm.find('[name="destination"]').val());
    // Planned wagon lading
    formData.append('planned-wagon-lading', plannedWagonLading?.isValid()
      ? plannedWagonLading.format(Config.DATE_FORMAT) : null);
    // Waggon loading date
    formData.append('waggon-loading-date', waggonLoadingDate?.isValid()
      ? waggonLoadingDate.format(Config.DATE_FORMAT) : null);
    // Waggon estimate arrival date
    formData.append('waggon-estimate-arrival-date', waggonEstimateArrivalDate?.isValid()
      ? waggonEstimateArrivalDate.format(Config.DATE_FORMAT) : null);
    // Waggon arrival date
    formData.append('waggon-arrival-date', waggonArrivalDate?.isValid()
      ? waggonArrivalDate.format(Config.DATE_FORMAT) : null);
    // Waggon notes
    formData.append('waggon-notes', eForm.find('[name="waggon-notes"]').val());
    // Waggon notes
    formData.append('notes-for-yourself', eForm.find('[name="notes-for-yourself"]').val());
    // Photos
    photos.forEach((file, i) => {
      if (file.format === 'file') {
        formData.append(`files-photos[${i}][file]`, file.file);
      } else {
        formData.append(`photos[${i}][url]`, (new URL(file.url)).pathname);
        formData.append(`photos[${i}][original-name]`, file.originalName);
      }
    });

    // Save form
    let url;

    if (customerZone.id > 0) {
      url = Config.api(`/customer-zone/auto/update/${customerZone.id}`);
    } else {
      url = Config.api('/customer-zone/auto/create');
    }

    axios.post(url, formData, {
      headers: {'Content-Type': 'multipart/form-data'}
    })
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);
          // Unset `Save Process` flag
          setSaveProcess(false);

          return;
        }

        const updatedCustomerZone = response.data;

        // Mark as saved
        setSaveNotify(true);

        // Add customer zone to state after saving
        if (!(customerZone.id > 0)) {
          history.replace(`/admin/customer-zone/auto/form/${response.data.id}`);

          // Update customer zone
          setCustomerZone(updatedCustomerZone);
        }

        // Unset `Save Process` flag
        setSaveProcess(false);
        // Set files
        setPhotos((updatedCustomerZone?.data?.['photos'] ?? []).map(item => ({
          url: item.url,
          originalName: item['original-name']
        })));
        setDockReceipt((updatedCustomerZone?.data?.['dock-receipt'] ?? []).map(item => ({
          url: item.url,
          originalName: item['original-name']
        })));
        setBillOfLading((updatedCustomerZone?.data?.['bill-of-lading'] ?? []).map(item => ({
          url: item.url,
          originalName: item['original-name']
        })));
      });
  }

  return (
    <>
      {customerZone && <div id="customer-zone-auto-component">
        <Head
            head={t('admin:customer_zone_auto.form.head')}
            subhead={t('admin:customer_zone_auto.form.subhead')}
            right={<div className="d-flex justify-content-start justify-content-lg-end train-buttons">
              <button
                form="customer-zone-form-component"
                className="btn btn-save"
                disabled={saveProcess}
              >
                {t('buttons.save')}
              </button>
              <button
                type="button"
                className="btn btn-reload"
                onClick={() => reset()}
              >
                {t('buttons.reset')}
              </button>
              <button
                type="button"
                className="btn btn-remove"
              >
                {t('buttons.remove')}
              </button>
            </div>}
            hr={{style: {marginTop: 20}}}
        />

        <form
            ref={_eForm}
            id="customer-zone-form-component"
            onSubmit={onSubmit}
        >
          <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelDetails className="pt-0">
              <div className="row d-flex flex-grow-1">
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Buyer ID*/}
                  <Select
                      label={t('admin:customer_zone_auto.buyer_id')}
                      async={true}
                      select={{
                        cacheOptions: true,
                        loadOptions: buyerIdLoadOptions,
                        defaultOptions: true,
                        value: buyerId,
                        components: {
                          DropdownIndicator: DownArrowIndicator,
                        },
                        styles: SelectStylesHideIndicatorSeparator,
                        onChange: option => setBuyerId(option)
                      }}
                      required={true}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Date*/}
                  <KeyboardDatePicker
                      label={t('admin:customer_zone_auto.date')}
                      KeyboardDatePicker={{
                        value: date,
                        onChange: date => setDate(date)
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Buyer number*/}
                  <Input
                      label={t('admin:customer_zone_auto.buyer_number')}
                      input={{
                        name: 'buyer-number',
                        id: 'buyer-number',
                        defaultValue: customerZone.data ? customerZone.data['buyer-number'] : null,
                        required: true
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Year*/}
                  <Input
                      label={t('admin:customer_zone_auto.year')}
                      input={{
                        type: 'number',
                        name: 'year',
                        id: 'year',
                        defaultValue: customerZone.data ? customerZone.data['year'] : null,
                        required: true
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Type*/}
                  <Input
                      label={t('admin:customer_zone_auto.type')}
                      input={{
                        name: 'type',
                        id: 'type',
                        defaultValue: customerZone.data ? customerZone.data['type'] : null,
                        required: true
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Model*/}
                  <Input
                      label={t('admin:customer_zone_auto.model')}
                      input={{
                        name: 'model',
                        id: 'model',
                        defaultValue: customerZone.data ? customerZone.data['model'] : null,
                        required: true
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Auction*/}
                  <Input
                      label={t('admin:customer_zone_auto.auction')}
                      input={{
                        name: 'auction',
                        id: 'auction',
                        defaultValue: customerZone.data ? customerZone.data['auction'] : null
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Lot*/}
                  <Input
                      label={t('admin:customer_zone_auto.lot')}
                      input={{
                        name: 'lot',
                        id: 'lot',
                        defaultValue: customerZone.data ? customerZone.data['lot'] : null
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Vin*/}
                  <Input
                      label={t('admin:customer_zone_auto.vin')}
                      input={{
                        name: 'vin',
                        id: 'vin',
                        defaultValue: customerZone.data ? customerZone.data['vin'] : null,
                        required: true,
                        inputProps: {
                          minLength: 17,
                          maxLength: 17,
                          pattern: '^[a-zA-Z0-9]+$',
                        }
                      }}
                  />
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary id="section-password">
              {t('admin:customer_zone_auto.form.info_about_vehicle')}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className="row d-flex flex-grow-1">
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Point of loading*/}
                  <Input
                      label={t('admin:customer_zone_auto.point_of_loading')}
                      input={{
                        name: 'point-of-loading',
                        id: 'point-of-loading',
                        defaultValue: customerZone.data ? customerZone.data['point-of-loading'] : null
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Date Of Loading*/}
                  <KeyboardDateTimePicker
                      label={t('admin:customer_zone_auto.date_of_loading')}
                      KeyboardDateTimePicker={{
                        value: dateOfLoading,
                        onChange: date => setDateOfLoading(date)
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Destination port*/}
                  <Input
                      label={t('admin:customer_zone_auto.destination_port')}
                      input={{
                        name: 'destination-port',
                        id: 'destination-port',
                        defaultValue: customerZone.data ? customerZone.data['destination-port'] : null
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Container number*/}
                  <Input
                      label={t('admin:customer_zone_auto.container_number')}
                      input={{
                        name: 'container-number',
                        id: 'container-number',
                        defaultValue: customerZone.data ? customerZone.data['container-number'] : null
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Delivered to warehouse*/}
                  <KeyboardDatePicker
                      label={t('admin:customer_zone_auto.delivered_to_warehouse')}
                      KeyboardDatePicker={{
                        value: deliveredToWarehouse,
                        onChange: date => setDeliveredToWarehouse(date)
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Dock receipt*/}
                  <Upload
                      name="dock-receipt"
                      id="dock-receipt"
                      label={t('admin:customer_zone_auto.dock_receipt')}
                      placeholder={t('admin:customer_zone_auto.dock_receipt_placeholder')}
                      accept={['application/pdf']}
                      defaultFiles={dockReceipt}
                      maxFiles={1}
                      onLoad={(files) => setDockReceipt(files)}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Title status*/}
                  <Input
                      label={t('admin:customer_zone_auto.title_status')}
                      input={{
                        name: 'title-status',
                        id: 'title-status',
                        defaultValue: customerZone.data ? customerZone.data['title-status'] : null
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Title received*/}
                  <KeyboardDatePicker
                      label={t('admin:customer_zone_auto.title_received')}
                      KeyboardDatePicker={{
                        value: titleReceived,
                        onChange: date => setTitleReceived(date)
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Fence Point*/}
                  <Select
                      label={t('admin:customer_zone_auto.fence_point')}
                      async={true}
                      select={{
                        cacheOptions: true,
                        loadOptions: fencePointLoadOptions,
                        defaultOptions: true,
                        value: fencePoint,
                        components: {
                          DropdownIndicator: DownArrowIndicator,
                        },
                        styles: SelectStylesHideIndicatorSeparator,
                        onChange: option => setFencePoint(option)
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Keys*/}
                  <Input
                      label={t('admin:customer_zone_auto.keys')}
                      input={{
                        name: 'keys',
                        id: 'keys',
                        defaultValue: customerZone.data ? customerZone.data['keys'] : null
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Notes*/}
                  <Textarea
                      label={t('admin:customer_zone_auto.notes')}
                      textarea={{
                        name: 'notes',
                        defaultValue: customerZone.data ? customerZone.data['notes'] : null,
                        rowsMin: 3,
                        rowsMax: 3
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Invoice*/}
                  <Select
                      label={t('admin:customer_zone_auto.invoice')}
                      async={true}
                      select={{
                        cacheOptions: true,
                        loadOptions: invoiceLoadOptions,
                        defaultOptions: true,
                        value: invoice,
                        components: {
                          DropdownIndicator: DownArrowIndicator,
                        },
                        styles: SelectStylesHideIndicatorSeparator,
                        onChange: option => setInvoice(option)
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Auto price*/}
                  <Input
                      label={t('admin:customer_zone_auto.auto_price')}
                      input={{
                        type: 'number',
                        name: 'auto-price',
                        id: 'auto-price',
                        defaultValue: customerZone.data ? customerZone.data['auto-price'] : null
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Paid*/}
                  <Input
                      label={t('admin:customer_zone_auto.paid')}
                      input={{
                        type: 'number',
                        name: 'paid',
                        id: 'paid',
                        defaultValue: customerZone.data ? customerZone.data['paid'] : null
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Waggon number*/}
                  <Input
                      label={t('admin:customer_zone_auto.waggon_number')}
                      input={{
                        name: 'waggon-number',
                        id: 'waggon-number',
                        defaultValue: customerZone.data ? customerZone.data['waggon-number'] : null
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Bill of lading*/}
                  <Upload
                      name="bill-of-lading"
                      id="bill-of-lading"
                      label={t('admin:customer_zone_auto.bill_of_lading')}
                      files={billOfLading}
                      placeholder={t('admin:customer_zone_auto.bill_of_lading_placeholder')}
                      accept={['application/pdf']}
                      maxFiles={1}
                      onLoad={(files) => setBillOfLading(files)}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Waggon loading place*/}
                  <Input
                      label={t('admin:customer_zone_auto.waggon_loading_place')}
                      input={{
                        name: 'waggon-loading-place',
                        id: 'waggon-loading-place',
                        defaultValue: customerZone.data ? customerZone.data['waggon-loading-place'] : null
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Destination*/}
                  <Input
                      label={t('admin:customer_zone_auto.destination')}
                      input={{
                        name: 'destination',
                        id: 'destination',
                        defaultValue: customerZone.data ? customerZone.data['destination'] : null
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Planned wagon lading*/}
                  <KeyboardDatePicker
                      label={t('admin:customer_zone_auto.planned_wagon_lading')}
                      KeyboardDatePicker={{
                        value: plannedWagonLading,
                        onChange: date => setPlannedWagonLading(date)
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Waggon loading date*/}
                  <KeyboardDatePicker
                      label={t('admin:customer_zone_auto.waggon_loading_date')}
                      KeyboardDatePicker={{
                        value: waggonLoadingDate,
                        onChange: date => setWaggonLoadingDate(date)
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Waggon estimate arrival date*/}
                  <KeyboardDatePicker
                      label={t('admin:customer_zone_auto.waggon_estimate_arrival_date')}
                      KeyboardDatePicker={{
                        value: waggonEstimateArrivalDate,
                        onChange: date => setWaggonEstimateArrivalDate(date)
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Waggon arrival date*/}
                  <KeyboardDatePicker
                      label={t('admin:customer_zone_auto.waggon_arrival_date')}
                      KeyboardDatePicker={{
                        value: waggonArrivalDate,
                        onChange: date => setWaggonArrivalDate(date)
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Waggon notes*/}
                  <Textarea
                      label={t('admin:customer_zone_auto.waggon_notes')}
                      textarea={{
                        name: 'waggon-notes',
                        defaultValue: customerZone.data ? customerZone.data['waggon-notes'] : null,
                        rowsMin: 3,
                        rowsMax: 3
                      }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  {/*Waggon notes*/}
                  <Textarea
                      label={t('admin:customer_zone_auto.notes_for_yourself')}
                      textarea={{
                        name: 'notes-for-yourself',
                        defaultValue: customerZone.data ? customerZone.data['notes-for-yourself'] : null,
                        rowsMin: 3,
                        rowsMax: 3
                      }}
                  />
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <div className="row">
            <div className="col-12 col-md-12">
              {/*Photos*/}
              <Upload
                  name="photos"
                  id="photos"
                  label={t('admin:customer_zone_auto.photos')}
                  files={photos}
                  placeholder={t('admin:customer_zone_auto.photos_placeholder')}
                  accept={['image/png', 'image/jpeg']}
                  maxFiles={-1}
                  multiple={true}
                  onLoad={(files) => setPhotos(files)}
              />
            </div>
          </div>
        </form>

        {/*Share*/}
        {customerZone && customerZone.id > 0 ?
          <Share
            className="mb-3"
            share={customerZone.id}
            module={'customer-zone-auto'}
          />
          :
          ''
        }

        <BottomToolbar
            right={<>
              <div className="d-flex justify-content-lg-end justify-content-start train-buttons">
                <button
                  form="customer-zone-form-component"
                  className="btn btn-save"
                  disabled={saveProcess}
                >
                  {t('buttons.save')}
                </button>
                <button
                  type="button"
                  className="btn btn-reload"
                  onClick={() => reset()}
                >
                  {t('buttons.reset')}
                </button>
                <button
                  type="button"
                  className="btn btn-remove"
                >
                  {t('buttons.remove')}
                </button>
              </div>
              <div className="d-flex justify-content-end">
                <Notify
                  type="save-form"
                  hide={!saveNotify}
                />
              </div>
            </>}
        />
      </div>}
    </>
  );
};

export default CustomerZoneAutoForm;
