import React, {FC, useEffect, useState} from 'react';
import './style.scss';
import {default as Dropzone} from 'react-dropzone';

interface IFile {
  format: 'file' | 'url';
  file?: File;
  url?: string;
  type: string;
  originalName: string;
  shortName: string;
}

export interface IUploadedFile {
  format: 'file' | 'url';
  file?: File,
  url?: string,
  originalName: string
}

/**
 * Return file type
 *
 * @param file
 * @returns {string}
 */
function type(file): string {
  // Detect type
  if (file instanceof File) {
    if (file.type.match('image.*')) {
      return 'image';
    }
  } else if (typeof file === 'string') {
    const fileExt = ext(file);

    if ([
      'jpeg',
      'jpg',
      'png',
      'gif',
      'webp'
    ].indexOf(fileExt) !== -1) {
      return 'image';
    }
  }

  return null;
}

/**
 * Return file extension
 *
 * @param filename
 * @returns {string}
 */
function ext(filename): string {
  if (!filename || typeof filename !== 'string') {
    return null;
  }

  let ext: any = filename.split(/#|\?/)[0].split('/').splice(-1, 1)[0].split('.');

  if (ext.length < 2) {
    return null;
  }

  ext = ext.pop().trim();
  ext = ext ? ext : null;

  return ext;
}

/**
 * Return file base name
 *
 * @param filename
 * @returns {string}
 */
function basename(filename): string {
  if (!filename || typeof filename !== 'string') {
    return null;
  }

  return filename.split(/#|\?/)[0].split('/').splice(-1, 1)[0].split('.')[0];
}

/**
 * Return short basename
 *
 * @param filename
 * @returns {string}
 */
function shortBasename(filename): string {
  let fileBasename = basename(filename);

  if (!fileBasename || typeof fileBasename !== 'string') {
    return null;
  }

  fileBasename = fileBasename.length > 35 ? fileBasename.substr(0, 34) + '...' : fileBasename;

  return fileBasename;
}

const Upload: FC<{
  type?: string;
  id?: string;
  className?: string;
  name?: string;
  label?: string;
  defaultFiles?: any[];
  files?: any[];
  placeholder?: string;
  style?: any;
  required?: boolean;
  uploadStyle?: any;
  maxFiles?: number;
  accept?: string[];
  multiple?: boolean;
  hideLabel?: boolean;
  validate?: boolean;
  valid?: boolean;
  invalidMessage?: string;
  withErrors?: boolean;
  onLoad?: (files: IUploadedFile[]) => void
}> = (props) => {
  const [filesList, setFilesList] = useState<IFile[]>([]);

  useEffect(() => {
    setFiles(props.defaultFiles);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFiles(props.files);
    // eslint-disable-next-line
  }, [props.files]);

  function setFiles(files: any[], clear?: boolean): void {
    if (!Array.isArray(files)) {
      return;
    }

    let _files = [];
    files.forEach((file) => {
      const fileObj: IFile = {
        format: file.file instanceof File ? 'file' : 'url',
        type: null,
        originalName: file.originalName,
        shortName: `${shortBasename(file.originalName)}.${ext(file.originalName)}`
      };

      // File
      if (file.file instanceof File) {
        const blob = new Blob([file.file]);
        fileObj.type = type(file.file);
        fileObj.file = file.file;
        fileObj.url = URL.createObjectURL(blob).toString();
      }
      // URL
      else {
        fileObj.type = type(file.url);
        fileObj.url = file.url;
      }

      _files = addFile(_files, fileObj);
    });

    setFilesList(_files);
  }

  /**
   * Return all files
   *
   * @returns {any[]}
   */
  function files(list?: IFile[]): IUploadedFile[] {
    list = Array.isArray(list) ? list : filesList;
    const files = [];

    for (const file of list) {
      const fileObj: IUploadedFile = {
        format: file.format,
        originalName: file.originalName
      };

      // File
      if (file.format === 'file') {
        fileObj.file = file.file;
        files.push(fileObj);
      }
      // URL
      else {
        fileObj.url = file.url;
        files.push(fileObj);
      }
    }

    return files;
  }

  // Handle selected file
  function onDrop(acceptedFiles) {
    let _files = [...filesList];
    acceptedFiles.forEach((file) => {
      const fileType = type(file);
      // Format name
      const fileExt: any = ext(file.name);
      const basename = shortBasename(file.name);
      const blob = new Blob([file]);
      _files = addFile(_files, {
        format: 'file',
        file,
        url: URL.createObjectURL(blob).toString(),
        type: fileType,
        originalName: file.name,
        shortName: `${basename}.${fileExt}`
      });
    });

    setFilesList(_files);

    // Trigger onLoad
    if (typeof props.onLoad === 'function')
      props.onLoad(files(_files));
  }

  // Add file to file list and return new file list array
  function addFile(filesList: IFile[], file: IFile): IFile[] {
    const _files = [...filesList];
    _files.push(file);

    // Check max files
    let maxFiles = props.maxFiles ? props.maxFiles : 1;
    maxFiles = maxFiles === 1 ? 1 : maxFiles;

    if (maxFiles !== -1 && _files.length > maxFiles) {
      _files.splice(0, _files.length - maxFiles);
    }

    return _files;
  }

  return (
    <div
      className={`form-field upload ${props.className}`}
      style={props.style}
    >
      <div className="field">
        <div className={'label ' + (props.hideLabel ? 'hide' : '')}>
          <label htmlFor={props.id}>
            {props.label}
          </label>
        </div>

        <Dropzone
          onDrop={onDrop}
          multiple={props.multiple}
          accept={props.accept}
        >
          {({getRootProps, getInputProps}) => (
            <div
              {...getRootProps()}
              className="upload-area"
            >
              <input
                {...getInputProps()}
                name={props.name}
              />
              <div className="placeholder">
                {props.placeholder}
              </div>
            </div>
          )}
        </Dropzone>

        <div className="files">
          {filesList.map((file, i) => (
            <div
              key={i}
              className={'file'}
            >
              <div
                className="delete"
                onClick={() => {
                  const files = [...filesList];
                  files.splice(i, 1);
                  setFilesList(files);
                }}
              />

              <div className="preview">
                {file.type === 'image' && <div className="preview-img">
                  <img
                      src={file.url}
                      width={150}
                      alt={file.originalName}
                  />
                </div>}
                {!file.type && <div className="preview-doc">
                  <span className={'name'}>{file.shortName}</span>
                  <img
                      src={'/assets/images/icon-metal-paper-clip.svg'}
                      alt={file.originalName}
                  />
                </div>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Upload;
