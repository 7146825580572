import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Select, {SelectStylesHideIndicatorSeparator} from '../../Core/Form/Select/Select';
import Input from '../../Core/Form/Input/Input';
import DownArrowIndicator from '../../Core/Form/Select/Indicators/DownArrowIndicator';
import Cars from '../../Providers/Cars';

const carsProvider = new Cars();

const CustomerZoneAutoFilter: FC<{
  onFilter?: (filter: any) => void
}> = (props) => {
  const {t} = useTranslation();
  const [filter, setFilter] = useState<{
    point_of_loading?: string;
    vin?: string;
    lot?: string;
    container_number?: string;
    year_from?: number;
    year_to?: number;
    brand?: string;
    model?: string;
  }>({
    year_from: carsProvider.years().reverse()[0],
    year_to: carsProvider.years()[0]
  });

  return (
    <div className="row table-filter">
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <Input
          hideLabel={true}
          input={{
            placeholder: t('admin:filter.point_of_loading'),
            onChange: e => setFilter({...filter, point_of_loading: e.target.value})
          }}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <Input
          hideLabel={true}
          input={{
            placeholder: t('admin:filter.vin'),
            onChange: e => setFilter({...filter, vin: e.target.value})
          }}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <Input
          hideLabel={true}
          input={{
            placeholder: t('admin:filter.lot_number'),
            onChange: e => setFilter({...filter, lot: e.target.value})
          }}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <Input
          hideLabel={true}
          input={{
            placeholder: t('admin:filter.container_number'),
            onChange: e => setFilter({...filter, container_number: e.target.value})
          }}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-2 d-flex align-items-center table-filter-with-label">
        <span className="table-filter-label">
          {t('admin:filter.year_from')}
        </span>
        <Select
          hideLabel={true}
          select={{
            isSearchable: false,
            options: carsProvider.years().map((year) => ({
              value: year,
              label: year
            })),
            defaultValue: {
              value: carsProvider.years().reverse()[0],
              label: carsProvider.years().reverse()[0]
            },
            components: {
              DropdownIndicator: DownArrowIndicator,
            },
            styles: SelectStylesHideIndicatorSeparator,
            onChange: option => setFilter({...filter, year_from: option.value})
          }}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-2 d-flex align-items-center table-filter-with-label">
        <span className="table-filter-label">
          {t('admin:filter.year_to')}
        </span>
        <Select
          hideLabel={true}
          select={{
            isSearchable: false,
            options: carsProvider.years().map((year) => ({
              value: year,
              label: year
            })),
            defaultValue: {
              value: carsProvider.years()[0],
              label: carsProvider.years()[0]
            },
            components: {
              DropdownIndicator: DownArrowIndicator,
            },
            styles: SelectStylesHideIndicatorSeparator,
            onChange: option => setFilter({...filter, year_to: option.value})
          }}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <Input
          hideLabel={true}
          input={{
            placeholder: 'Марка/Модель'
          }}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <Select
          hideLabel={true}
          select={{
            isSearchable: false,
            options: [
              {
                value: '1',
                label: 'Иванов'
              },
              {
                value: '2',
                label: 'Петров'
              },
              {
                value: '3',
                label: 'Сидоров'
              }
            ],
            defaultValue: {
              value: null,
              label: 'Дилер'
            },
            components: {
              DropdownIndicator: DownArrowIndicator,
            },
            styles: SelectStylesHideIndicatorSeparator
          }}
        />
      </div>
      <div className="col-12 col-md-12 col-lg-3 col-xl-2 d-flex justify-content-center justify-content-lg-start">
        <button
          className="btn btn-submit mb-control"
          onClick={() => {
            if (typeof props.onFilter === 'function')
              props.onFilter(filter);
          }}
        >
          {t('buttons.search')}
        </button>
      </div>
    </div>
  );
}

export default CustomerZoneAutoFilter;
