import React, {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './Calculator.scss';
import Notify from '../../Core/Ui/Notify/Notify';
import $ from 'jquery';
import * as EgoUtil from '@egofoxlab/util';
import Config from '../../Providers/Config';
import axios from 'axios';
import {IHttpResponse} from '../../Interfaces/IHttpResponse';
import Input from '../../Core/Form/Input/Input';
import Select, {SelectStylesHideIndicatorSeparator} from '../../Core/Form/Select/Select';
import Head from '../Head/Head';
import BottomToolbar from '../BottomToolbar/BottomToolbar';
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary} from '@material-ui/core';
import DownArrowIndicator from '../../Core/Form/Select/Indicators/DownArrowIndicator';

const engineVolumes: {
  value: string,
  label: string
}[] = [...Array(6.5 / .1), 0].map((item, i) => {
  const volume = parseInt(String((.5 + i * 0.1) * 10)) / 10;

  return {
    value: String(volume),
    label: volume.toFixed(1)
  };
});

const years: {
  value: string,
  label: string
}[] = [...Array((new Date()).getFullYear() - 1998 + 1)].map((item, i) => {
  return {
    value: String(1998 + i),
    label: String(1998 + i)
  };
}).reverse();

const Calculator: FC = () => {
  const {t} = useTranslation();
  const [_cities, set_cities] = useState<any[]>([]);
  const [country, setCountry] = useState('us');
  const [auctions, setAuctions] = useState<any[]>([]);
  const [auction, setAuction] = useState('copart');
  const [lotPrice, setLotPrice] = useState<number>();
  const [cities, setCities] = useState<any[]>([]);
  const [city, setCity] = useState('');
  const [vehicleType, setVehicleType] = useState('sedan');
  const [insurance, setInsurance] = useState('180');
  const [engineVolume, setEngineVolume] = useState(.5);
  const [engineType, setEngineType] = useState('gas');
  const [year, setYear] = useState((new Date()).getFullYear());
  const [salePrices, setSalePrices] = useState<any[]>([]);
  const [internetBidFees, setInternetBidFees] = useState<any[]>([]);
  const [saveProcess, setSaveProcess] = useState(false);
  const [saveNotify, setSaveNotify] = useState(false);
  const _eForm = useRef<HTMLFormElement>();

  useEffect(() => {
    load();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateAuctions();
    updateCities();
    // eslint-disable-next-line
  }, [_cities, country]);

  function load(): void {
    //  Cities
    axios.get(Config.api('/auction/city/list'))
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);

          return;
        }

        set_cities(response.data);
        updateCities();
      });

    //  Sale Prices
    axios.get(Config.api('/auction/sale-price/list'))
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);

          return;
        }

        setSalePrices(response.data);
      });

    //  Internet Bid Fees
    axios.get(Config.api('/auction/internet-bid-fee/list'))
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);

          return;
        }

        setInternetBidFees(response.data);
      });
  }

  function reset(): void {
    //  Clear input field
    EgoUtil.clearFields(_eForm.current);
  }

  function calculate(): {
    customsClearance: {
      excise: number
      duty: number,
      vat: number,
      total: number
    },
    landPrice: number,
    shipping: number,
    countryServiceTax: number,
    insurance: number,
    portService: number,
    broker: number,
    gr8Auto: number,
    auctionFee: number,
    estimatedCustomsValue: number,
    includingDelivery: number,
    total: number
  } {
    let result: any = {};
    //  Convert Vehicle Type
    const vehicleTypeObj = {
      sedan: 'sedan',
      crossover: 'crossover',
      'heavy-duty': 'heavy_duty'
    }[vehicleType];
    //  Customs Clearance
    result.customsClearance = customsClearance(lotPrice, year, engineVolume, engineType);
    //  Delivery in the USA
    let cityObj = getCity(city);
    cityObj = cityObj ? cityObj : {};
    //  To port land price
    result.landPrice = cityObj[`to_port_land_price_${auction}`];
    //  Shipping
    result.shipping = cityObj[`odessa_shipping_price_${vehicleTypeObj}`];
    //  Country Service tax
    result.countryServiceTax = auctionFee('gr8auto', lotPrice, null);
    //  Insurance
    result.insurance = parseFloat(insurance);
    //  Port Service
    result.portService = 450;
    //  Broker
    result.broker = 350;
    //  GR8Auto
    result.gr8Auto = 300;
    //  Auction Fee
    result.auctionFee = auctionFee(auction, lotPrice, country);

    switch (auction) {
      case 'copart':
      case 'iaai':
        result.auctionFee += 59;

        break;
    }

    //  Estimated Customs Value
    result.estimatedCustomsValue = lotPrice + result.auctionFee + 400;
    //  Including delivery
    result.includingDelivery = lotPrice + result.auctionFee + result.landPrice + result.shipping +
      result.countryServiceTax + result.broker + result.insurance + result.portService + result.gr8Auto;

    //  Total
    result.total = result.customsClearance.total + result.includingDelivery;

    return result;
  }

  //  Return Tax calculation
  function customsClearance(price: number, year: number, engineVolume: number, engineType: string): {
    excise: number
    duty: number,
    vat: number,
    total: number
  } {
    //  Calculate excise in EUR
    let exciseRate;

    switch (engineType) {
      case 'diesel':
        if (engineVolume <= 3.5) {
          exciseRate = 75;
        } else {
          exciseRate = 150;
        }

        break;

      case 'electric':
        return {
          excise: 113,
          duty: 0,
          vat: 0,
          total: 113
        };

      case 'gas':
      case 'hybrid':
        if (engineVolume <= 3) {
          exciseRate = 50;
        } else {
          exciseRate = 100;
        }

        break;
    }

    //  Convert EUR to USD
    exciseRate *= 1.13;
    //  Car age
    let age = (new Date()).getFullYear() - year;
    age = age <= 0 ? 1 : age;
    const excise = exciseRate * engineVolume * age;
    //  Duty
    const duty = price * 0.1;
    //  VAT
    const vat = (price + duty + excise) * 0.2;

    return {
      excise,
      duty,
      vat,
      total: excise + duty + vat
    };
  }

  //  Return city object by id
  function getCity(id: string): any {
    return (_cities ? _cities : []).find((item) => {
      return parseInt(item.id) === parseInt(id);
    });
  }

  //  Auction Fee
  function auctionFee(auction: string, price: number, country: string): number {
    let salePriceFee = 0;
    let _salePrices = [];

    switch (auction) {
      case 'copart':
        _salePrices.push(salePrices.find((item) => {
          return item.auction === auction && price >= item.price_from && (price <= item.price_to || item.price_to === -1) && item.country === country;
        }));

        break;

      case 'iaai':
      case 'gr8auto':
      case 'impactauto':
        _salePrices.push(salePrices.find((item) => {
          return item.auction === auction && price >= item.price_from && (price <= item.price_to || item.price_to === -1);
        }));

        break;

      case 'manheim':
        //  Passenger Commercial
        _salePrices.push(salePrices.find((item) => {
          return item.auction === auction && price >= item.price_from && (price <= item.price_to || item.price_to === -1) && item.type === 'passenger-commercial';
        }));
        //  Simulcast
        _salePrices.push(salePrices.find((item) => {
          return item.auction === auction && price >= item.price_from && (price <= item.price_to || item.price_to === -1) && item.type === 'simulcast';
        }));

        break;
    }

    //  Remove empty items
    _salePrices = _salePrices.filter((item) => {
      return item;
    });

    switch (auction) {
      case 'copart':
        switch (country) {
          case 'us':
            _salePrices.forEach((salePrice) => {
              //  Percentage
              if (salePrice.fee_a_type === 'percentage') {
                salePriceFee += price * salePrice.fee_a / 100;
              }
              //  Price
              else {
                salePriceFee += salePrice.fee_a;
              }
            });

            break;

          case 'ca':
            _salePrices.forEach((salePrice) => {
              //  Percentage
              if (salePrice.secured_funds_fee === 'percentage') {
                salePriceFee += price * salePrice.secured_funds_fee / 100;
              }
              //  Price
              else {
                salePriceFee += salePrice.secured_funds_fee;
              }
            });

            break;
        }

        break;

      case 'iaai':
      case 'manheim':
      case 'gr8auto':
        _salePrices.forEach((salePrice) => {
          salePriceFee += salePrice.buyer_fee + price * salePrice.buyer_fee_percentage / 100;
        });

        break;

      case 'impactauto':
        _salePrices.forEach((salePrice) => {
          salePriceFee += salePrice.buyer_fee + salePrice.pull_fee + salePrice.fee;
        });

        break;
    }

    //  Internet Bid
    const internetBid = internetBidFees.find((item) => {
      switch (auction) {
        case 'copart':
          return item.auction === auction && price >= item.price_from && (price <= item.price_to || item.price_to === -1) && item.country === country;

        default:
          return item.auction === auction && price >= item.price_from && (price <= item.price_to || item.price_to === -1);
      }
    });

    if (internetBid) {
      salePriceFee += internetBid.fee;
    }

    return salePriceFee;
  }

  function updateAuctions() {
    const auctions = {
      us: [
        {
          value: 'copart',
          label: 'Copart'
        },
        {
          value: 'iaai',
          label: 'IAAI'
        },
        {
          value: 'manheim',
          label: 'Manheim'
        }
      ],
      ca: [
        {
          value: 'copart',
          label: 'Copart'
        },
        {
          value: 'manheim',
          label: 'Manheim'
        },
        {
          value: 'impactauto',
          label: 'ImpactAuto'
        }
      ]
    }[country];

    setAuctions(auctions);
  }

  function updateCities() {
    const cities = _cities
      .filter(city => {
        return city.country === country;
      })
      .map(item => {
        return {
          value: item.id,
          label: `${item.city} - ${item.port}`
        };
      });

    setCities(cities);
    setCity('');
  }

  const onSubmit = (e) => {
    if (!_eForm.current.checkValidity()) {
      return;
    }

    e.preventDefault();

    // Set `Save Process` flag
    setSaveProcess(true);

    const eForm = $(_eForm.current);
    const formData = new FormData();
    //  Type
    formData.append('type', 'admin');
    //  Client
    formData.append('client', eForm.find('[name="client"]').val());
    //  Vehicle
    formData.append('vehicle', eForm.find('[name="vehicle"]').val());
    //  Lot URL
    formData.append('lot-url', eForm.find('[name="lot-url"]').val());
    //  VIN
    formData.append('vin', eForm.find('[name="vin"]').val());
    //  Country
    formData.append('country', country);
    //  Auction
    formData.append('auction', auction);
    //  Lot Price
    formData.append('lot-price', eForm.find('[name="lot-price"]').val());
    //  City
    formData.append('city', city);
    //  Vehicle Type
    formData.append('vehicle-type', vehicleType);
    //  Insurance
    formData.append('insurance', insurance);
    //  Engine Volume
    formData.append('engine-volume', String(engineVolume));
    //  Engine Type
    formData.append('engine-type', engineType);
    //  Year
    formData.append('year', String(year));
    //region Personal Manager
    //  Name
    formData.append('personal-manager[name]', eForm.find('[name="personal-manager-name"]').val());
    //  Phone
    formData.append('personal-manager[phone]', eForm.find('[name="personal-manager-phone"]').val());
    //  Email
    formData.append('personal-manager[email]', eForm.find('[name="personal-manager-email"]').val());
    //endregion

    axios.post(Config.api('/calculator/create'), formData, {
      headers: {'Content-Type': 'multipart/form-data'}
    })
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);
          //  Unset `Save Process` flag
          setSaveProcess(false);

          return;
        }

        // Mark as saved
        setSaveNotify(true);

        //  Unset `Save Process` flag
        setSaveProcess(false);

        const pdfId = response.data.id;

        window.open(Config.url(`/calculator/pdf/${pdfId}`));
      });
  };

  const calculation = calculate();
  let citySelect = cities.find(item => item.value === city);
  citySelect = citySelect ? citySelect : null;

  return (
    <div id="calculator-component">
      <Head
        head={t('admin:calculator.head')}
        right={<div className="d-flex justify-content-lg-end justify-content-start mt-3 mt-lg-0">
          <button
            form="calculator-form-component"
            className="btn btn-save"
            disabled={saveProcess}
          >
            {t('buttons.save')}
          </button>
        </div>}
        hr={{style: {marginTop: 20}}}
      />

      <form
        ref={_eForm}
        id="calculator-form-component"
        onSubmit={onSubmit}
      >
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            {/*Client*/}
            <Input
              label={t('admin:calculator.client')}
              input={{
                name: 'client',
                id: 'client',
                required: true
              }}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            {/*Vehicle*/}
            <Input
              label={t('admin:calculator.vehicle')}
              input={{
                name: 'vehicle',
                id: 'vehicle',
                required: true
              }}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            {/*Lot URL*/}
            <Input
              label={t('admin:calculator.lot_url')}
              input={{
                type: 'url',
                name: 'lot-url',
                id: 'lot-url',
                required: true
              }}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            {/*VIN*/}
            <Input
              label={t('admin:calculator.vin')}
              input={{
                name: 'vin',
                id: 'vin',
                required: true,
                pattern: '^[a-zA-Z0-9]+$',
                minLength: 17,
                maxLength: 17
              }}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            {/*City*/}
            <Select
              label={t('admin:calculator.city')}
              select={{
                isSearchable: true,
                options: cities,
                value: citySelect,
                components: {
                  DropdownIndicator: DownArrowIndicator,
                },
                styles: SelectStylesHideIndicatorSeparator,
                onChange: (option) => setCity(option.value)
              }}
              required={true}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            {/*Vehicle Type*/}
            <Select
              label={t('admin:calculator.vehicle_type')}
              select={{
                isSearchable: false,
                options: [
                  {
                    value: 'sedan',
                    label: t('admin:calculator.vehicle_type_sedan')
                  },
                  {
                    value: 'crossover',
                    label: t('admin:calculator.vehicle_type_crossover')
                  },
                  {
                    value: 'heavy-duty',
                    label: t('admin:calculator.vehicle_type_heavy_duty')
                  }
                ],
                defaultValue: {
                  value: 'sedan',
                  label: t('admin:calculator.vehicle_type_sedan')
                },
                components: {
                  DropdownIndicator: DownArrowIndicator,
                },
                styles: SelectStylesHideIndicatorSeparator,
                onChange: (option) => setVehicleType(option.value)
              }}
              required={true}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            {/*Insurance*/}
            <Select
              label={t('admin:calculator.insurance')}
              select={{
                isSearchable: false,
                options: [
                  {
                    value: '180',
                    label: t('admin:calculator.insurance_1')
                  },
                  {
                    value: '360',
                    label: t('admin:calculator.insurance_2')
                  },
                  {
                    value: '540',
                    label: t('admin:calculator.insurance_3')
                  }
                ],
                defaultValue: {
                  value: '180',
                  label: t('admin:calculator.insurance_1')
                },
                tooltip: {
                  title: {
                    '180': t('admin:calculator.insurance_tooltip_1'),
                    '360': t('admin:calculator.insurance_tooltip_2'),
                    '540': t('admin:calculator.insurance_tooltip_3'),
                  }[insurance]
                },
                components: {
                  DropdownIndicator: DownArrowIndicator,
                },
                styles: SelectStylesHideIndicatorSeparator,
                onChange: (option) => setInsurance(option.value)
              }}
              required={true}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            {/*Engine Volume*/}
            <Select
              label={t('admin:calculator.engine_volume')}
              select={{
                isSearchable: false,
                options: engineVolumes,
                defaultValue: engineVolumes[0],
                components: {
                  DropdownIndicator: DownArrowIndicator,
                },
                styles: SelectStylesHideIndicatorSeparator,
                onChange: (option) => setEngineVolume(parseFloat(option.value))
              }}
              required={true}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            {/*Country*/}
            <Select
              label={t('admin:calculator.country')}
              select={{
                isSearchable: false,
                options: [
                  {
                    value: 'us',
                    label: 'USA'
                  },
                  {
                    value: 'ca',
                    label: 'Canada'
                  }
                ],
                defaultValue: {
                  value: 'us',
                  label: 'USA'
                },
                components: {
                  DropdownIndicator: DownArrowIndicator,
                },
                styles: SelectStylesHideIndicatorSeparator,
                onChange: (option) => {
                  setCountry(option.value);
                  updateAuctions();
                  updateCities();
                }
              }}
              required={true}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            {/*Engine Type*/}
            <Select
              label={t('admin:calculator.engine_type')}
              select={{
                isSearchable: false,
                options: [
                  {
                    value: 'gas',
                    label: t('admin:calculator.engine_type_gas')
                  },
                  {
                    value: 'diesel',
                    label: t('admin:calculator.engine_type_diesel')
                  },
                  {
                    value: 'hybrid',
                    label: t('admin:calculator.engine_type_hybrid')
                  },
                  {
                    value: 'electric',
                    label: t('admin:calculator.engine_type_electric')
                  }
                ],
                defaultValue: {
                  value: 'gas',
                  label: t('admin:calculator.engine_type_gas')
                },
                components: {
                  DropdownIndicator: DownArrowIndicator,
                },
                styles: SelectStylesHideIndicatorSeparator,
                onChange: (option) => setEngineType(option.value)
              }}
              required={true}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            {/*Auction*/}
            <Select
              label={t('admin:calculator.auction')}
              select={{
                isSearchable: false,
                options: auctions,
                value: auctions.find(item => item.value === auction),
                components: {
                  DropdownIndicator: DownArrowIndicator,
                },
                styles: SelectStylesHideIndicatorSeparator,
                onChange: (option) => setAuction(option.value)
              }}
              required={true}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            {/*Year*/}
            <Select
              label={t('admin:calculator.year')}
              select={{
                isSearchable: false,
                options: years,
                defaultValue: years[0],
                components: {
                  DropdownIndicator: DownArrowIndicator,
                },
                styles: SelectStylesHideIndicatorSeparator,
                onChange: (option) => setYear(parseInt(option.value))
              }}
              required={true}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            {/*Lot Price*/}
            <Input
              label={t('admin:calculator.lot_price')}
              input={{
                type: 'number',
                name: 'lot-price',
                id: 'lot-price',
                required: true,
                onChange: (e) => setLotPrice(parseFloat(e.target.value)),
                onKeyUp: (e) => setLotPrice(parseFloat(e.target.value))
              }}
            />
          </div>
        </div>

        {/*Personal Manager*/}
        <ExpansionPanel defaultExpanded={true}>
          <ExpansionPanelSummary id="section-password">
            {t('admin:calculator.personal_manager')}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="row flex-grow-1">
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                {/*Name*/}
                <Input
                  label={t('admin:calculator.personal_manager_name')}
                  input={{
                    name: 'personal-manager-name',
                    id: 'personal-manager-name',
                    required: true
                  }}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                {/*Phone*/}
                <Input
                  label={t('admin:calculator.personal_manager_phone')}
                  input={{
                    name: 'personal-manager-phone',
                    id: 'personal-manager-phone',
                    required: true
                  }}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                {/*Email*/}
                <Input
                  label={t('admin:calculator.personal_manager_email')}
                  input={{
                    name: 'personal-manager-email',
                    id: 'personal-manager-email',
                    required: true
                  }}
                />
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {/*Calculation*/}
        <div className="row calc-result">
          <div className="col-12 col-md-6">
            <h3 className={'calc-result-header'}>
              {t('admin:calculator.price_including_delivery')}: {Math.round(parseFloat(String(calculation.includingDelivery > 0 ? calculation.includingDelivery : 0)))} $
            </h3>
            {/*<div>
                                                {t('admin:calculator.price_estimated_customs_value')}: {Math.round(parseFloat(String(calculation.estimatedCustomsValue > 0 ? calculation.estimatedCustomsValue : 0)))} $
                                            </div>*/}
            <div className="calc-result-item">
              {t('admin:calculator.price_winning_bet')}: {Math.round(parseFloat(String(lotPrice > 0 ? lotPrice : 0)))} $
            </div>
            <div className="calc-result-item">
              {t('admin:calculator.price_auction')}: {Math.round(parseFloat(String(calculation.auctionFee > 0 ? calculation.auctionFee : 0)))} $
            </div>
            <div className="calc-result-item">
              {t('admin:calculator.price_shipping')}: {Math.round(parseFloat(String(calculation.landPrice + calculation.shipping > 0 ? calculation.landPrice + calculation.shipping : 0)))} $
            </div>
            <div className="calc-result-item">
              {t('admin:calculator.price_broker_forwarder')}: {Math.round(parseFloat(String(calculation.broker > 0 ? calculation.broker : 0)) + parseFloat(String(calculation.portService > 0 ? calculation.portService : 0)))} $
            </div>
            <div className="calc-result-item">
              {t('admin:calculator.price_insurance')}: {Math.round(parseFloat(String(calculation.insurance > 0 ? calculation.insurance : 0)))} $
            </div>
            <div className="calc-result-item">
              {t('admin:calculator.price_country_service_tax')}: {Math.round(parseFloat(String(calculation.countryServiceTax > 0 ? calculation.countryServiceTax : 0)))} $
            </div>
            <div className="calc-result-item">
              {t('admin:calculator.price_gr8auto')}: {Math.round(parseFloat(String(calculation.gr8Auto > 0 ? calculation.gr8Auto : 0)))} $
            </div>
          </div>
          <div className="col-12 col-md-6">
            <h3 className={'calc-result-header'}>
              {t('admin:calculator.price_customs_clearance')}: {Math.round(parseFloat(String(calculation.customsClearance.total > 0 ? calculation.customsClearance.total : 0)))} $
            </h3>
          </div>
        </div>

        <BottomToolbar
          left={<h3 className="calc-result-total mb-3 mb-lg-0">
            {t('admin:calculator.price_total')}: {Math.round(parseFloat(String(calculation.total > 0 ? calculation.total : 0)))} $
          </h3>}
          right={<>
            <div className="d-flex justify-content-start justify-content-lg-end train-buttons">
              <button
                className="btn btn-save"
                disabled={saveProcess}
              >
                {t('buttons.save')}
              </button>
              <button
                type="button"
                className="btn btn-reload"
                onClick={() => reset()}
              >
                {t('buttons.reset')}
              </button>
            </div>
            <div className="d-flex justify-content-end">
              <Notify
                type="save-form"
                hide={!saveNotify}
              />
            </div>
          </>}
        />
      </form>
    </div>
  );
}

export default Calculator;
