import React, {FC} from 'react';
import './KeyboardDateTimePicker.scss';
import Config from '../../../../Providers/Config';
import Picker from '../Picker';
import {KeyboardDateTimePicker as KeyboardDateTimePickerComponent} from '@material-ui/pickers';
import {useTranslation} from 'react-i18next';

const KeyboardDateTimePicker: FC<any> = (props) => {
  const {t} = useTranslation();

  return (
    <Picker
      {...props}
      className={`KeyboardDateTimePicker ${props.className ? props.className : ''}`}
      picker={<KeyboardDateTimePickerComponent
        autoOk
        ampm={false}
        disableToolbar={true}
        variant="inline"
        format={Config.DATE_TIME_FORMAT}
        InputAdornmentProps={{position: 'start'}}
        PopoverProps={{
          anchorOrigin: {vertical: 'bottom', horizontal: 'left'},
        }}
        invalidDateMessage={t('picker_invalid_ate_message')}
        {...props.KeyboardDateTimePicker}
      />}
    />
  );
};

export default KeyboardDateTimePicker;
