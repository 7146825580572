import moment from 'moment';
import ICustomerZoneAuto from './ICustomerZoneAuto';

export default class ICustomerZoneBalance {

  id: number;
  customerZoneAutoId: number;
  data: {
    city: string,
    price: number,
    delivery: number,
    shipping: number,
    dealerFee: number,
    storageFee: number,
    loadingFee: number,
    lateFee: number,
    insurance: number,
    currentBalance: number,
    payedAmount: number,
    balance: number,
    customNotes: string
  };
  customerZoneAuto?: ICustomerZoneAuto;
  createdAt: string;
  updatedAt: string;

  public static parse(data: any): ICustomerZoneBalance {
    if (!data) {
      return null;
    }

    const customerZoneBalance = new ICustomerZoneBalance();

    customerZoneBalance.id = data.id ? parseInt(data.id, 10) : null;
    customerZoneBalance.customerZoneAutoId = data.customer_zone_auto_id ? data.customer_zone_auto_id : null;
    customerZoneBalance.data = {
      city: data.data?.['city'] ?? null,
      price: parseFloat(data.data?.['price']) || 0,
      delivery: parseFloat(data.data?.['delivery']) || 0,
      shipping: parseFloat(data.data?.['shipping']) || 0,
      dealerFee: parseFloat(data.data?.['dealer-fee']) || 0,
      storageFee: parseFloat(data.data?.['storage-fee']) || 0,
      loadingFee: parseFloat(data.data?.['loading-fee']) || 0,
      lateFee: parseFloat(data.data?.['late-fee']) || 0,
      insurance: parseFloat(data.data?.['insurance']) || 0,
      currentBalance: parseFloat(data.data?.['current-balance']) || 0,
      payedAmount: parseFloat(data.data?.['payed-amount']) || 0,
      balance: parseFloat(data.data?.['balance']) || 0,
      customNotes: data.data?.['custom-notes'],
    };
    customerZoneBalance.customerZoneAuto = ICustomerZoneAuto.parse(data['customer-zone-auto']);
    customerZoneBalance.createdAt = data.created_at ? moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') : null;
    customerZoneBalance.updatedAt = data.updated_at ? moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') : null;

    return customerZoneBalance;
  }

}
