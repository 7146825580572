import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './CustomerZoneAutoForm.scss';
import axios from 'axios';
import {useHistory, useParams} from 'react-router-dom';
import Config from '../../Providers/Config';
import IInvoice from '../../Interfaces/IInvoice';
import ViewValue from '../../Core/Form/ViewValue/ViewValue';
import ViewFiles from '../../Core/Form/ViewFiles/ViewFiles';
import IAuctionCity from '../../Interfaces/IAuctionCity';
import Head from '../Head/Head';

// Load form
async function load(id) {
  const formResponse = (await axios.get(Config.api(`/customer-zone/auto/get/${id}`))).data;

  if (!formResponse.success) {
    console.error(formResponse.message);

    return false;
  }

  return formResponse.data;
}

// Load Fence point value
async function loadFencePoint(customerZone) {
  // Skip if empty customer zone
  if (!customerZone?.data?.['fence-point'])
    return null;

  const fencePointResponse = (await axios.get(
    Config.api(`/auction/city/search?id=${customerZone.data['fence-point']}`)
  )).data;

  if (!fencePointResponse.success)
    return null;

  const auctionCity = fencePointResponse.data.length > 0 ? IAuctionCity.parse(fencePointResponse.data[0]) : null;

  if (!auctionCity)
    return null;

  return `${auctionCity.city}   – ${auctionCity.port}`;
}

// Load Invoice
async function loadInvoice(customerZone) {
  // Skip if empty customer zone
  if (!customerZone?.data?.['invoice'])
    return;

  const invoiceResponse = (await axios.get(
    Config.api(`/invoices/search?id=${customerZone.data.invoice}`)
  )).data;

  if (!invoiceResponse.success)
    return null;

  const invoice = invoiceResponse.data.length > 0 ? IInvoice.parse(invoiceResponse.data[0]) : null;

  if (!invoice)
    return null;

  return invoice.data.invoiceNumber;
}

const CustomerZoneAutoForm: FC = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const id = parseInt(useParams().id);
  const [customerZone, setCustomerZone] = useState(null);
  const [invoice, setInvoice] = useState('');
  const [fencePoint, setFencePoint] = useState('');

  useEffect(() => {
    // Load form data
    (async () => {
      if (!(id > 0)) {
        history.push('/admin/customer-zone/auto');

        return;
      }

      const customerZone = await load(id);

      if (!customerZone) {
        history.push('/admin/customer-zone/auto');

        return;
      }

      setCustomerZone(customerZone);
      setFencePoint(await loadFencePoint(customerZone));
      setInvoice(await loadInvoice(customerZone));
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {customerZone ?
        <div
          id="customer-zone-auto-component"
        >
          <Head
            head={t('admin:customer_zone_auto.form.head')}
            subhead={'VIN - Auto'}
          />

          <form id="customer-zone-view-component">
            <div className="row">
              <div className="col-12 col-md-6">
                {/*Date*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.date')}
                  value={customerZone.data ? customerZone.data['date'] : null}
                />

                {/*Buyer number*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.buyer_number')}
                  value={customerZone.data ? customerZone.data['buyer-number'] : null}
                />

                {/*Year*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.year')}
                  value={customerZone.data ? customerZone.data['year'] : null}
                />

                {/*Type*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.type')}
                  value={customerZone.data ? customerZone.data['type'] : null}
                />

                {/*Model*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.model')}
                  value={customerZone.data ? customerZone.data['model'] : null}
                />

                {/*Lot*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.lot')}
                  value={customerZone.data ? customerZone.data['lot'] : null}
                />

                {/*Vin*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.vin')}
                  value={customerZone.data ? customerZone.data['vin'] : null}
                />

                {/*Point of loading*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.point_of_loading')}
                  value={customerZone.data ? customerZone.data['point-of-loading'] : null}
                />

                {/*Date Of Loading*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.date_of_loading')}
                  value={customerZone.data ? customerZone.data['date-of-loading'] : null}
                />

                {/*Auction*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.auction')}
                  value={customerZone.data ? customerZone.data['auction'] : null}
                />

                {/*Destination port*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.destination_port')}
                  value={customerZone.data ? customerZone.data['destination-port'] : null}
                />

                {/*Container number*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.container_number')}
                  value={customerZone.data ? customerZone.data['container-number'] : null}
                />

                {/*Delivered to warehouse*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.delivered_to_warehouse')}
                  value={customerZone.data ? customerZone.data['delivered-to-warehouse'] : null}
                />

                {/*Arrival Date*/}
                {/*<ViewValue
                                                    label={t('admin:customer_zone_auto.delivered_to_warehouse')}
                                                    value={customerZone.data ? customerZone.data['arrival-date'] : null}
                                                />
*/}
                {/*Dock receipt*/}
                <ViewFiles
                  label={t('admin:customer_zone_auto.dock_receipt')}
                  value={customerZone.data ? customerZone.data['dock-receipt'] : null}
                  originalKeyName={'original-name'}
                />

                {/*Title status*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.title_status')}
                  value={customerZone.data ? customerZone.data['title-status'] : null}
                />

                {/*Title received*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.title_received')}
                  value={customerZone.data ? customerZone.data['title-received'] : null}
                />

                {/*Fence Point*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.fence_point')}
                  value={fencePoint}
                />
              </div>

              <div className="col-12 col-md-6">
                {/*Keys*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.keys')}
                  value={customerZone.data ? customerZone.data['keys'] : null}
                />

                {/*Notes*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.notes')}
                  value={customerZone.data ? customerZone.data['notes'] : null}
                />

                {/*Invoice*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.invoice')}
                  value={invoice}
                />

                {/*Auto price*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.auto_price')}
                  value={customerZone.data ? customerZone.data['auto-price'] : null}
                />

                {/*Paid*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.paid')}
                  value={customerZone.data ? customerZone.data['paid'] : null}
                />

                {/*Waggon number*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.waggon_number')}
                  value={customerZone.data ? customerZone.data['waggon-number'] : null}
                />

                {/*Bill of lading*/}
                <ViewFiles
                  label={t('admin:customer_zone_auto.bill_of_lading')}
                  value={customerZone.data ? customerZone.data['bill-of-lading'] : null}
                  originalKeyName={'original-name'}
                />

                {/*Waggon loading place*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.waggon_loading_place')}
                  value={customerZone.data ? customerZone.data['waggon-loading-place'] : null}
                />

                {/*Destination*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.destination')}
                  value={customerZone.data ? customerZone.data['destination'] : null}
                />

                {/*Planned wagon lading*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.planned_wagon_lading')}
                  value={customerZone.data ? customerZone.data['planned-wagon-lading'] : null}
                />

                {/*Waggon loading date*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.waggon_loading_date')}
                  value={customerZone.data ? customerZone.data['waggon-loading-date'] : null}
                />

                {/*Waggon estimate arrival date*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.waggon_estimate_arrival_date')}
                  value={customerZone.data ? customerZone.data['waggon-estimate-arrival-date'] : null}
                />

                {/*Waggon arrival date*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.waggon_arrival_date')}
                  value={customerZone.data ? customerZone.data['waggon-arrival-date'] : null}
                />

                {/*Waggon notes*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.waggon_notes')}
                  value={customerZone.data ? customerZone.data['waggon-notes'] : null}
                />

                {/*Waggon notes*/}
                <ViewValue
                  label={t('admin:customer_zone_auto.notes_for_yourself')}
                  value={customerZone.data ? customerZone.data['notes-for-yourself'] : null}
                />
              </div>
            </div>

            <hr style={{marginTop: '29px'}} />

            <div className="row">
              <div className="col-12 col-md-12">
                {/*Photos*/}
                <ViewFiles
                  label={t('admin:customer_zone_auto.photos')}
                  value={customerZone.data ? customerZone.data['photos'] : null}
                  originalKeyName={'original-name'}
                />
              </div>
            </div>

            <hr style={{marginTop: '29px'}} />
          </form>
        </div>
        :
        ''
      }
    </>
  );
}

export default CustomerZoneAutoForm;
