import React, {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './PromoCodeHistory.scss';
import axios from 'axios';
import Config from '../../../Providers/Config';
import {IHttpResponse} from '../../../Interfaces/IHttpResponse';
import IInvoice from '../../../Interfaces/IInvoice';
import IUser from '../../../Interfaces/IUser';
import moment from 'moment';
import Confirm from '../../../Core/Form/Confirm/Confirm';

// Get invoices with referral program
async function load() {
  const response = (await axios.get(Config.api('/invoices/referral-program/history'))).data;

  if (!response.success) {
    console.error(response.message);

    return false;
  }

  //  Skip empty
  if (!response.data)
    return false;

  return response.data.map((item) => ({
    invoice: IInvoice.parse(item.invoice),
    user: item.user ? IUser.parse(item.user) : IUser.parse({})
  }));
}

const PromoCodeHistory: FC = () => {
  const {t} = useTranslation();
  const [invoices, setInvoices] = useState<{
    invoice: IInvoice,
    user: IUser
  }[]>([]);
  const [confirmStep, setConfirmStep] = useState<{
    invoiceId: number,
    state: string
  }>();
  const [confirmMsg, setConfirmMsg] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const _eRoot = useRef<HTMLDivElement>();

  useEffect(() => {
    (async () => {
      const invoices = (await load()) || [];
      setInvoices(invoices);
    })();
  }, []);

  /**
   * Open/Close invoice detailed info
   */
  function onToggleDetailedInfo(e, invoice): void {
    const eInvoice = _eRoot.current.querySelector(`.invoice[data-invoice="${invoice}"]`);
    const eOpen = eInvoice.querySelector('.btn.btn-open');
    const eClose = eInvoice.querySelector('.btn.btn-close');
    const eWrap = _eRoot.current.querySelector(`.invoice-detailed[data-invoice="${invoice}"]`);

    //  Open
    if (eWrap.classList.contains('close')) {
      eWrap.classList.remove('close');
      eOpen.classList.add('hide');
      eClose.classList.remove('hide');
    }
    // Close
    else {
      eWrap.classList.add('close');
      eOpen.classList.remove('hide');
      eClose.classList.add('hide');
    }
  }

  // Set invoice state
  function setInvoiceState(invoice, state): void {
    axios.post(Config.api('/invoices/referral-program/set-state'), {
      invoice,
      state
    })
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);

          return;
        }

        let _invoices = [...invoices];

        for (const key in _invoices) {
          if (_invoices[key].invoice.id === invoice) {
            _invoices[key].invoice = IInvoice.parse(response.data);

            break;
          }
        }

        //  Invoices
        setInvoices(_invoices);
      });
  }

  //  Return text for Confirm modal
  function getConfirmText(status): any {
    let statusStr;

    switch (status) {
      case 'invoice-sent':
        statusStr = t('admin:promotions_data.referrals_history.status_invoice_sent');

        break;

      case 'invoice-paid':
        statusStr = t('admin:promotions_data.referrals_history.status_invoice_paid');

        break;

      case 'funds-credited':
        statusStr = t('admin:promotions_data.referrals_history.status_funds_credited');

        break;
    }

    return (
      <div>
        Вы действительно хотите изменить
        <br />
        статус на: <b style={{fontFamily: 'Lato-Bold', color: '#283f50'}}>{statusStr}?</b>
      </div>
    );
  }

  //  Allow or not to change status
  function allowChangeStatus(invoice: IInvoice, status: string): boolean {
    switch (status) {
      case 'invoice-sent':
        return !invoice.invoiceSent && invoice.promoCodeActive;
      case 'invoice-paid':
        return !invoice.invoicePaid && invoice.invoiceSent;
      case 'funds-credited':
        return !invoice.fundsCredited && invoice.invoicePaid;
    }
  }

  return (
    <div
      ref={_eRoot}
      id="promo-code-history-component"
      className="refresh-container"
    >
      {/*Confirmation status modal*/}
      <Confirm
        id="promo-code-confirm"
        title={'Изменение статуса'}
        message={confirmMsg}
        cancel={'Отменить'}
        apply={'Сохранить изменения'}
        open={confirmOpen}
        onShow={() => setConfirmOpen(true)}
        onHide={() => {
          setConfirmOpen(false);
        }}
        onApply={() => {
          setInvoiceState(confirmStep.invoiceId, confirmStep.state);
          setConfirmStep(null);
        }}
      />

      {/*Refresh*/}
      <div
        className="tool refresh text-right"
        onClick={() => {
          (async () => {
            const invoices = (await load()) || [];
            setInvoices(invoices);
          })();
        }}
      >
        <img
          src={'/assets/images/refresh.svg'}
          alt="refresh"
          height={16}
        />
      </div>
      <div className="table-wrap-scroll">
        <div className="table-wrap">
          <table className="old-table-style">
            <thead>
              <tr>
                {/*Date*/}
                <th style={{width: '17%'}}>
                  <div>
                    <img
                      src={'/assets/images/icon-calendar-empty.svg'}
                      alt="icon-calendar-empty"
                      height={14}
                    />

                    <span>
                      {t('admin:referral_program.promo_code_history.date')}
                    </span>
                  </div>
                </th>
                {/*Name*/}
                <th style={{width: '29%'}}>
                  <div>
                    <img
                      src={'/assets/images/icon-user-silhouette.svg'}
                      alt="icon-user-silhouette"
                      height={14}
                    />

                    <span>
                      {t('admin:referral_program.promo_code_history.name')}
                    </span>
                  </div>
                </th>
                {/*Amount*/}
                <th style={{width: '17%'}}>
                  <div>
                    <img
                      src={'/assets/images/icon-dollar-symbol.svg'}
                      alt="icon-dollar-symbol"
                      height={14}
                    />

                    <span>
                      {t('admin:referral_program.promo_code_history.amount')}
                    </span>
                  </div>
                </th>
                {/*Promo code*/}
                <th style={{width: '17%'}}>
                  <div>
                    <img
                      src={'/assets/images/icon-coupon.svg'}
                      alt="icon-coupon"
                      height={14}
                    />

                    <span>
                      {t('admin:referral_program.promo_code_history.promo_code')}
                    </span>
                  </div>
                </th>
                {/*Action*/}
                <th style={{width: '17%'}}>
                  <div>
                    <img
                      src={'/assets/images/icon-cursor.svg'}
                      alt="icon-cursor"
                      height={14}
                    />

                    <span>
                      {t('admin:referral_program.promo_code_history.action')}
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                invoices.map((item) => {
                  let statusActive = {
                    promoCodeActive: item.invoice.promoCodeActive ? ' active ' : '',
                    invoiceSent: item.invoice.invoiceSent ? ' active ' : '',
                    invoicePaid: item.invoice.invoicePaid ? ' active ' : '',
                    fundsCredited: item.invoice.fundsCredited ? ' active ' : ''
                  };
                  const keys = ['promoCodeActive', 'invoiceSent', 'invoicePaid', 'fundsCredited'];

                  for (let i = 0; i < keys.length - 1; i++) {
                    if (!statusActive[keys[i + 1]]) {
                      statusActive[keys[i]] += ' last-active';

                      break;
                    }
                  }

                  return <React.Fragment key={item.invoice.id}>
                    {/*Invoice Common Info*/}
                    <tr
                      data-invoice={item.invoice.id}
                      className="invoice"
                    >
                      {/*Activate date*/}
                      <td>
                        {item.invoice.createdAt}
                      </td>
                      {/*Name*/}
                      <td>
                        {item.invoice.data.shortName}
                      </td>
                      {/*Amount*/}
                      <td>
                        ${item.invoice.rewardAmount}
                      </td>
                      {/*Promo code*/}
                      <td>
                        {item.invoice.promoCode}
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn th-action th-action-open btn-open"
                          onClick={(e) => onToggleDetailedInfo(e, item.invoice.id)}
                        >
                          {t('admin:referral_program.promo_code_history.open')}
                        </button>
                        <button
                          type="button"
                          className="btn th-action th-action-open btn-close hide"
                          onClick={(e) => onToggleDetailedInfo(e, item.invoice.id)}
                        >
                          {t('admin:referral_program.promo_code_history.close')}
                        </button>
                      </td>
                    </tr>

                    {/*Invoice Detailed Info*/}
                    <tr
                      data-invoice={item.invoice.id}
                      className="invoice-detailed close"
                    >
                      <td colSpan={5}>
                        <div className="w-100 content-wrap">
                          <div className="content">
                            {/*User info*/}
                            <div className="user-info">
                              <div className="user">
                                <div className="wrap">
                                  <div
                                    className="avatar"
                                    style={{backgroundImage: `url(${item.user.avatar})`}}
                                  />
                                  <div className="name">
                                    {
                                      `${item.user.firstName} ` +
                                      (item.user.lastName ? item.user.lastName : '')
                                    }
                                  </div>
                                </div>
                              </div>
                              <table>
                                <thead>
                                  <tr>
                                    {/*Phone*/}
                                    <th style={{width: '25%'}}>
                                      <div>
                                        <img
                                          src={'/assets/images/icon-calendar-empty.svg'}
                                          alt="icon-calendar-empty"
                                          height={14}
                                        />

                                        <span>
                                          {t('admin:referral_program.activate_promo_code.phone')}
                                        </span>
                                      </div>
                                    </th>
                                    {/*Email*/}
                                    <th style={{width: '25%'}}>
                                      <div>
                                        <img
                                          src={'/assets/images/icon-user-silhouette.svg'}
                                          alt="icon-user-silhouette"
                                          height={14}
                                        />

                                        <span>
                                          Email
                                        </span>
                                      </div>
                                    </th>
                                    {/*Card number*/}
                                    <th style={{width: '25%'}}>
                                      <div>
                                        <img
                                          src={'/assets/images/icon-dollar-symbol.svg'}
                                          alt="icon-dollar-symbol"
                                          height={14}
                                        />

                                        <span>
                                          {t('admin:referral_program.activate_promo_code.card_number')}
                                        </span>
                                      </div>
                                    </th>
                                    {/*Card name*/}
                                    <th style={{width: '25%'}}>
                                      <div>
                                        <img
                                          src={'/assets/images/icon-car.svg'}
                                          alt="icon-car"
                                          height={14}
                                        />

                                        <span>
                                          {t('admin:referral_program.activate_promo_code.card_name')}
                                        </span>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    {/*Phone*/}
                                    <td>
                                      {item.user.phone}
                                    </td>
                                    {/*Email*/}
                                    <td>
                                      {item.user.email}
                                    </td>
                                    {/*Card number*/}
                                    <td>
                                      {item.user.cardNumber}
                                    </td>
                                    {/*Card name*/}
                                    <td>
                                      {item.user.cardName}
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td />
                                    <td />
                                    <td />
                                    <td />
                                  </tr>
                                </tfoot>
                              </table>
                            </div>

                            {/*Status line*/}
                            <div className="row status-line">
                              {/*Promo code activate*/}
                              <div
                                className={
                                  'col-3 status promo-code-activated ' +
                                  statusActive.promoCodeActive
                                }
                              >
                                <div className="title">
                                  {t('admin:promotions_data.referrals_history.status_promo_code_activated')}
                                </div>
                                <div className="image">
                                  {
                                    item.invoice.promoCodeActive ?
                                      <img
                                        src={'/assets/images/icon-active-point.svg'}
                                        alt="icon-active-point"
                                      />
                                      :
                                      <img
                                        src={'/assets/images/icon-not-active-point.svg'}
                                        alt="icon-active-point"
                                      />
                                  }
                                </div>
                                <div className="date">
                                  {
                                    item.invoice.promoCodeActiveDate ?
                                      moment(
                                        item.invoice.promoCodeActiveDate,
                                        'YYYY-MM-DD HH:mm:ss'
                                      ).format('DD.MM.YYYY, HH:mm')
                                      :
                                      ''
                                  }
                                </div>
                              </div>
                              {/*Invoice sent*/}
                              <div
                                className={
                                  'col-3 status invoice-sent ' +
                                  statusActive.invoiceSent
                                }
                                onClick={(e) => {
                                  if (allowChangeStatus(item.invoice, 'invoice-sent')) {
                                    setConfirmStep({
                                      invoiceId: item.invoice.id,
                                      state: 'invoice-sent'
                                    });
                                    setConfirmMsg(getConfirmText('invoice-sent'));
                                    setConfirmOpen(true);
                                  }
                                }}
                              >
                                <div className="title">
                                  {t('admin:promotions_data.referrals_history.status_invoice_sent')}
                                </div>
                                <div className="image">
                                  {
                                    item.invoice.invoiceSent ?
                                      <img
                                        src={'/assets/images/icon-active-point.svg'}
                                        alt="icon-active-point"
                                      />
                                      :
                                      <img
                                        src={'/assets/images/icon-not-active-point.svg'}
                                        alt="icon-active-point"
                                      />
                                  }
                                </div>
                                <div className="date">
                                  {
                                    item.invoice.invoiceSentDate ?
                                      moment(
                                        item.invoice.invoiceSentDate,
                                        'YYYY-MM-DD HH:mm:ss'
                                      ).format('DD.MM.YYYY, HH:mm')
                                      :
                                      ''
                                  }
                                </div>
                              </div>
                              {/*Invoice paid*/}
                              <div
                                className={
                                  'col-3 status invoice-paid ' +
                                  statusActive.invoicePaid
                                }
                                onClick={(e) => {
                                  if (allowChangeStatus(item.invoice, 'invoice-paid')) {
                                    setConfirmStep({
                                      invoiceId: item.invoice.id,
                                      state: 'invoice-paid'
                                    });
                                    setConfirmMsg(getConfirmText('invoice-paid'));
                                    setConfirmOpen(true);
                                  }
                                }}
                              >
                                <div
                                  className="title"
                                  style={{width: '100px'}}
                                >
                                  {t('admin:promotions_data.referrals_history.status_invoice_paid')}
                                </div>
                                <div className="image">
                                  {
                                    item.invoice.invoicePaid ?
                                      <img
                                        src={'/assets/images/icon-active-point.svg'}
                                        alt="icon-active-point"
                                      />
                                      :
                                      <img
                                        src={'/assets/images/icon-not-active-point.svg'}
                                        alt="icon-active-point"
                                      />
                                  }
                                </div>
                                <div className="date">
                                  {
                                    item.invoice.invoicePaidDate ?
                                      moment(
                                        item.invoice.invoicePaidDate,
                                        'YYYY-MM-DD HH:mm:ss'
                                      ).format('DD.MM.YYYY, HH:mm')
                                      :
                                      ''
                                  }
                                </div>
                              </div>
                              {/*Funds credited*/}
                              <div
                                className={
                                  'col-3 status funds-credited ' +
                                  statusActive.fundsCredited
                                }
                                onClick={(e) => {
                                  if (allowChangeStatus(item.invoice, 'funds-credited')) {
                                    setConfirmStep({
                                      invoiceId: item.invoice.id,
                                      state: 'funds-credited'
                                    });
                                    setConfirmMsg(getConfirmText('funds-credited'));
                                    setConfirmOpen(true);
                                  }
                                }}
                              >
                                <div
                                  className="title"
                                  style={{width: '100px'}}
                                >
                                  {t('admin:promotions_data.referrals_history.status_funds_credited')}
                                </div>
                                <div className="image">
                                  {
                                    item.invoice.fundsCredited ?
                                      <img
                                        src={'/assets/images/icon-active-point.svg'}
                                        alt="icon-active-point"
                                      />
                                      :
                                      <img
                                        src={'/assets/images/icon-not-active-point.svg'}
                                        alt="icon-active-point"
                                      />
                                  }
                                </div>
                                <div className="date">
                                  {
                                    item.invoice.fundsCreditedDate ?
                                      moment(
                                        item.invoice.fundsCreditedDate,
                                        'YYYY-MM-DD HH:mm:ss'
                                      ).format('DD.MM.YYYY, HH:mm')
                                      :
                                      ''
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                })
              }
            </tbody>
            <tfoot>
              <tr>
                <td style={{height: '36px'}} />
                <td style={{height: '36px'}} />
                <td style={{height: '36px'}} />
                <td style={{height: '36px'}} />
                <td style={{height: '36px'}} />
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <br />
      <br />
    </div>
  );
}

export default PromoCodeHistory;
