import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import './UsersForm.scss';
import UserProvider from '../../Providers/User';
import Input from '../../Core/Form/Input/Input';
import axios from 'axios';
import Config from '../../Providers/Config';
import $ from 'jquery';
import {IHttpResponse} from '../../Interfaces/IHttpResponse';
import * as EgoUtil from '@egofoxlab/util';
import Notify from '../../Core/Ui/Notify/Notify';
import IUser from '../../Interfaces/IUser';
import Select, {SelectStylesHideIndicatorSeparator} from '../../Core/Form/Select/Select';
import Head from '../Head/Head';
import BottomToolbar from '../BottomToolbar/BottomToolbar';
import {ExpansionPanel, ExpansionPanelDetails} from '@material-ui/core';
import DownArrowIndicator from '../../Core/Form/Select/Indicators/DownArrowIndicator';
import qs from 'qs';

const userProvider = new UserProvider();

const UsersForm: FC = () => {
  const {t} = useTranslation();
  const roleGroupOptions = [
    {
      value: UserProvider.ROLE_GROUP_ADMIN,
      label: t('admin:users.role_group_admin')
    },
    {
      value: UserProvider.ROLE_GROUP_EMPLOYEE,
      label: t('admin:users.role_group_employee')
    },
    {
      value: UserProvider.ROLE_GROUP_DEALER,
      label: t('admin:users.role_group_dealer')
    },
    {
      value: UserProvider.ROLE_GROUP_CLIENT,
      label: t('admin:users.role_group_client')
    },
  ];
  const history = useHistory();
  const id = parseInt(useParams().id) || null;
  const [user, setUser] = useState<IUser>();
  const [role, setRole] = useState<any>();
  const [roleGroup, setRoleGroup] = useState<any>();
  const [buyerIds, setBuyerIds] = useState<any[]>();
  const [saveProcess, setSaveProcess] = useState(false);
  const [saveNotify, setSaveNotify] = useState(false);
  const _eForm = useRef<HTMLFormElement>();

  useEffect(() => {
    (async () => {
      let user = id > 0 ? await userProvider.get(id) : null;
      user = user ?? IUser.parse({})

      if (!user) {
        history.push('/admin/users');

        return;
      }

      setUser(user);
      setRoleGroup(user.roleGroup);
    })();
    // eslint-disable-next-line
  }, []);

  function reset(): void {
    // Clear input field
    EgoUtil.clearFields(_eForm.current);
    $(_eForm.current).find('[name]').trigger('change');
  }

  function onSubmit(e): void {
    if (!_eForm.current.checkValidity()) {
      return;
    }

    e.preventDefault();

    // Set `Save Process` flag
    setSaveProcess(true);

    const eForm = $(_eForm.current);
    const formData = new FormData();
    // Email
    formData.append('email', eForm.find('[name="email"]').val());
    // First Name
    formData.append('first_name', eForm.find('[name="first-name"]').val());
    // Last Name
    formData.append('last_name', eForm.find('[name="last-name"]').val() ?? '');
    // Role
    formData.append('role', role?.value ?? '');
    // Role group
    formData.append('role_group', roleGroup ?? '');
    // Buyer IDs
    buyerIds.forEach(item => formData.append('buyer_ids[]', item.value));
    // Company
    formData.append('company', eForm.find('[name="company"]').val() ?? '');
    // Country
    formData.append('country', eForm.find('[name="country"]').val() ?? '');
    // City
    formData.append('city', eForm.find('[name="city"]').val() ?? '');

    // Save form
    let url;

    if (user && user.id > 0) {
      url = Config.api(`/user/update/${user.id}`);
    } else {
      url = Config.api('/user/create');
    }

    axios.post(url, formData, {
      headers: {'Content-Type': 'multipart/form-data'}
    })
      .then((_response) => {
        const response: IHttpResponse = _response.data;

        if (response.code !== 200) {
          console.error(response.message);
          // Unset `Save Process` flag
          setSaveProcess(false);

          return;
        }

        // Mark as saved
        setSaveNotify(true);

        // Add user to state after saving
        if (!user || !(user.id > 0)) {
          history.replace(`/admin/users/form/${response.data.id}`);

          // Update user state
          setUser(IUser.parse(response.data));
        }

        // Unset `Save Process` flag
        setSaveProcess(false);
      });
  }

  // Search roles based on input
  const roleLoadOptions = async input => {
    input = input ? input : '';

    // Min input length
    if (input && input.length < 2)
      return false;

    const response = (await axios.get(Config.api(`/role/search?term=${input}`))).data;

    if (!response.success)
      return [];

    const options = response.data.map((item) => ({
      value: item.id,
      label: item.display_name
    }));

    // Initial value
    if (role === undefined && user && user.role.id > 0) {
      const valueResponse = (await axios.get(Config.api(`/role/search?id=${user.role.id}`))).data;
      const item = valueResponse.success ? valueResponse.data[0] : null;
      setRole(item ? {
        value: item.id,
        label: item.display_name
      } : null);
    }

    return options;
  };

  // Search Buyer ID based on input
  const buyerIdLoadOptions = useCallback(async input => {
    input = input ? input : '';

    // Min input length
    if (input && input.length < 2)
      return false;

    const q = qs.stringify({
      term: input,
      exclude_ids: (buyerIds ?? []).map(item => item.value)
    });
    const response = (await axios.get(Config.api(`/buyer-id/search?${q}`))).data;

    if (!response.success)
      return [];

    const options = response.data.map((item) => ({
      value: item.id,
      label: `${item.login} - ${t(`admin:auction.${item.auction}`)}`
    }));

    // Initial value
    if (buyerIds === undefined && user?.buyerIds?.length > 0) {
      setBuyerIds(user.buyerIds.map(item => ({
        value: item.id,
        label: `${item.login} - ${t(`admin:auction.${item.auction}`)}`
      })));
    }

    return options;
    // eslint-disable-next-line
  }, [buyerIds, user]);

  return (
    <>
      {user !== undefined ?
        <div
          id="users-form-component"
        >
          <Head
            head={t('admin:users.form.head')}
            subhead={'VIN - Auto'}
            right={<div className="d-flex justify-content-lg-end justify-content-start train-buttons">
              <button
                form="users-form"
                className="btn btn-save"
                disabled={saveProcess}
              >
                {t('buttons.save')}
              </button>
              <button
                type="button"
                className="btn btn-reload"
                onClick={() => reset()}
              >
                {t('buttons.reset')}
              </button>
              <button
                type="button"
                className="btn btn-remove"
              >
                {t('buttons.remove')}
              </button>
            </div>}
            hr={{style: {marginTop: 20}}}
          />

          <form
            ref={_eForm}
            id="users-form"
            onSubmit={onSubmit}
          >
            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelDetails className="pt-0">
                <div className="row d-flex flex-grow-1">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Email*/}
                    <Input
                      label={t('admin:users.email')}
                      input={{
                        type: 'email',
                        name: 'email',
                        id: 'email',
                        defaultValue: user ? user.email : null,
                        required: true
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*First Name*/}
                    <Input
                      label={t('admin:users.first_name')}
                      input={{
                        name: 'first-name',
                        id: 'first-name',
                        defaultValue: user ? user.firstName : null,
                        required: true
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Last Name*/}
                    <Input
                      label={t('admin:users.last_name')}
                      input={{
                        name: 'last-name',
                        id: 'last-name',
                        defaultValue: user.lastName ?? null,
                        required: true
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Role*/}
                    <Select
                      label={t('admin:users.role')}
                      async={true}
                      select={{
                        cacheOptions: true,
                        loadOptions: roleLoadOptions,
                        defaultOptions: true,
                        value: role,
                        components: {
                          DropdownIndicator: DownArrowIndicator,
                        },
                        styles: SelectStylesHideIndicatorSeparator,
                        onChange: option => setRole(option)
                      }}
                      required={true}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Role group*/}
                    <Select
                      label={t('admin:users.role_group')}
                      select={{
                        isSearchable: false,
                        options: roleGroupOptions,
                        defaultValue: roleGroupOptions.find(item => item.value === user.roleGroup),
                        required: true,
                        components: {
                          DropdownIndicator: DownArrowIndicator,
                        },
                        styles: SelectStylesHideIndicatorSeparator,
                        onChange: option => setRoleGroup(option.value)
                      }}
                      required={true}
                    />
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            {/*Employee fields*/}
            {roleGroup === UserProvider.ROLE_GROUP_EMPLOYEE && <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelDetails className="pt-0">
                <div className="row d-flex flex-grow-1">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*City*/}
                    <Input
                        label={t('admin:users.city')}
                        input={{
                          name: 'city',
                          id: 'city',
                          defaultValue: user.city ?? '',
                          required: true
                        }}
                    />
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>}

            {/*Dealer fields*/}
            {roleGroup === UserProvider.ROLE_GROUP_DEALER && <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelDetails className="pt-0">
                <div className="row d-flex flex-grow-1">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Buyer ID*/}
                    <Select
                        label={t('admin:users.buyer_id')}
                        async={true}
                        select={{
                          cacheOptions: true,
                          isMulti: true,
                          loadOptions: buyerIdLoadOptions,
                          defaultOptions: true,
                          value: buyerIds,
                          components: {
                            DropdownIndicator: DownArrowIndicator,
                          },
                          styles: SelectStylesHideIndicatorSeparator,
                          onChange: options => setBuyerIds(options)
                        }}
                        required={true}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Company*/}
                    <Input
                        label={t('admin:users.company')}
                        input={{
                          name: 'company',
                          id: 'company',
                          defaultValue: user.company ?? '',
                          required: true
                        }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*Country*/}
                    <Input
                        label={t('admin:users.country')}
                        input={{
                          name: 'country',
                          id: 'country',
                          defaultValue: user.country ?? '',
                          required: true
                        }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    {/*City*/}
                    <Input
                        label={t('admin:users.city')}
                        input={{
                          name: 'city',
                          id: 'city',
                          defaultValue: user.city ?? '',
                          required: true
                        }}
                    />
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>}
          </form>

          <BottomToolbar
            right={<>
              <div className="d-flex justify-content-lg-end justify-content-start train-buttons">
                <button
                  form="users-form"
                  className="btn btn-save"
                  disabled={saveProcess}
                >
                  {t('buttons.save')}
                </button>
                <button
                  type="button"
                  className="btn btn-reload"
                  onClick={() => reset()}
                >
                  {t('buttons.reset')}
                </button>
                <button
                  type="button"
                  className="btn btn-remove"
                >
                  {t('buttons.remove')}
                </button>
              </div>
              <div className="d-flex justify-content-end">
                <Notify
                  type="save-form"
                  hide={!saveNotify}
                />
              </div>
            </>}
          />
        </div>
        :
        ''
      }
    </>
  );
};

export default UsersForm;
