export default class Config {

  private static _config: {
    mode: 'dev' | 'prod',
    dev: any,
    prod: any
  };

  private static configData: {
    version: string;
    server: string;
    fb_id: string;
    date_format: string;
    date_time_format: string;
    time_format: string;
  };

  public static STYLE = {
    HEADER: {
      HEIGHT: 89
    }
  };

  /**
   * App version
   *
   * @returns {string}
   * @constructor
   */
  public static get VERSION(): string {
    return this.configData.version;
  };

  /**
   * App version
   *
   * @returns {string}
   * @constructor
   */
  public static get FB_ID(): string {
    return this.configData.fb_id;
  };

  /**
   * Common date format
   *
   * @returns {string}
   * @constructor
   */
  public static get DATE_FORMAT(): string {
    return this.configData.date_format;
  }

  /**
   * Common date time format
   *
   * @returns {string}
   * @constructor
   */
  public static get DATE_TIME_FORMAT(): string {
    return this.configData.date_time_format;
  }


  /**
   * Common date time format
   *
   * @returns {string}
   * @constructor
   */
  public static get TIME_FORMAT(): string {
    return this.configData.time_format;
  }

  /**
   * Return common info.
   * This info can based on client domain.
   *
   * @constructor
   */
  public static get Common(): {
    logo: string,
    logoWhite: string
  } {
    const hostname = window.location.hostname;
    const result: any = {};

    switch (hostname) {
      case 'my.gr8logistics.com':
        result.logo = 'logo-logistics.png';
        result.logoWhite = 'logo-logistics-white.png';

        break;

      default:
        result.logo = 'logo.png';
        result.logoWhite = 'logo-white.png';
    }

    return result;
  }

  public static config(config): void {
    this._config = config;
    this.configData = this._config[this._config.mode];
  }


  /**
   * Return server URI
   *
   * @returns {string}
   */
  public static serverUri(): string {
    const uri = this.configData.server.replace(/\/$/, '');

    return `${uri}/`;

  }

  /**
   * Return API path
   *
   * @param {string} path
   * @returns {string}
   */
  public static api(path: string): string {
    path = path.replace(/^\//, '');

    return this.serverUri() + 'api/v1/' + path;
  }

  /**
   * Return server URL with path
   *
   * @param path
   */
  public static url(path: string): string {
    path = path.replace(/^\//, '');

    return this.serverUri() + path;
  }

}
