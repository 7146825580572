import moment from 'moment';

export default class ICustomerZoneAuto {

  id: number;
  buyerId: number;
  data: {
    date: string;
    model: string;
    buyerNumber: string;
    year: number;
    type: string;
    lot: string;
    vin: string;
    pointOfLoading: string;
    dateOfLoading: string;
    auction: string;
    destinationPort: string;
    containerNumber: string;
    deliveredToWarehouse: string;
    arrivalDate: string;
    titleStatus: string;
    titleReceived: string;
    fencePoint: number;
    keys: string;
    notes: string;
    invoice: number;
    autoPrice: string;
    paid: string;
    waggonNumber: string;
    waggonLoadingPlace: string;
    destination: string;
    plannedWagonLading: string;
    waggonLoadingDate: string;
    waggonEstimateArrivalDate: string;
    waggonArrivalDate: string;
    waggonNotes: string;
    notesForYourself: string;

    photos: any[];
    dockReceipt: any[];
    billOfLading: any[];
  };
  code: string;
  createdAt: string;
  updatedAt: string;

  public static parse(data: any): ICustomerZoneAuto {
    if (!data) {
      return null;
    }

    const customerZoneAuto = new ICustomerZoneAuto();

    customerZoneAuto.id = data.id ? parseInt(data.id, 10) : null;
    customerZoneAuto.buyerId = data.buyer_id > 0 ? data.buyer_id : null;
    customerZoneAuto.data = {
      date: data.data['date'],
      model: data.data['model'],
      buyerNumber: data.data['buyer-number'],
      year: parseInt(data.data['year']) || null,
      type: data.data['type'],
      lot: data.data['lot'],
      vin: data.data['vin'],
      pointOfLoading: data.data['point-of-loading'],
      dateOfLoading: data.data['date-of-loading'],
      auction: data.data['auction'],
      destinationPort: data.data['destination-port'],
      containerNumber: data.data['container-number'],
      deliveredToWarehouse: data.data['delivered-to-warehouse'],
      arrivalDate: data.data['arrival-date'],
      titleStatus: data.data['title-status'],
      titleReceived: data.data['title-received'],
      fencePoint: data.data['fence-point'],
      keys: data.data['keys'],
      notes: data.data['notes'],
      invoice: parseInt(data.data['invoice']) > 0 ? data.data['invoice'] : null,
      autoPrice: data.data['auto-price'],
      paid: data.data['paid'],
      waggonNumber: data.data['waggon-number'],
      waggonLoadingPlace: data.data['waggon-loading-place'],
      destination: data.data['destination'],
      plannedWagonLading: data.data['planned-wagon-lading'],
      waggonLoadingDate: data.data['waggon-loading-date'],
      waggonEstimateArrivalDate: data.data['waggon-estimate-arrival-date'],
      waggonArrivalDate: data.data['waggon-arrival-date'],
      waggonNotes: data.data['waggon-notes'],
      notesForYourself: data.data['notes-for-yourself'],

      photos: Array.isArray(data.data['photos']) ? data.data['photos'] : [],
      dockReceipt: Array.isArray(data.data['dock-receipt']) ? data.data['dock-receipt'] : [],
      billOfLading: Array.isArray(data.data['bill-of-lading']) ? data.data['bill-of-lading'] : [],
    };
    customerZoneAuto.code = data.code;
    customerZoneAuto.createdAt = data.created_at ? moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') : null;
    customerZoneAuto.updatedAt = data.updated_at ? moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') : null;

    return customerZoneAuto;
  }

}
